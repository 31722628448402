import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import NumAbbr from "number-abbreviate";

import FlowSettingPopover from "./FlowSettingPopover";
import PlayPauseButton, {
  BTN_STATE_PAUSE,
  BTN_STATE_PLAY,
} from "../shared/Button/PlayPauseButton";

import { FLOW_TIMEFRAME_FILTER } from "../constants";

import { Types as FlowActionTypes } from "./flowReducer";

const numAbbr = new NumAbbr(["k", "m"], 2);

const FlowFilterStatusText = styled.span`
  margin-top: 6px;
  font-size: 14px;
  color: rgb(191, 191, 191);
`;

const FlowFilterPanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  margin-top: -8px;
  margin-left: 8px;
`;

const FlowFilterPanelRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

function FlowFilter() {
  const dispatch = useDispatch();
  const {
    timeframe,
    isPlaying,
    filter: { vol },
  } = useSelector((state) => state.flow);

  const playPause = (value) => {
    dispatch({
      type: FlowActionTypes.PLAY_PAUSE,
      value,
    });
  };

  return (
    <div className="d-flex flex-row justify-content-between mb-1">
      <h4 style={{ marginBottom: "0px" }}>Flow</h4>
      <FlowFilterPanelWrapper>
        <FlowFilterStatusText>
          {FLOW_TIMEFRAME_FILTER[timeframe]}
          {vol["1d"] > 0 &&
            ` | ${numAbbr.abbreviate(vol["1d"], 2)} daily min vol`}
        </FlowFilterStatusText>
        <FlowFilterPanelRight>
          <PlayPauseButton
            state={isPlaying ? BTN_STATE_PAUSE : BTN_STATE_PLAY}
            onClick={() => playPause(!isPlaying)}
          />
          <FlowSettingPopover />
        </FlowFilterPanelRight>
      </FlowFilterPanelWrapper>
    </div>
  );
}

FlowFilter.propTypes = {};

export default FlowFilter;

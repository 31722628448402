import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";

const TriggerIcon = styled.i`
  cursor: pointer;
  color: white;
  font-size: 16px;
  margin-left: 4px;
`;

function HelpTooltip({ title, placement, content }) {
  return (
    <OverlayTrigger
      trigger={"click"}
      placement={placement || "bottom"}
      rootClose
      overlay={
        <Popover title={title || "Help Tooltip"}>
          <Popover.Content>{content}</Popover.Content>
        </Popover>
      }
    >
      <TriggerIcon className="mdi mdi-help-circle-outline"></TriggerIcon>
    </OverlayTrigger>
  );
}

HelpTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  content: PropTypes.any.isRequired,
};

export default HelpTooltip;

import { createReducer, createActions } from "reduxsauce";

const defaultState = {
  collapsed: {
    news: false,
    options: false,
    voiceNoti: false,
    price: {},
    marketCap: {},
    volume: {},
    float: {},
    industry: {},
    count: {},
    custom_view: {},
    discovery: false,
    alert_halts: false,
    alert_trade: false,
    alert_uv: false,
    alert_vwap: false,
    alert_price: false,
    alert_filtered: false,
  },
  sectorSymbolsCnt: {},
};

export const { Types, Creators } = createActions({
  toggleCollapsed: ["value"],
  setSectorSymbolsCnt: ["value"],
});

export const ConfigTypes = Types;

const toggleCollapsed = (state, { value }) => {
  const { key, subkey } = value;
  let collapsed = state.collapsed || {};
  if (!subkey) {
    collapsed[key] = !collapsed[key];
  } else {
    collapsed[key] = collapsed[key] || {};
    collapsed[key][subkey] = !collapsed[key][subkey];
  }
  return {
    ...state,
    collapsed: {
      ...collapsed,
    },
  };
};

const setSectorSymbolsCnt = (state, { value }) => {
  return {
    ...state,
    sectorSymbolsCnt: {
      ...value,
    },
  };
};

export const settingReducer = createReducer(defaultState, {
  [Types.TOGGLE_COLLAPSED]: toggleCollapsed,
  [Types.SET_SECTOR_SYMBOLS_CNT]: setSectorSymbolsCnt,
});

export default Creators;

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form, Button, Modal, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import cogoToast from "cogo-toast";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

import {
  PLANID,
  isPro,
  isProNew,
  isProPlusNew,
  hasSubscription,
  isActiveSubscription,
} from "../../../constants";
import { addGTMAnalyticsScripts } from "../../../util";
import { store } from "../../../store/createStore";
import { AuthActions } from "../../../store";
import Api from "../../../api";
import i18n from "../../../../i18n";
import "./index.scss";

class Subscription extends Component {
  state = {
    errTxt: "",
    succTxt: "",
    plans: [],
    selectedPlan: null,
    currentPlan: null,
    subscribing: false,
    showCardInput: false,
    changeCard: false,
    discountCode: "",
    name: "",
    coupon: null,
    success: false,
    showCancelSubscriptionModal: false,
    default_plan: window.localStorage.getItem("default_plan"),
    tabActiveKey: "monthly",
  };

  plansByType = {
    monthly: [PLANID.NEW_STANDARD, PLANID.NEW_PRO, PLANID.NEW_PROPLUS],
    annually: [
      PLANID.NEW_STANDARD_ANNUAL,
      PLANID.NEW_PRO_ANNUAL,
      PLANID.NEW_PROPLUS_ANNUAL,
    ],
  };
  activePlans = [
    PLANID.NEW_STANDARD,
    PLANID.NEW_PRO,
    PLANID.NEW_PROPLUS,
    PLANID.NEW_STANDARD_ANNUAL,
    PLANID.NEW_PRO_ANNUAL,
    PLANID.NEW_PROPLUS_ANNUAL,
  ];

  componentDidMount() {
    // get plans
    this.getPlans();

    // Append scripts
    this.createADTrackingScripts();
    addGTMAnalyticsScripts();

    // Open Flow popout if the non-subscriber was from Flow signup journey
    const { subscription, is_flow } = this.props.user;
    if (!hasSubscription(subscription) && is_flow) {
      window.open(
        "/popout/flow",
        "MOMO Flow",
        `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=1024,height=200,left=0,top=150`
      );
    }
  }

  createADTrackingScripts = () => {
    if (document.getElementById("fb-pixel-code")) {
      return;
    }

    // Add fb, google tagmanger scripts
    const s1 = document.createElement("script");
    s1.id = "fb-pixel-code";
    s1.innerHTML = `
    !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '306433420451932');
      fbq('track', 'PageView');`;
    document.head.appendChild(s1);
    const ns1 = document.createElement("noscript");
    const img1 = document.createElement("img");
    (img1.width = 1), (img1.height = 1);
    img1.style.display = "none";
    img1.src =
      "https://www.facebook.com/tr?id=306433420451932&ev=PageView&noscript=1";
    document.head.appendChild(ns1);
    ns1.appendChild(img1);

    const s2 = document.createElement("script");
    s2.id = "googletagmanager";
    s2.async = true;
    s2.src = "https://www.googletagmanager.com/gtag/js?id=UA-56001602-1";
    document.head.appendChild(s2);

    const s3 = document.createElement("script");
    s3.innerHTML = `
    window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-56001602-1');
      gtag('config', 'AW-1011852803');

      function gtag_report_conversion(url, subscription_id) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
          'send_to': 'AW-1011852803/qW-5CP6d79oBEIPMvuID',
          'transaction_id': subscription_id || '',
          'event_callback': callback
        });
        return false;
      }`;
    document.head.appendChild(s3);

    // const s4 = document.createElement('script');
    // s4.innerHTML = `
    //   (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`;
    // document.head.appendChild(s4);

    // const s5 = document.createElement('script');
    // s5.async = true;
    // s5.setAttribute("data-rewardful", "1b3ebd");
    // s5.src = "https://r.wdfl.co/rw.js";
    // document.head.appendChild(s5);
  };

  getPlans = async () => {
    try {
      const { default_plan } = this.state;
      const plans = await Api.getStripePlans();
      const { subscription } = this.props.user;
      let currentPlan = null;
      if (subscription) {
        currentPlan = plans.find(({ id }) => id === subscription.plan);
        if (currentPlan) {
          currentPlan.selected = true;
        }
      }

      this.setState({ plans, currentPlan });

      const selected_plan = plans.find((plan) => {
        if (default_plan === "standard") {
          return plan.id === PLANID.NEW_STANDARD;
        } else if (default_plan === "pro") {
          return plan.id === PLANID.NEW_PRO;
        } else if (default_plan === "proplus") {
          return plan.id === PLANID.NEW_PROPLUS;
        } else if (default_plan === "standard_annual") {
          return plan.id === PLANID.NEW_STANDARD_ANNUAL;
        } else if (default_plan === "pro_annual") {
          return plan.id === PLANID.NEW_PRO_ANNUAL;
        } else if (default_plan === "proplus_annual") {
          return plan.id === PLANID.NEW_PROPLUS_ANNUAL;
        }
      });

      if (selected_plan) {
        this.onSelectPlan(selected_plan);
      }
    } catch (e) {
      cogoToast.error("Failed to get plans!");
    }
  };

  onClickSaveCard = async () => {
    if (this.state.changeCard && this.state.name === "") {
      return cogoToast.error("Please enter the name");
    }

    try {
      this.setState({ subscribing: true });

      if (this.state.changeCard) {
        const payload = await this.stripe.createToken(
          this.elements.getElement(CardElement),
          {
            name: this.state.name,
          }
        );
        if (payload && payload.error) {
          cogoToast.error(payload.error.message);
          throw payload.error;
        }
        const res = await Api.createCustomer(payload.token.id);

        if (res && res.error) {
          cogoToast.error("Payment method verification failed!");
          throw res.error;
        }
        const { customer } = res;
        this.props.setUser({
          ...this.props.user,
          customer,
        });
        if (!this.state.selectedPlan) {
          cogoToast.success("Successfully saved card!");
        }
      }

      if (this.state.selectedPlan) {
        await this.onClickSubscribe(this.state.selectedPlan);
      }
    } catch (e) {
      console.error("Failed to save card:", e);
    } finally {
      this.setState({ subscribing: false });
    }
  };

  onClickSubscribe = async (plan) => {
    cogoToast.loading("Please wait for a moment!");
    try {
      let subscription = this.props.user.subscription;
      subscription = await Api.createSubscription(
        plan.id,
        this.state.coupon ? this.state.coupon.id : undefined
      );
      if (subscription && subscription.error) {
        if (subscription.error.startsWith("Error: No such coupon:")) {
          return cogoToast.error("Invalid Coupon Code!");
        } else {
          return cogoToast.error("Subscription Failed, please try again!");
        }
      }

      // Set subscription
      this.props.setUser({ ...this.props.user, subscription });

      const currentPlan = this.state.plans.find(
        ({ id }) => id === subscription.plan
      );

      // TODO: handle non complete statues

      this.setState({
        currentPlan,
        success: true,
        showCardInput: false,
      });

      try {
        window.gtag_report_conversion(undefined, subscription.id);
      } catch (e) {}

      try {
        window.fbq("track", "Purchase", {
          currency: "USD",
          value: subscription.amount,
        });
      } catch (e) {}

      try {
        addGTMAnalyticsScripts();
      } catch (e) {}

      try {
        const script = document.createElement("script");
        script.innerHTML = `
          (function(w,d,t,r,u)
          {
              var f,n,i;
              w[u]=w[u]||[],f=function()
              {
                  var o={ti:"187136300", enableAutoSpaTracking: true};
                  o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
              },
              n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
              {
                  var s=this.readyState;
                  s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
              },
              i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
          })
          (window,document,"script","//bat.bing.com/bat.js","uetq");
        `;
        document.head.appendChild(script);
      } catch (e) {}
    } catch (e) {
      cogoToast.error("Subscription failed, please try again!");
      console.error("onClickSubscribe - ", e);
    }
  };

  onClickCancelSubscription = () => {
    this.setState({
      showCancelSubscriptionModal: true,
    });
  };

  cancelSubscription = async () => {
    const { subscription } = this.props.user;
    if (!hasSubscription(subscription)) {
      console.error("onClickCancelSubscription: no subscription");
      return;
    }

    try {
      cogoToast.loading("Please wait for a moment!");
      const res = await Api.cancelSubscription(subscription.id);
      if (res && res.error) {
        cogoToast.error("Failed to cancel the subscription, please try again!");
        return;
      }
      this.props.setUser({ ...this.props.user, subscription: res });

      this.setState({
        currentPlan: null,
        plan: null,
        plans: this.state.plans.map((plan) => {
          plan.selected = false;
          return plan;
        }),
      });
      cogoToast.warn(
        "Subscription cancelled, please subscribe to use the app!"
      );
    } catch (e) {
      console.error(e);
      cogoToast.error(
        "Sorry, failed to cancel the current subscription, please try again"
      );
    }
  };

  onClickApplyCoupon = async () => {
    if (!this.state.discountCode) {
      return cogoToast.error("Please enter the discount code!");
    }

    try {
      const coupon = await Api.getCoupon(this.state.discountCode.trim());
      if (coupon && coupon.error) {
        cogoToast.error(`Invalid discount code!`);
      } else {
        this.setState({ coupon });
        cogoToast.success(
          `Successfully applied the discount code:${coupon.name}`
        );
      }
    } catch (e) {
      cogoToast.error("Invalid discount code!");
    }
  };

  onSelectPlan = (plan) => {
    // Init selected plan in the local storage
    window.localStorage.setItem("default_plan", "");
    const { customer } = this.props.user;
    this.setState({
      selectedPlan: plan,
      showCardInput: true,
      changeCard:
        customer &&
        customer.card_kind &&
        customer.card_last_4 &&
        customer.card_exp_year &&
        customer.card_exp_month
          ? false
          : true,
    });
  };

  renderCurrentCard = () => {
    const { customer } = this.props.user;
    if (
      customer &&
      customer.card_kind &&
      customer.card_last_4 &&
      customer.card_exp_year &&
      customer.card_exp_month
    ) {
      return (
        <div>
          <span>{`${customer.card_kind} ***${customer.card_last_4}`} </span>
          <small>ending in </small>
          <span>{`${customer.card_exp_month}/${customer.card_exp_year}`}</span>
        </div>
      );
    } else {
      return (
        <div className="current-card-details">
          <small>No card information</small>
        </div>
      );
    }
  };

  canSubscribe = () => {
    const { plan, currentPlan } = this.state;

    return plan && (!currentPlan || currentPlan.id !== plan.id);
  };

  getPayAmount = () => {
    let amount = this.state.selectedPlan.amount / 100;

    if (this.state.coupon) {
      const { percent_off, name, amount_off } = this.state.coupon;
      //discount type is fixed discount
      if (name === "fixedDiscount") {
        //discount is bigger than the cost
        if (amount < amount_off / 100) {
          amount = 0;
          //discount is smaller than the cost
        } else {
          amount = (amount - amount_off / 100).toFixed(2);
        }
        //discount type is percentage
      } else {
        if (percent_off > 0) {
          amount = ((amount * (100 - percent_off)) / 100).toFixed(2);
        }
      }
    } else {
      amount = amount.toFixed(2);
    }

    return `Subscribe for $${amount}`;
  };

  onClickLogout = () => {
    store.dispatch({
      type: "USER_LOGOUT",
    });
  };

  renderCardInputModal() {
    const { isPro, isProPlus } = this.props;
    return (
      <Modal
        show={this.state.showCardInput}
        onHide={() => {
          this.setState({
            showCardInput: false,
            selectedPlan: null,
            changeCard: false,
            coupon: null,
            discountCode: "",
          });
        }}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="logo">
              <span
                className="brand-logo"
                style={{
                  marginRight: 4,
                }}
              >
                MOMO
              </span>
              {(isPro || isProPlus) && (
                <div style={{ marginTop: 6 }}>
                  <span
                    style={{
                      paddingLeft: 2,
                      paddingRight: 2,
                      fontSize: "13px",
                      color: "#000000",
                      fontWeight: "600",
                      background: "#ffff",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {isPro && "PRO"}
                    {isProPlus && "PRO +"}
                  </span>
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.state.selectedPlan && (
            <React.Fragment>
              <h4>Payment</h4>
              <h4>
                {" "}
                {">>"} Selected Plan:{" "}
                <span className="text-success">
                  {this.state.selectedPlan.nickname}
                </span>
              </h4>
            </React.Fragment>
          )}

          {this.props.user.customer && !this.state.changeCard ? (
            <Form.Group>
              <label htmlFor="currentCard">Current Card</label>
              <div id="currentCard">
                {this.renderCurrentCard()}
                <button
                  className="mx-4 text-primary btn"
                  onClick={() => {
                    this.setState({ changeCard: true });
                  }}
                >
                  <small>Change</small>
                </button>
              </div>
            </Form.Group>
          ) : null}

          {this.state.changeCard && (
            <React.Fragment>
              <Form.Group>
                <label>Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                  <Form.Control
                    type="text"
                    className="form-control text-light"
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <label>Card Number</label>
                {this.renderStripeCard()}
              </Form.Group>
            </React.Fragment>
          )}

          {this.state.selectedPlan ? (
            <Form.Group className="couponWrapper">
              <div className="row couponContainer">
                <div className="col-4">
                  <Form.Control
                    placeholder="Discount Code"
                    type="text"
                    className={`form-control ${
                      !this.state.discountCode ? null : "activeTyping"
                    }`}
                    value={this.state.discountCode}
                    onChange={(e) => {
                      this.setState({
                        discountCode: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="btnContainer">
                  <Button
                    disabled={!this.state.discountCode}
                    className={`coupon-apply ${
                      !this.state.discountCode ? "disabled" : "activeBtn"
                    }`}
                    onClick={this.onClickApplyCoupon}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Form.Group>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <div className="footer-container">
            {this.state.subscribing ? (
              <Button variant="success col-12" disabled className="payBt">
                Processing...
              </Button>
            ) : (
              <Button
                variant="success col-12"
                onClick={this.onClickSaveCard}
                className="payBt"
              >
                {this.state.selectedPlan ? this.getPayAmount() : "Save"}
              </Button>
            )}
            <div
              style={{
                marginTop: "5px",
                fontSize: "0.8rem",
                textAlign: "center",
                color: "#e8eff4",
              }}
            >
              following 5 day trial
            </div>
          </div>
          {/*<Button variant='light m-2' onClick={() => { this.setState({ showCardInput: false }) }}>Cancel</Button>*/}
        </Modal.Footer>
      </Modal>
    );
  }

  renderSuccessModal() {
    return (
      <Modal
        show={this.state.success}
        onHide={() => {
          this.props.history.replace("/dashboard");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="h3">Payment complete</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <Button
              variant="success col-12"
              onClick={() => {
                this.props.history.replace("/dashboard");
              }}
              className="successBt"
            >
              Start using MOMO
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-muted text-center w-100">
            You may manage your account under settings
          </p>
        </Modal.Footer>
      </Modal>
    );
  }

  renderCancelSubscriptionModal() {
    return (
      <Modal
        show={this.state.showCancelSubscriptionModal}
        onHide={() => {
          this.setState({
            showCancelSubscriptionModal: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="h3">Confirm</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-left">Are you sure you want to cancel?</div>
          <div className="text-left">
            Your plan will expire at the end of the payment period.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({
                showCancelSubscriptionModal: false,
              });
            }}
          >
            Go Back
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({
                showCancelSubscriptionModal: false,
              });
              this.cancelSubscription();
            }}
          >
            &nbsp;OK&nbsp;
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderStripeCard() {
    return (
      <div className="p-1 card-container">
        <Elements stripe={stripePromise} className="p-4 b-1">
          <ElementsConsumer>
            {({ elements, stripe }) => {
              this.elements = elements;
              this.stripe = stripe;
              return (
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#ffffff",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              );
            }}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }

  getPlanTitle(titleId) {
    let title = "";

    if (titleId === PLANID.STANDARD) {
      title = "MOMO Standard";
    } else if (titleId === PLANID.PRO) {
      title = "MOMO Pro";
    } else if (
      titleId === PLANID.PRO_SEMI_ANNUAL ||
      titleId === PLANID.PRO_SEMI_ANNUAL_NEW ||
      titleId === PLANID.PRO_SEMI_ANNUAL_V1_NEW
    ) {
      title = "MOMO Pro (Semi-Annual)";
    } else if (
      titleId === PLANID.NEW_STANDARD ||
      titleId === PLANID.V1_NEW_STANDARD
    ) {
      title = "MOMO Standard";
    } else if (titleId === PLANID.NEW_PRO || titleId === PLANID.V1_NEW_PRO) {
      title = "MOMO Pro";
    } else if (
      titleId === PLANID.NEW_PROPLUS ||
      titleId === PLANID.V1_NEW_PROPLUS
    ) {
      title = "MOMO Pro +";
    } else if (
      titleId === PLANID.NEW_STANDARD_ANNUAL ||
      titleId === PLANID.V1_NEW_STANDARD_ANNUAL
    ) {
      title = "MOMO Standard (Annual)";
    } else if (
      titleId === PLANID.NEW_PRO_ANNUAL ||
      titleId === PLANID.V1_NEW_PRO_ANNUAL
    ) {
      title = "MOMO Pro (Annual)";
    } else if (
      titleId === PLANID.NEW_PROPLUS_ANNUAL ||
      titleId === PLANID.V1_NEW_PROPLUS_ANNUAL
    ) {
      title = "MOMO Pro + (Annual)";
    } else {
      title = titleId;
    }

    return title;
  }

  getPlanIntervalShortname(interval) {
    let shortname = "";
    switch (interval) {
      case "month":
        shortname = "mo";
        break;
      case "year":
        shortname = "yr";
        break;
      default:
        shortname = interval;
    }
    return shortname;
  }

  getPlanItem(plan) {
    return (
      !plan || (
        <div className="account-plan-item">
          <div className="d-flex justify-content-between align-items-center">
            <span
              className={`custom-radio ${plan.selected ? "active" : ""}`}
              onClick={() => {
                const { plans } = this.state;
                plans.forEach((item) => {
                  if (item.id === plan.id) {
                    item.selected = true;
                  } else {
                    item.selected = false;
                  }
                });
                this.setState({
                  plans,
                });
              }}
            ></span>
            <span className="plan-title flex-grow-1">
              {this.getPlanTitle(plan.id)}
            </span>
          </div>
          <span className="plan-detail">
            ${(plan.amount / 100).toFixed(2)}/
            {plan.interval_count > 1 ? plan.interval_count + " " : ""}
            {this.getPlanIntervalShortname(plan.interval)}
          </span>
        </div>
      )
    );
  }

  onUpdatePlan = () => {
    const { plans } = this.state;
    const { subscription } = this.props.user;

    const selectedPlan = plans.filter((plan) => plan.selected)[0];
    if (subscription && selectedPlan.id === subscription.plan) {
      return;
    }

    this.onSelectPlan(selectedPlan);
  };

  onSignOut = () => {
    store.dispatch({
      type: "USER_LOGOUT",
    });
  };

  signoutBtnVisible() {
    const { user } = this.props;
    return !isActiveSubscription(user.subscription);
  }

  brandVisible() {
    const { user } = this.props;
    return (
      !isActiveSubscription(user.subscription) &&
      !hasSubscription(user.subscription)
    );
  }

  render() {
    const { plans, currentPlan, tabActiveKey } = this.state;
    const {
      isPro,
      isProPlus,
      user: { customer, subscription },
    } = this.props;
    const availablePlans = this.activePlans.filter((plan) => {
      if ([PLANID.STANDARD, PLANID.V1_NEW_STANDARD].includes(currentPlan?.id)) {
        return plan != PLANID.NEW_STANDARD;
      }
      if ([PLANID.PRO, PLANID.V1_NEW_PRO].includes(currentPlan?.id)) {
        return plan != PLANID.NEW_PRO;
      }
      if (currentPlan?.id === PLANID.V1_NEW_PROPLUS) {
        return plan != PLANID.NEW_PROPLUS;
      }
      if (currentPlan?.id === PLANID.V1_NEW_STANDARD_ANNUAL) {
        return plan != PLANID.NEW_STANDARD_ANNUAL;
      }
      if (currentPlan?.id === PLANID.V1_NEW_PRO_ANNUAL) {
        return plan != PLANID.NEW_PRO_ANNUAL;
      }
      if (currentPlan?.id === PLANID.V1_NEW_PROPLUS_ANNUAL) {
        return plan != PLANID.NEW_PROPLUS_ANNUAL;
      }
      return true;
    });
    return (
      <div>
        {this.renderCardInputModal()}
        {this.renderCancelSubscriptionModal()}
        {this.renderSuccessModal()}
        {!this.brandVisible() || (
          <div
            className="logo mb-sm-3 mb-1"
            style={{
              display: "flex",
              flexDirection: "row",
              fontFamily: "Lato",
            }}
          >
            <span
              className="brand-logo"
              style={{
                marginRight: 4,
              }}
            >
              MOMO
            </span>
            {(isPro || isProPlus) && (
              <div style={{ marginTop: 6 }}>
                <span
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: "13px",
                    color: "#000000",
                    fontWeight: "600",
                    background: "#ffff",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isPro && "PRO"}
                  {isProPlus && "PRO +"}
                </span>
              </div>
            )}
          </div>
        )}
        <div className="align-items-center auth px-0">
          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <h2 className="text-left">Select your Plan</h2>
            <div className="d-flex flex-column text-right">
              <span>Change your plan at anytime.</span>
              {!hasSubscription(subscription) || (
                <span>
                  <span
                    className="btn-cancel-subscription"
                    onClick={() => {
                      this.onClickCancelSubscription();
                    }}
                  >
                    Cancel subscription
                  </span>
                </span>
              )}
            </div>
          </div>
          {/* <div className='div text-center'>
            <div className='card p-2 col-md-4 my_card'>
              {customer && <Form.Group>
                <label>Your Card</label>
                <Button variant='primary' className='change_card' onClick={() => { this.setState({ showCardInput: true, changeCard: true }) }} size='md'>Change</Button>
                {this.renderCurrentCard()}
              </Form.Group>}
            </div>
          </div> */}
          {hasSubscription(subscription) ? (
            <div className="row mx-0">
              <div className="col-12 col-md-10 offset-0 offset-md-1 px-2 px-md-4">
                <div className="mt-2 mt-md-4" style={{ fontSize: "0.875rem" }}>
                  Currently Selected Plan:
                </div>
                {plans.map((plan, index) => {
                  if (!currentPlan || currentPlan.id !== plan.id) {
                    return null;
                  }
                  return <div key={index}>{this.getPlanItem(plan)}</div>;
                })}
                <div className="mt-2 mt-md-5" style={{ fontSize: "0.875rem" }}>
                  Available Plans:
                </div>
                {plans
                  .filter((plan) => {
                    return availablePlans.includes(plan.id);
                  })
                  .map((plan, index) => {
                    if (currentPlan && currentPlan.id === plan.id) {
                      return null;
                    }
                    return <div key={index}>{this.getPlanItem(plan)}</div>;
                  })}
                <div className="mt-2 mt-sm-4">
                  &#8251;{" "}
                  <span className="small">
                    Any credit for unused subscription period will be
                    automatically applied
                  </span>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn-sm-block btn btn-primary btn-lg"
                    onClick={() => {
                      this.onUpdatePlan();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <Tab.Container defaultActiveKey="monthly" activeKey={tabActiveKey}>
              <div className="plan-tab-header d-flex align-items-center justify-content-center mt-3 mt-md-0">
                <div
                  className={`plan-tab-header-item ${
                    tabActiveKey === "monthly" ? "active" : ""
                  }`}
                  onClick={() => {
                    this.setState({
                      tabActiveKey: "monthly",
                    });
                  }}
                >
                  <label
                    className={`mb-0 ${
                      tabActiveKey === "monthly" ? "textActive" : ""
                    }`}
                  >
                    Billed Monthly
                  </label>
                </div>
                <div
                  className={`plan-tab-header-item ${
                    tabActiveKey === "annually" ? "active" : ""
                  }`}
                  onClick={() => {
                    this.setState({
                      tabActiveKey: "annually",
                    });
                  }}
                >
                  <label
                    className={`mb-0 ${
                      tabActiveKey === "annually" ? "textActive" : ""
                    }`}
                  >
                    Billed Annually
                  </label>
                </div>
              </div>
              <Tab.Content className="py-1" style={{ border: "none" }}>
                {Object.keys(this.plansByType).map((type, index) => (
                  <Tab.Pane key={`plan-tab-content-${index}`} eventKey={type}>
                    <div className="row mx-0">
                      {plans
                        .filter((plan) =>
                          this.plansByType[type].includes(plan.id)
                        )
                        .map((plan) => {
                          return (
                            <div className="col-lg-4 p-4" key={plan.id}>
                              <div className={`card p-4 plan-card h-100`}>
                                <h3 className="text-center">{plan.nickname}</h3>
                                <h2 className="text-center">
                                  ${(plan.amount / 100).toFixed(2)}
                                </h2>
                                <p className="text-center mt-2 mb-1">
                                  {plan.metadata.description}
                                </p>
                                {(plan.metadata.features || "")
                                  .split(", ")
                                  .filter((feature) => !!feature)
                                  .map((feature, index) => {
                                    return (
                                      <h5
                                        className="my-2"
                                        key={`feature:${index}`}
                                      >
                                        {" "}
                                        - {feature}
                                      </h5>
                                    );
                                  })}
                                <div className="pb-5" />
                                <div className="pb-5" />

                                <div className="bottomDiv text-center">
                                  <React.Fragment>
                                    <Button
                                      variant="success"
                                      onClick={() => {
                                        this.onSelectPlan(plan);
                                      }}
                                      className="cardBt selectBt mb-2"
                                    >
                                      Select
                                    </Button>
                                  </React.Fragment>
                                  {/* {
                                    plan.id === PLANID.PRO_SEMI_ANNUAL || plan.id === PLANID.PRO_SEMI_ANNUAL_NEW
                                    ?
                                    <div className='text-muted'>Renews semi-annually.</div>
                                    :
                                    <div className='text-muted'>Renews every {plan.interval}.</div>
                                  } */}
                                  <div className="text-muted">
                                    Cancel anytime
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          )}
          {!this.signoutBtnVisible() || (
            <div className="row mx-0">
              <div className="text-center w-100">
                <a
                  className="btn-sm-block btn btn-primary btn-lg"
                  onClick={this.onSignOut}
                >
                  Sign Out
                </a>
              </div>
            </div>
          )}
          {/* {!this.props.user.subscription &&
            <div className='row'>
              <div className='text-center col-12'>
                <button className='btn btn-primary' onClick={this.onClickLogout}>Logout</button>
              </div>
            </div>
          } */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) ||
      isProNew(state.auth.user.subscription.plan)),
  isProPlus:
    isActiveSubscription(state.auth.user.subscription) &&
    isProPlusNew(state.auth.user.subscription.plan),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Subscription)
);

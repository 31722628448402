import { createActions, createReducer } from "reduxsauce";

const defaultState = {
  buffer: [],
  bufferSize: 50,
  isPlaying: true,
  timeframe: "1min",
  filter: {
    vol: {
      "1d": 0,
      "5m": 500,
      "1m": 100,
    },
    price: 0,
  },
};

export const { Types, Creators } = createActions({
  addToFlow: ["value"],
  playPause: ["value"],
  updateTimeframe: ["value"],
  updateMinVolume: ["value"],
  updateMinPrice: ["value"],
  updateBufferSize: ["value"],
});

const updateBufferSize = (state, { value }) => {
  return {
    ...state,
    bufferSize: value,
  };
};

const addToFlow = (state, { value }) => {
  const { symbol, ratio } = value;
  let newBuffer = [
    ...state.buffer,
    {
      symbol,
      ratio,
      timestamp: new Date().getTime(),
    },
  ];
  if (newBuffer.length > state.bufferSize) {
    newBuffer = newBuffer.slice(newBuffer.length - state.bufferSize);
  }
  return {
    ...state,
    buffer: newBuffer,
  };
};

const playPause = (state, { value }) => {
  return {
    ...state,
    isPlaying: !!value,
  };
};

const updateTimeframe = (state, { value }) => {
  return {
    ...state,
    timeframe: value,
  };
};

const updateMinVolume = (state, { value }) => {
  const { tf, volume } = value;
  return {
    ...state,
    filter: {
      ...state.filter,
      vol: {
        ...state.filter.vol,
        [tf]: volume,
      },
    },
  };
};

const updateMinPrice = (state, { value }) => {
  return {
    ...state,
    filter: {
      ...state.filter,
      price: value,
    },
  };
};

export const FlowTypes = Types;

export const flowReducer = createReducer(defaultState, {
  [Types.ADD_TO_FLOW]: addToFlow,
  [Types.PLAY_PAUSE]: playPause,
  [Types.UPDATE_TIMEFRAME]: updateTimeframe,
  [Types.UPDATE_MIN_VOLUME]: updateMinVolume,
  [Types.UPDATE_MIN_PRICE]: updateMinPrice,
  [Types.UPDATE_BUFFER_SIZE]: updateBufferSize,
});

export default Creators;

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";

import { AuthActions } from "../store";
import Api from "../api";
import { validateEmail } from "../util";
import i18n from "../../i18n";

class Verification extends Component {
  state = {
    errTxt: "",
    succTxt: "",
    succEmail: "",
  };

  componentDidMount() {
    if (
      new URLSearchParams(this.props.location.search).get("_t") === "invalid"
    ) {
      cogoToast.error(
        <div>
          The one-time email verification link is already been used or is no
          longer valid
        </div>,
        {
          hideAfter: 8,
        }
      );
      this.props.history.replace("/verify");
    }
  }

  // componentWillUpdate() {
  //   console.info('verification page update');
  //   if (this.props.user && this.props.user.email_verified) {
  //     this.props.history.push('/dashboard')
  //   }
  // }

  onResend = () => {
    this.setState({
      errTxt: "",
      succTxt: "",
      succEmail: "",
    });

    const email = this.refEmail.value;

    if (!email || !validateEmail(email)) {
      return cogoToast.error("Please enter a valid email address");
    }

    Api.verify(email)
      .then(() => {
        this.refEmail.value = "";
        this.setState({
          errTxt: "",
          succTxt: "Verification link has been sent successfully",
          succEmail: email,
        });
      })
      .catch((error) => {
        let errTxt = error.toString();
        if (error.toString() === "TypeError: Failed to fetch") {
          errTxt = "Service not available";
        } else {
          errTxt = i18n.getResource("en", ["translations"], errTxt);
          if (!errTxt) {
            errTxt = "Unknown problem";
          }
        }
        this.setState({ errTxt, succTxt: "", succEmail: "" });
      });
  };

  render() {
    const { errTxt, succTxt, succEmail } = this.state;
    return (
      <div className="auth px-0 pt-4 pt-md-5 h-100">
        <div className="row w-100 mx-0">
          <div className="col-xl-4 col-lg-6 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5 min-sm-520">
              <div className="brand-logo">
                <span className="pr-2">MOMO</span>
              </div>
              <h4>Verify Email</h4>
              <div className="mb-2">
                Enter your email address to send a new verification link
              </div>

              <Form.Group className="mb-0">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <i className="input-group-text mdi mdi-email text-momo-green" />
                  </div>
                  <Form.Control
                    type="text"
                    className="form-control text-light form-momo-green"
                    placeholder="Email"
                    ref={(ref) => {
                      this.refEmail = ref;
                    }}
                  />
                </div>
              </Form.Group>

              {errTxt !== "" && (
                <label className="text-danger">{`${errTxt}`}</label>
              )}
              {succTxt !== "" && (
                <label className="text-success mt-3">
                  Link has been sent to{" "}
                  <span style={{ color: "#b5b5b5" }}>{succEmail}</span>{" "}
                  successfully
                </label>
              )}
              <Form className="">
                <div className="mt-3">
                  <a
                    className="btn btn-block btn-momo-green btn-lg font-weight-medium auth-form-btn"
                    onClick={this.onResend}
                  >
                    Resend
                  </a>
                </div>
                <div className="text-center mt-4 font-weight-light">
                  Click to go back to{" "}
                  <Link to="/login" className="text-momo-green">
                    Login
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Verification)
);

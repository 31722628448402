import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import qs from "qs";

import { store } from "../store/createStore";
import { AuthActions } from "../store";
import Api from "../api";
import { validateEmail } from "../util";
import i18n from "../../i18n";

export class Register extends Component {
  state = {
    loginErrTxt: "",
    agreedTerms: false,
    errTxt: "",
    succTxt: "",
    email: "",
    signupSuccess: false,
  };

  source = null;

  componentDidMount() {
    // Test for save plan
    // this.savePlan()
    const { source } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (source === "flow") {
      this.source = source;
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.authenticated && nextProps.email_verified) {
      this.props.history.push("/dashboard");
    }
  }

  onChangeTerms = () => {
    this.setState({ agreedTerms: this.refTerms.checked });
    if (!this.refTerms.checked) {
      this.setState({ loginErrTxt: "" });
    }
  };

  savePlan() {
    const { plan } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    // standard, pro, semi_pro
    if (plan) {
      console.info("Default Plan Saved:", plan);
      window.localStorage.setItem("default_plan", plan);
    }
  }

  onRegister = (e) => {
    e.preventDefault();
    this.setState({ loginErrTxt: "" });
    if (!validateEmail(this.refEmail.value)) {
      this.setState({ loginErrTxt: "Please enter a valid email address" });
      return;
    }
    if (this.refPassword.value !== this.refConfirm.value) {
      this.setState({
        loginErrTxt: i18n.getResource(
          "en",
          ["translations"],
          "password_mismatch"
        ),
      });
      return;
    }

    const email = this.refEmail.value;
    const password = this.refPassword.value;

    this.props.setLoading(true);

    Api.signup({
      email,
      password,
      source: this.source,
    })
      .then(({ success, error, user, access_token }) => {
        this.props.setLoading(false);

        this.user = {
          ...user,
        };
        this.setState({
          email,
          signupSuccess: true,
        });
        this.savePlan();

        // Save Session
        // Api.setSession(access_token);

        // this.props.setUser(user);
        // this.props.setAuthenticated(true);

        // this.props.history.push('/verify');
      })
      .catch((error) => {
        this.props.setLoading(false);
        const errTxt = error.toString();
        console.info(errTxt);
        let loginErrTxt;
        if (errTxt === "TypeError: Failed to fetch") {
          loginErrTxt = "Service not available";
        } else if (errTxt === "invalid_content") {
          loginErrTxt = "Content is invalid. Please try again";
        } else if (errTxt === "duplicate_account_active") {
          loginErrTxt = "Email address is already registered";
        } else if (errTxt === "duplicate_account_pending") {
          loginErrTxt =
            "Email address is pending verification. If you have not received email, then assign different email or wait 2 hours and try again";
          // } else if (errTxt.startsWith('Error: ER_DUP_ENTRY')) {
          //   loginErrTxt = 'You already have an account or have a pending verification';
        } else {
          loginErrTxt = i18n.getResource("en", ["translations"], errTxt);
          if (!loginErrTxt) {
            loginErrTxt = "Failed to signup. Please try again";
          }
        }

        this.setState({ loginErrTxt });
        store.dispatch({
          type: "USER_LOGOUT",
        });
      });
  };

  onResend = () => {
    this.setState({
      errTxt: "",
      succTxt: "",
    });

    if (!this.user || !this.user.email) {
      this.setState({ errTxt: "Email address is invalid" });
      return;
    }

    Api.verify(this.user.email)
      .then(() => {
        this.setState({
          errTxt: "",
          succTxt: "Verification link has been sent successfully",
        });
      })
      .catch((error) => {
        let errTxt = error.toString();
        if (errTxt === "TypeError: Failed to fetch") {
          errTxt = "Service not available";
        } else if (errTxt === "invalid_content") {
          errTxt = "Content is invalid. Please try again";
        } else if (errTxt === "no_user") {
          errTxt = "Email is not registered. Please signup first";
        } else {
          errTxt = i18n.getResource("en", ["translations"], errTxt);
          if (!errTxt) {
            errTxt = "Failed to resend. Please try again";
          }
        }
        this.setState({ errTxt, succTxt: "" });
      });
  };

  render() {
    const { email, loginErrTxt, agreedTerms, signupSuccess, errTxt, succTxt } =
      this.state;
    return (
      <div className="d-flex align-items-md-center auth px-0 pt-4 pt-md-0 vh-100">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5 min-sm-520">
              <div className="brand-logo">
                <span className="pr-2">MOMO Registration</span>
              </div>
              {!signupSuccess ? (
                <div>
                  <h4>New to MOMO Platform?</h4>
                  <h6 className="font-weight-light">
                    {" "}
                    Join us today. It takes only a few steps
                  </h6>
                  <form className="pt-3" onSubmit={this.onRegister}>
                    {/* <Form.Group>
                        <label>Username</label>
                        <div className='input-group'>
                          <div className='input-group-prepend'>
                            <i className='input-group-text mdi mdi-account text-success' />
                          </div>
                          <Form.Control type='text' className='form-control text-light' placeholder='Username'
                            ref={ref => {
                              this.refUser = ref;
                            }}
                          />
                        </div>
                      </Form.Group> */}

                    <Form.Group>
                      <label>Email</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="input-group-text mdi mdi-email text-momo-green" />
                        </div>
                        <Form.Control
                          type="text"
                          className="form-control text-light form-momo-green"
                          placeholder="Email"
                          ref={(ref) => {
                            this.refEmail = ref;
                          }}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group>
                      <label>Password</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="input-group-text mdi mdi-lock text-momo-green" />
                        </div>
                        <Form.Control
                          type="password"
                          className="form-control text-light form-momo-green"
                          placeholder="Password"
                          ref={(ref) => {
                            this.refPassword = ref;
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label>Confirm Password</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="input-group-text mdi mdi-lock text-momo-green" />
                        </div>
                        <Form.Control
                          type="password"
                          className="form-control text-light form-momo-green"
                          placeholder="Password"
                          ref={(ref) => {
                            this.refConfirm = ref;
                          }}
                        />
                      </div>
                    </Form.Group>
                    <div className="mb-4">
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={this.onChangeTerms}
                            ref={(ref) => {
                              this.refTerms = ref;
                            }}
                          />
                          <i className="input-helper"></i>I agree to all{" "}
                          <a
                            href="https://www.mometic.com/privacy.html"
                            className="text-momo-green"
                            target="_blank"
                          >
                            Terms & Conditions
                          </a>
                        </label>
                      </div>
                    </div>
                    {loginErrTxt !== "" && (
                      <label className="text-danger">{`${loginErrTxt}`}</label>
                    )}
                    <div className="mt-3">
                      <button
                        className="btn btn-block btn-momo-green btn-lg font-weight-medium auth-form-btn"
                        type="submit"
                        disabled={!agreedTerms}
                      >
                        SIGN UP {">>"}
                      </button>
                    </div>
                    <div className="text-center mt-4 font-weight-light">
                      Already have an account?{" "}
                      <Link to="/login" className="text-momo-green">
                        Login
                      </Link>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <h4>Verify Email</h4>
                  <div>
                    An email was sent to{" "}
                    <span
                      style={{
                        fontSize: "1.2rem",
                        wordBreak: "break-all",
                        color: "#0090e7",
                        textDecoration: "underline",
                      }}
                    >
                      {email}
                    </span>
                    . Please click the confirmation link in email to continue.
                  </div>

                  {errTxt !== "" && (
                    <label className="text-danger">{`${errTxt}`}</label>
                  )}
                  {succTxt !== "" && (
                    <label className="text-success mt-3">{`${succTxt}`}</label>
                  )}

                  <div className="text-center mt-4 font-weight-light">
                    Didn't receive the email?{" "}
                    <span
                      onClick={this.onResend}
                      className="text-primary link-button"
                      style={{ textDecoration: "none" }}
                    >
                      Click to resend
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortDirection } from "react-virtualized";
import { Spinner } from "react-bootstrap";
import * as _ from "lodash";
import cogoToast from "cogo-toast";

import API from "../../../api";

import { AdminTypes } from "./adminReducer";

import UserTable from "./UserTable";

import styles from "./index.module.scss";

const Admin = () => {
  const dispatch = useDispatch();

  const { data, tableSort } = useSelector((state) => state.admin);

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    dispatch({
      type: AdminTypes.FETCH_USERS,
    });
  }, []);

  const _sort = ({ sortBy }) => {
    const value = {
      sortBy,
      sortDirection: SortDirection.ASC,
    };
    if (sortBy === tableSort.sortBy) {
      value.sortDirection =
        tableSort.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC;
    }
    dispatch({
      type: AdminTypes.UPDATE_TABLE_SORT,
      value,
    });
  };

  const getTableData = () => {
    const { sortBy, sortDirection } = tableSort;
    if (sortBy === "status") {
      return data;
    }
    if (sortBy === "username") {
      return _.orderBy(
        data.filter((item) => item.username),
        sortBy,
        [sortDirection === SortDirection.ASC ? "asc" : "desc"]
      ).concat(data.filter((item) => !item.username));
    }
    return _.orderBy(data, sortBy, [
      sortDirection === SortDirection.ASC ? "asc" : "desc",
    ]);
  };

  const updateUserSetting = async (payload) => {
    if (showSpinner) {
      return;
    }

    try {
      setShowSpinner(true);

      const response = await API.updateUserProfile(payload);

      dispatch({
        type: AdminTypes.UPDATE_USER_SETTING_SUCCEED,
        value: response.content,
      });

      cogoToast.success("User setting has been updated!");
    } catch (e) {
      console.log(e);
      cogoToast.error("Failed to update user setting!");
    }

    setShowSpinner(false);
  };

  return (
    <section>
      {showSpinner && (
        <div className="overlay">
          <Spinner
            className={"overlay-content"}
            animation="border"
            variant="success"
          />
        </div>
      )}
      <h2 className="mb-4">Dashboard</h2>
      <UserTable
        data={getTableData(data)}
        sortBy={tableSort.sortBy}
        sortDirection={tableSort.sortDirection}
        _sort={_sort}
        updateUserSetting={updateUserSetting}
      />
    </section>
  );
};

export default Admin;

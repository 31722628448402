import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { authReducer as auth } from "./auth";
import { configReducer as config } from "./config";
import { settingReducer as setting } from "./setting";
import { discoveryReducer as discovery } from "../discovery/discoveryReducer";
import { quoteReducer as quote } from "../quotes/quoteReducer";
import { dashboardReducer as dashboard } from "../dashboard/dashboardReducer";
import { newsReducer as news } from "../news/newsReducer";
import { chatReducer as chat } from "../chat/chatReducer";
import { flowReducer as flow } from "../flow/flowReducer";
import { adminReducer as admin } from "../user-pages/account/admin/adminReducer";
import { optionsReducer as options } from "./options";
import { stockNamesReducer as stocknames } from "./stocknames";
import { miscReducer as misc } from "./misc";
import { chartReducer as chart } from "../chart/chartReducer";
import { streamReducer as stream } from "../stream/streamReducer";
import { audioPlayerReducer as audioplayer } from "./audioPlayerReducer";

const appReducer = combineReducers({
  auth,
  options,
  config,
  setting,
  discovery,
  quote,
  news,
  chat,
  flow,
  dashboard,
  admin,
  stocknames,
  misc,
  chart,
  stream,
  audioplayer,
});

const rootReducer = (state, action) => {
  if (action && action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    localStorage.removeItem("jwt_access_token");
    state = undefined;
  }

  return appReducer(state, action);
};

export default () => rootReducer;

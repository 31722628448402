import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

function UpgradeButton({ label, buttonLabel, buttonSize }) {
  return (
    <ButtonWrapper className="row mx-0">
      <div className="col-12 px-0">
        {label && <label className="small mr-1">--- {label} ---</label>}
        <a
          className={`btn btn-primary btn-${buttonSize} ml-1`}
          href="/profile/plan"
        >
          {" "}
          {buttonLabel || "Upgrade Now"}
        </a>
      </div>
    </ButtonWrapper>
  );
}

UpgradeButton.propTypes = {
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonSize: PropTypes.oneOf(["sm", "md"]),
};

UpgradeButton.defaultProps = {
  buttonSize: "sm",
};

export default UpgradeButton;

import React from "react";
import cogoToast from "cogo-toast";
import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "../api";
import { ChatTypes } from "./chatReducer";

const getChatData = (state) => state.chat;

function* getChannels() {
  try {
    const response = yield call(API.getChatChannels);
    yield put({
      type: ChatTypes.FETCH_CHANNELS_SUCCEED,
      value: response.channels,
    });

    const { channelSelected } = yield select(getChatData) || {};
    const channelIds = (response.channels || []).map((item) => item.id);
    if (channelIds.length > 0 && !channelIds.includes(channelSelected)) {
      yield put({ type: ChatTypes.SELECT_CHANNEL, value: channelIds[0] });
    }
  } catch (e) {
    console.log(e);
    cogoToast.error("Failed to fetch chat channels!");
  }
}

function* chatSaga() {
  yield takeLatest(ChatTypes.FETCH_CHANNELS, getChannels);
}

export default chatSaga;

import React from "react";
import PropTypes from "prop-types";

import { TIMEFRAME_FILTER } from "../../../constants";

import styles from "./index.module.scss";

function TimeframeSelector(props) {
  const { value, onChangeRate } = props;

  const onClickChangeRate = (dir) => {
    const timeframes = Object.keys(TIMEFRAME_FILTER);
    const index = timeframes.findIndex((item) => item === value);
    const nextIndex = (index + dir + timeframes.length) % timeframes.length;
    onChangeRate && onChangeRate(timeframes[nextIndex]);
  };

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <i
          className={`fa fa-caret-left ${styles["prev-next-button"]}`}
          onClick={() => onClickChangeRate(-1)}
        />
        <span className={styles["rate-label"]}>{TIMEFRAME_FILTER[value]}</span>
        <i
          className={`fa fa-caret-right ${styles["prev-next-button"]}`}
          onClick={() => onClickChangeRate(1)}
        />
      </div>
      <div className={styles["rate-description"]}>
        (triggers will be based on prior {TIMEFRAME_FILTER[value]} closing
        timeframe)
      </div>
    </div>
  );
}

TimeframeSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeRate: PropTypes.func,
};

export default TimeframeSelector;

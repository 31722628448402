import React, { forwardRef, useEffect, useRef, useState } from "react";
import { isAndroid } from "react-device-detect";
import PropTypes from "prop-types";

const SearchInput = forwardRef(function (props, ref) {
  const {
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    onClear,
    className,
    closeVisible,
    variation,
  } = props || {};

  const [search, setSearch] = useState("");
  const searchRef = useRef(search);
  const symbolSearchTimeoutId = useRef(null);

  useEffect(() => {
    setSearch(value);
    if (symbolSearchTimeoutId.current) {
      clearTimeout(symbolSearchTimeoutId.current);
      symbolSearchTimeoutId.current = null;
    }
  }, [value]);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  const onInputChange = (e) => {
    const textValue = e.target.value;
    const boundingRect = e.target.getBoundingClientRect();

    setSearch(textValue);

    if (!isAndroid) {
      onChange && onChange(textValue, boundingRect);
    } else {
      if (symbolSearchTimeoutId.current) {
        clearTimeout(symbolSearchTimeoutId.current);
        symbolSearchTimeoutId.current = null;
      }
      symbolSearchTimeoutId.current = setTimeout(() => {
        symbolSearchTimeoutId.current = null;
        if (textValue !== searchRef.current) {
          return;
        }

        onChange && onChange(textValue, boundingRect);
      }, 800);
    }
  };

  return (
    <div
      className={`search-bar-wrapper filter-bar-wrapper filter-bar-wrapper-hover ${className || ""}`}
    >
      <input
        ref={ref}
        className={`search-bar ${variation ? `variation-${variation}` : ""}`}
        placeholder={placeholder || ""}
        value={search}
        onChange={onInputChange}
        onFocus={(e) => onFocus && onFocus(e)}
        onBlur={(e) => onBlur && onBlur(e)}
        style={{ border: "none" }}
      />
      <div className="search-icon-wrapper">
        {value || closeVisible ? (
          <i
            className="fa fa-close text-white"
            style={{ cursor: "pointer", width: "14px" }}
            onClick={() => onClear && onClear()}
          />
        ) : (
          <i
            className="fa fa-search text-white"
            style={{ cursor: "default", width: "14px" }}
          />
        )}
      </div>
    </div>
  );
});

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  className: PropTypes.string,
  closeVisible: PropTypes.bool,
  variation: PropTypes.oneOf(["chat"]),
};

export default SearchInput;

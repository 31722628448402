import React, { useEffect, useCallback, useRef, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import FlowFilter from "./FlowFilter";
import FlowSymbolItem, { FlowSymbolItemWidth } from "./FlowSymbolItem";
import UpgradeButton from "../shared/Button/UpgradeButton";
import { isActiveSubscription } from "../constants";

import { useDataSource } from "../../contexts/datasource/provider";

import { Types as FlowActionTypes } from "./flowReducer";

import FlowService from "./FlowService";

import styles from "./index.module.scss";

const bufferBackupSize = 10;

function Flow({ onFlowAdd }) {
  const dispatch = useDispatch();

  const { maximisedView } = useSelector((state) => state.dashboard);
  const { buffer, timeframe, filter, isPlaying } = useSelector(
    (state) => state.flow
  );
  const {
    user: { subscription },
  } = useSelector((state) => state.auth);

  const datasource = useDataSource();

  const backupBuffer = useRef([]);
  const eventBuffer = useRef([]);
  const elemFlowWrapper = useRef(null);

  const [serviceEnabled, setServiceEnabled] = useState(true);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const bufferSize = parseInt(
        entries[0].target.clientWidth / FlowSymbolItemWidth
      );
      dispatch({
        type: FlowActionTypes.UPDATE_BUFFER_SIZE,
        value: bufferSize + 5,
      });
    });
    observer.observe(elemFlowWrapper.current);

    return () => observer.disconnect();
  }, [elemFlowWrapper.current]);

  useEffect(() => {
    if (datasource.primary) {
      datasource.primary.on("flowMessage", onReceiveFlowMessage);
    }

    if (!isPlaying) {
      eventBuffer.current = [];
      backupBuffer.current = [];
    }

    return () => {
      datasource.primary?.off("flowMessage", onReceiveFlowMessage);
    };
  }, [datasource.primary, isPlaying]);

  useEffect(() => {
    if (datasource.primary) {
      FlowService.init(datasource.primary);
    }

    if (datasource.primary && timeframe && isPlaying) {
      FlowService.subscribe(timeframe);
      datasource.primary.on("connected", onPrimarySocketConnected);
    }

    return () => {
      FlowService.unsubscribe();
      datasource.primary?.off("connected", onPrimarySocketConnected);
    };
  }, [datasource.primary, timeframe, isPlaying]);

  useEffect(() => {
    let timerId;
    if (isPlaying) {
      timerId = setInterval(() => {
        flushBuffer();
      }, 1 * 1000);
      flushBuffer();
    }

    return () => {
      timerId && clearInterval(timerId);
    };
  }, [timeframe, filter, isPlaying]);

  const onPrimarySocketConnected = useCallback(() => {
    isPlaying && FlowService.subscribe(timeframe);
  }, [timeframe, isPlaying]);

  const onReceiveFlowMessage = useCallback(
    ({ detail }) => {
      if (detail.t === "upgrade") {
        setServiceEnabled(false);
      } else {
        isPlaying && eventBuffer.current.unshift(detail);
      }
    },
    [isPlaying]
  );

  const flushBuffer = useCallback(() => {
    // const testSymbols = ['AAPL', 'MSFT', 'QQQ', 'ESYL'];
    // const symbol = _.sample(testSymbols);
    // const ratio = Number((Math.random() * 10).toFixed(2));
    // dispatch({
    //   type: FlowActionTypes.ADD_TO_FLOW,
    //   value: {
    //     symbol, ratio
    //   }
    // })
    // onFlowAdd && symbol && onFlowAdd(symbol);
    // return;
    const tfMap = {
      "1min": "1m",
      "5min": "5m",
    };
    let topSpike;
    const backupBufferUpdated = [
      ...eventBuffer.current,
      ...backupBuffer.current,
    ];
    backupBufferUpdated.forEach((bufferItem, index) => {
      const filteredBuffer = bufferItem
        .filter((item) => item.av >= filter.vol["1d"])
        .filter((item) => item.v >= filter.vol[tfMap[timeframe]])
        .filter((item) => item.p >= filter.price);
      if (topSpike) {
        if (Array.isArray(bufferItem)) {
          backupBufferUpdated[index] = bufferItem.filter(
            (item) => item.s !== topSpike.s
          );
        }
      } else {
        if (Array.isArray(filteredBuffer) && filteredBuffer.length > 0) {
          topSpike = filteredBuffer[0];
          backupBufferUpdated[index] = bufferItem.filter(
            (item) => item.s !== topSpike.s || item.v !== topSpike.v
          );
        }
      }
    });
    if (backupBufferUpdated.length > bufferBackupSize) {
      backupBufferUpdated.splice(bufferBackupSize);
    }
    backupBuffer.current = backupBufferUpdated;
    eventBuffer.current = [];

    dispatch({
      type: FlowActionTypes.ADD_TO_FLOW,
      value: {
        symbol: topSpike?.s || "",
        ratio: topSpike?.zc || 0,
      },
    });
    onFlowAdd && topSpike?.s && onFlowAdd(topSpike?.s);
  }, [timeframe, filter]);

  const topRatio = _.max(buffer.map((item) => item.ratio));

  return (
    <div
      ref={elemFlowWrapper}
      className={`card w-100 ${maximisedView ? "h-auto" : "h-100"} px-3 pt-3 pb-2`}
      style={{
        overflow: "hidden",
        position: "relative",
      }}
    >
      <FlowFilter />
      <div className={styles["flow-container-content-wrapper"]}>
        <div className={styles["flow-container-content-panel"]}>
          {buffer.map(({ symbol, ratio, timestamp }, index) => (
            <FlowSymbolItem
              key={`${symbol}-${timestamp}`}
              symbol={symbol}
              ratio={ratio}
              topRatio={topRatio}
              position={buffer.length - index - 1}
            />
          ))}
        </div>
      </div>
      {!serviceEnabled && (
        <div className={styles["flow-upgrade-overlay"]}>
          <div>Your daily free use of MOMO Flow has expired.</div>
          <div className="mb-2">
            You may register below for unlimited use or return tomorrow to
            restart 30min timer.
          </div>
          <UpgradeButton
            {...(!isActiveSubscription(subscription) && {
              buttonLabel: "Subscribe Now",
            })}
            buttonSize="md"
          />
        </div>
      )}
    </div>
  );
}

Flow.propTypes = {
  onFlowAdd: PropTypes.func,
};

export default Flow;

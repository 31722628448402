import { createActions, createReducer } from "reduxsauce";
import _ from "lodash";

const initialState = {
  masterVolume: 1,
  masterMuted: false,
  contexts: {
    default: {
      volume: 1,
      muted: false,
    },
  },
};

function setMasterVolume(state, action) {
  return {
    ...state,
    masterVolume: action.value,
  };
}
function setMuted(state, action) {
  return {
    ...state,
    masterMuted: action.value,
  };
}
function setContextVolume(state, { context, value }) {
  return _.set(_.cloneDeep(state), `contexts.${context}.volume`, value);
}
function setContextMuted(state, { context, value }) {
  return _.set(_.cloneDeep(state), `contexts.${context}.muted`, value);
}

const { Types, Creators } = createActions({
  setMasterVolume: ["value"],
  setMasterMuted: ["value"],
  setContextVolume: ["context", "value"],
  setContextMuted: ["context", "value"],
});

const audioPlayerReducer = createReducer(initialState, {
  [Types.SET_MASTER_VOLUME]: setMasterVolume,
  [Types.SET_MASTER_MUTED]: setMuted,
  [Types.SET_CONTEXT_VOLUME]: setContextVolume,
  [Types.SET_CONTEXT_MUTED]: setContextMuted,
});

export default Creators;
export { audioPlayerReducer };

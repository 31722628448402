import React from "react";
import cogoToast from "cogo-toast";
import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../api";
import { AdminTypes } from "./adminReducer";

function* getUsers() {
  try {
    const response = yield call(API.getUsers);
    yield put({ type: AdminTypes.FETCH_USERS_SUCCEED, value: response.users });
  } catch (e) {
    console.log(e);
    cogoToast.error("Failed to fetch user list!");
  }
}

function* adminSaga() {
  yield takeLatest(AdminTypes.FETCH_USERS, getUsers);
}

export default adminSaga;

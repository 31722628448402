import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

function MsgTypingStatus({ username, others }) {
  if (!username) {
    return null;
  }

  return (
    <div className={styles["typing-status"]}>
      <div className={styles["typing-indicator"]}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div>
        <span className={styles["typing-username"]}>{username}</span>
        {others ? (
          <span>
            {" "}
            and <span className={styles["typing-others"]}>
              {others} others
            </span>{" "}
            are
          </span>
        ) : (
          <span> is</span>
        )}
        <span> typing</span>
      </div>
    </div>
  );
}

MsgTypingStatus.propTypes = {
  username: PropTypes.string.isRequired,
  others: PropTypes.number,
};

export default MsgTypingStatus;

import {
  CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA,
  CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER,
  LAST_AT_FILTER_UNIT,
  TREND_CHANGE_TYPE,
  TREND_TYPE,
} from "../../constants";

export const isMoneyColumn = (column) => {
  return ["last", "mf_price", "marketCap"].includes(column);
};

export const isPercentColumn = (column) => {
  return [
    "price_dist",
    "uVol",
    "vWapDist",
    "mf_dist",
    "rv_dist",
    "atr",
    "gap",
    "rs",
    "tradeCount_dist",
  ].includes(column);
};

export const isBillionColumn = (column) => {
  return ["marketCap"].includes(column);
};

export const getConditionalAlertColumnTitle = (col) => {
  if (col === "avgVolume") {
    return "Avg Volume";
  }
  if (col === "float") {
    return "Float";
  }
  if (col === "last") {
    return "Last";
  }
  if (col === "marketCap") {
    return "Market Cap";
  }
  if (col === "momentum") {
    return "Momentum";
  }
  if (col === "mf_price") {
    return "Money Flow $";
  }
  if (col === "mf_dist") {
    return "Money Flow %";
  }
  if (col === "price_dist") {
    return "Last %";
  }
  if (col === "squeeze") {
    return "Squeeze";
  }
  if (col === "tradeCount") {
    return "Trades";
  }
  if (col === "tradeCount_dist") {
    return "Trades %";
  }
  if (col === "trend") {
    return "Trend";
  }
  if (col === "trend_change") {
    return "Trend Change";
  }
  if (col === "uVol") {
    return "UVol";
  }
  if (col === "vWapDist") {
    return "VWAPDist";
  }
  if (col === "rs") {
    return "RelStr %";
  }
  if (col === "rv_price") {
    return "Volume";
  }
  if (col === "rv_dist") {
    return "Volume %";
  }
  if (col === "short_ratio") {
    return "Short Ratio";
  }
  if (col === "atr") {
    return "ATR %";
  }
  if (col === "gap") {
    return "Gap %";
  }
  if (col === "news") {
    return "News";
  }
  if (col === "halt") {
    return "Halt";
  }
  if (col === "category") {
    return "Category";
  }
  return col;
};

export const isMinMaxColumn = (col, type) => {
  if (type === CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA) {
    return [
      "avgVolume",
      "float",
      "last",
      "marketCap",
      "mf_price",
      "short_ratio",
      "rs",
      "rv_price",
      "atr",
      "gap",
      "vWapDist",
      "tradeCount",
    ].includes(col);
  } else if (type === CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER) {
    return false;
  } else {
    return false;
  }
};

export const isSingleColumn = (col, type) => {
  if (type === CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA) {
    return false;
  } else if (type === CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER) {
    return [
      "atr",
      "price_dist",
      "momentum",
      "mf_price",
      "mf_dist",
      "rs",
      "uVol",
      "rv_price",
      "rv_dist",
      "tradeCount",
      "tradeCount_dist",
      "vWapDist",
    ].includes(col);
  } else {
    return false;
  }
};

export const isBiDirectionalColumn = (col, type) => {
  if (type === CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA) {
    return false;
  } else if (type === CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER) {
    return [
      "price_dist",
      "momentum",
      "rs",
      "rv_dist",
      "tradeCount_dist",
    ].includes(col);
  } else {
    return false;
  }
};

export const validateField = (value, type) => {
  const item = value;
  const result = {
    valid: true,
    emptyColumn: false,
    emptyValue: false,
  };
  if (!item.column) {
    result.valid = false;
    result.emptyColumn = true;
  } else if (item.column === "squeeze") {
    if (!item.min || Object.keys(item.min).length === 0) {
      result.valid = false;
      result.emptyValue = true;
    } else {
      const checkedCnt = Object.keys(item.min).filter(
        (key) => item.min[key]
      ).length;
      if (checkedCnt === 0) {
        result.valid = false;
        result.emptyValue = true;
      }
    }
  } else if (item.column === "trend") {
    if (!item.min) {
      result.valid = false;
      result.emptyValue = true;
    } else if (item.min !== TREND_TYPE.BUY && item.min !== TREND_TYPE.SELL) {
      result.valid = false;
    }
  } else if (item.column === "trend_change") {
    if (!item.min) {
      result.valid = false;
      result.emptyValue = true;
    } else if (
      item.min !== TREND_CHANGE_TYPE.BUY_TO_SELL &&
      item.min !== TREND_CHANGE_TYPE.SELL_TO_BUY
    ) {
      result.valid = false;
    }
  } else if (item.column === "news" || item.column === "halt") {
    if (!item.min || (!item.min.threshold && !item.min.unit)) {
      result.valid = false;
      result.emptyValue = true;
    } else {
      if (
        isNaN(item.min?.threshold) ||
        item.min?.threshold <= 0 ||
        !LAST_AT_FILTER_UNIT.includes(item.min?.unit)
      ) {
        result.valid = false;
      }
    }
  } else if (item.column === "category") {
    if (!item.min || !Array.isArray(item.min) || !item.min.length) {
      result.valid = false;
      result.emptyValue = true;
    }
  } else if (isSingleColumn(item.column, type)) {
    if (!item.min && item.min !== 0 && !item.max && item.max !== 0) {
      result.valid = false;
      result.emptyValue = true;
    }
  } else if (isMinMaxColumn(item.column, type)) {
    if (!item.min && item.min !== 0 && !item.max && item.max !== 0) {
      result.valid = false;
      result.emptyValue = true;
    } else if (
      (item.min || item.min === 0) &&
      (item.max || item.max === 0) &&
      item.max < item.min
    ) {
      result.valid = false;
    }
  }
  return result;
};

export const categorizeValidationResult = (arr) => {
  const result = {
    invalidCnt: 0,
    emptyColumnCnt: 0,
    emptyValueCnt: 0,
    invalidFormat: 0,
  };
  for (const item of arr) {
    if (!item.valid) {
      result.invalidCnt++;
      if (item.emptyColumn) {
        result.emptyColumnCnt++;
      }
      if (item.emptyValue) {
        result.emptyValueCnt++;
      }
      if (!item.emptyColumn && !item.emptyValue) {
        result.invalidFormat++;
      }
    }
  }
  return result;
};

import React from "react";
import moment from "moment-timezone";
import NumAbbr from "number-abbreviate";
import numeral from "numeral";
import PropTypes from "prop-types";
import { Sparklines, SparklinesCurve } from "react-sparklines";
import styles from "./index.module.scss";

const numAbbrExt = new NumAbbr(["k", "m", "b"], 2);

function VolumeMoneyFlowPopupContent(props) {
  const { symbol, dist, loading, data, err } = props || {};

  const items = data || [];
  const color =
    isNaN(dist) || dist === null
      ? "#9b9b9c"
      : dist >= 0
      ? "#00ff6e"
      : "#fb0410";

  const renderSparklines = (points, color) => {
    const data = points || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    return (
      <Sparklines data={data} limit={data.length} height={45} margin={5}>
        <SparklinesCurve
          color={color}
          style={{ strokeWidth: 2, stroke: color }}
        />
        {/* <SparklinesReferenceLine type="avg" style={{ strokeWidth: 1, stroke: color, fill: "none" }} /> */}
      </Sparklines>
    );
  };

  return (
    <div className={`context-menu-style ${styles["context-menu-style"]}`}>
      <div className="mt-2" />
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="">
          <div
            className="mr-3"
            style={{ fontSize: "1.0rem", fontWeight: "bold" }}
          >
            {symbol}
          </div>
          <div className="mr-1" style={{ fontSize: "0.9rem", color: color }}>
            {isNaN(dist) || dist === null
              ? "--"
              : dist > 9999
              ? "> 9999"
              : numeral(dist).format("+0.00")}
            %
          </div>
        </div>
        <div className="flex-grow-1">
          {renderSparklines(items.map((item) => item.val).reverse(), color)}
        </div>
      </div>
      <div className="my-1 symbol-context-menu-divider"></div>
      <div className="mt-2">
        {loading && <div className="text-center my-2">Loading ...</div>}
        {!loading && err && (
          <div
            className="text-center my-2"
            style={{ color: "rgb(155, 155, 156)" }}
          >
            {err}
          </div>
        )}
        {!loading && !err && (
          <div className={styles["volmf-data-list"]}>
            {items.length > 0 && (
              <div className={styles["volmf-item-even"]}>
                <span className="">current</span>
                <span className="">
                  {numAbbrExt.abbreviate(items[0].val, 2)}
                </span>
              </div>
            )}
            {items.slice(1).map((item, index) => {
              return (
                <div
                  key={`volmf-item-${index}`}
                  className={styles[`volmf-item-${index % 2 ? "even" : "odd"}`]}
                >
                  <span className="">
                    {moment
                      .utc(item.t)
                      .tz("America/New_York")
                      .format("MM.DD HH:mm")}
                  </span>
                  <span className="">{numAbbrExt.abbreviate(item.val, 2)}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

VolumeMoneyFlowPopupContent.propTypes = {
  symbol: PropTypes.string.isRequired,
  dist: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.any.isRequired,
  err: PropTypes.any,
};

export default VolumeMoneyFlowPopupContent;

import cogoToast from "cogo-toast";
import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "../api";
import { StockNamesTypes } from "./stocknames";

function* getStockNamesData() {
  try {
    const data = yield call(API.getStockNames);
    yield put({ type: StockNamesTypes.SET_STOCK_NAMES, data });
  } catch (e) {
    console.log(e);
    cogoToast.error("Failed to fetch stock names!");
  }
}

function* stocknamesSaga() {
  yield takeLatest(StockNamesTypes.STOCK_NAMES_FETCH, getStockNamesData);
}

export default stocknamesSaga;

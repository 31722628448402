import { update } from "lodash";
import { createActions, createReducer } from "reduxsauce";
import { CHART_DEFAULT_SYMBOL, CHART_DEFAULT_TIMEFRAME } from "../constants";

const initialState = {
  symbol: CHART_DEFAULT_SYMBOL,
  tf: CHART_DEFAULT_TIMEFRAME,
  color: "#00ff6e",
  source: "",
  recents: [CHART_DEFAULT_SYMBOL],
};

export const { Creators, Types } = createActions({
  updateSymbol: ["symbol", "source"],
  updateTf: ["tf"],
  addRecent: ["symbol"],
  removeRecent: ["symbol"],
  updateColor: ["color"],
  updateSource: ["source"],
});

function updateSymbol(state, { symbol, source }) {
  return { ...state, symbol, source: source ?? "" };
}
function updateTf(state, { tf }) {
  return { ...state, tf };
}
function addRecent(state, { symbol }) {
  if ((state.recents ?? []).includes(symbol)) return state;
  let newRecents = [symbol, ...(state.recents ?? [])];
  if (newRecents.length > 10) newRecents = newRecents.slice(0, 10);

  return {
    ...state,
    recents: newRecents,
  };
}
function removeRecent(state, { symbol }) {
  return {
    ...state,
    recents: state.recents.filter((item) => item !== symbol),
  };
}

function updateColor(state, { color }) {
  return { ...state, color };
}
function updateSource(state, { source }) {
  return { ...state, source };
}

export const chartReducer = createReducer(initialState, {
  [Types.UPDATE_SYMBOL]: updateSymbol,
  [Types.UPDATE_TF]: updateTf,
  [Types.ADD_RECENT]: addRecent,
  [Types.REMOVE_RECENT]: removeRecent,
  [Types.UPDATE_COLOR]: updateColor,
  [Types.UPDATE_SOURCE]: updateSource,
});

export default Creators;

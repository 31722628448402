import { createReducer, createActions } from "reduxsauce";
import {
  AVG_VOL_MAX,
  AVG_VOL_MIN,
  DEFAULT_NEWS_CONFIG,
  PRICE_MAX,
  PRICE_MIN,
  DEFAULT_VOICE_BUFFER,
  DEFAULT_OPTIONS_MODE,
  DEFAULT_STREAM_SETTING_LIST,
  DEFAULT_DISCOVERY_SETTING_LIST,
  DEFAULT_HALT_CONFIG,
  DEFAULT_QUOTE_CONFIG,
} from "../constants";
import { complementConfig } from "../util";

const defaultState = {
  stream: DEFAULT_STREAM_SETTING_LIST,
  discovery: DEFAULT_DISCOVERY_SETTING_LIST,
  news: DEFAULT_NEWS_CONFIG,
  voiceNoti: [],
  voiceBuffer: DEFAULT_VOICE_BUFFER,
  optionsMode: DEFAULT_OPTIONS_MODE,
  halt: DEFAULT_HALT_CONFIG,
  quote: DEFAULT_QUOTE_CONFIG,
};

export const { Types, Creators } = createActions({
  updateOptionsModeFilter: ["value"],
  updateStreamChannelType: ["value"],
  updateStreamChannelMode: ["value"],
  updateStreamChannelHaltLuld: ["value"],
  updateRangeFilter: ["value"],
  updateNewsIconVisible: ["value"],
  updateNewsRecency: ["value"],
  updateNewsHideRF: ["value"],
  updateIndustryFilter: ["value"],
  updateDiscoveryColumns: ["value"],
  updateStreamCustomSymbols: ["value"],
  updateStreamColor: ["value"],
  updateVoiceNoti: ["value"],
  createVoiceNotiItem: ["value"],
  deleteVoiceNotiItem: ["value"],
  updateVoiceBuffer: ["value"],
  updateHaltConfig: ["value"],
  setConfig: ["value"],

  //Non setting config
  updateQuoteConfig: ["value"],
});

export const ConfigTypes = Types;

export const getVolNumber = (strValue) => {
  if (strValue === "MIN") {
    return AVG_VOL_MIN * 1000;
  } else if (strValue === "MAX") {
    return AVG_VOL_MAX * 1000;
  } else if (strValue.indexOf("K") > -1) {
    return parseInt(strValue.replace("K", "")) * 1000;
  } else {
    return parseInt(strValue.replace("M", "") * 1000000);
  }
};

export const priceRangeFormat = (value) => {
  if (value === "MIN") {
    return 0;
  } else if (value === "MAX") {
    return PRICE_MAX;
  } else {
    return value;
  }
};

const updateOptionsModeFilter = (state, { value }) => {
  return {
    ...state,
    optionsMode: value,
  };
};

const updateNewsIconVisible = (state, { value }) => {
  const news = { ...state.news };
  news.icon = value;
  return {
    ...state,
    news: {
      ...news,
    },
  };
};

const updateNewsRecency = (state, { value }) => {
  const news = { ...state.news };
  news.recency = value;
  return {
    ...state,
    news: {
      ...news,
    },
  };
};

const updateNewsHideRF = (state, { value }) => {
  const news = { ...state.news };
  news.hide_rf = value;
  return {
    ...state,
    news: {
      ...news,
    },
  };
};

const updateStreamChannelType = (state, { value }) => {
  const { channel, type } = value;
  return {
    ...state,
    stream: [
      ...(state.stream || []).map((item) => {
        if (item.channel !== channel) return item;
        return {
          ...item,
          type,
        };
      }),
    ],
  };
};

const updateStreamChannelMode = (state, { value }) => {
  const { channel, mode } = value;
  return {
    ...state,
    stream: [
      ...(state.stream || []).map((item) => {
        if (item.channel !== channel) return item;
        return {
          ...item,
          mode,
        };
      }),
    ],
  };
};

const updateStreamChannelHaltLuld = (state, { value }) => {
  const { channel, type } = value;
  return {
    ...state,
    stream: [
      ...(state.stream || []).map((item) => {
        if (item.channel !== channel) return item;
        return {
          ...item,
          haltluld: {
            ...item.haltluld,
            [type]: value.value,
          },
        };
      }),
    ],
  };
};

const updateRangeFilter = (state, { value }) => {
  const { channel, type } = value;
  return {
    ...state,
    stream: [
      ...(state.stream || []).map((item) => {
        if (item.channel !== channel) return item;
        return {
          ...item,
          value: {
            ...item.value,
            [type]: {
              min: value.value.low, //priceRangeFormat(value[0]),
              max: value.value.high, //priceRangeFormat(value[1]),
            },
          },
        };
      }),
    ],
  };
};

const updateIndustryFilter = (state, { value }) => {
  const { channel } = value;
  return {
    ...state,
    stream: [
      ...(state.stream || []).map((item) => {
        if (item.channel !== channel) return item;
        return {
          ...item,
          value: {
            ...item.value,
            industries: {
              ...item.value.industries,
              [value.item]: !item.value.industries[value.item],
            },
          },
        };
      }),
    ],
  };
};

const updateStreamCustomSymbols = (state, { value }) => {
  const { channel, symbols } = value;
  return {
    ...state,
    stream: [
      ...(state.stream || []).map((item) => {
        if (item.channel !== channel) return item;
        return {
          ...item,
          value: {
            ...item.value,
            custom_view: [...symbols],
          },
        };
      }),
    ],
  };
};

const updateStreamColor = (state, { value }) => {
  const { channel, color } = value;
  return {
    ...state,
    stream: [
      ...(state.stream || []).map((item) => {
        if (item.channel !== channel) return item;
        return {
          ...item,
          color,
        };
      }),
    ],
  };
};

const updateDiscoveryColumns = (state, { value }) => {
  const { id, columns } = value;
  return {
    ...state,
    discovery: (state.discovery || DEFAULT_DISCOVERY_SETTING_LIST).map(
      (item) => {
        if (item.id !== id) return item;
        return {
          ...item,
          value: columns,
        };
      }
    ),
  };
};

const updateVoiceNoti = (state, { value }) => {
  const { index, zone, voice, type } = value;
  let { voiceNoti } = state;
  if (!voiceNoti) {
    voiceNoti = [];
  }
  return {
    ...state,
    voiceNoti: [
      ...voiceNoti.map((item, id) => {
        if (id !== index) return item;
        if (value.hasOwnProperty("zone")) item["zone"] = zone;
        if (value.hasOwnProperty("voice")) item["voice"] = voice;
        if (value.hasOwnProperty("type")) item["type"] = type;
        return item;
      }),
    ],
  };
};

const createVoiceNotiItem = (state, { value }) => {
  let { voiceNoti } = state;
  if (!voiceNoti) {
    voiceNoti = [];
  }
  return {
    ...state,
    voiceNoti: [
      ...voiceNoti,
      {
        zone: value.zone,
        voice: "voice1",
        type: "symbol",
      },
    ],
  };
};

const deleteVoiceNotiItem = (state, { value }) => {
  const { index } = value;
  let { voiceNoti } = state;
  if (!voiceNoti) {
    voiceNoti = [];
  }
  voiceNoti.splice(index, 1);
  return {
    ...state,
    voiceNoti: [...voiceNoti],
  };
};

const updateVoiceBuffer = (state, { value }) => {
  if (value == null) value = 0;
  value = Math.max(value, 0);
  value = Math.min(value, 10);
  return {
    ...state,
    voiceBuffer: value,
  };
};

const updateHaltConfig = (state, { value }) => {
  return {
    ...state,
    halt: {
      ...state.halt,
      ...value,
    },
  };
};

const updateQuoteConfig = (state, { value }) => {
  return {
    ...state,
    quote: {
      ...state.quote,
      ...value,
    },
  };
};

const setConfig = (state, { value }) => {
  return {
    ...complementConfig(value),
  };
};

export const configReducer = createReducer(defaultState, {
  [Types.UPDATE_OPTIONS_MODE_FILTER]: updateOptionsModeFilter,
  [Types.UPDATE_STREAM_CHANNEL_TYPE]: updateStreamChannelType,
  [Types.UPDATE_STREAM_CHANNEL_MODE]: updateStreamChannelMode,
  [Types.UPDATE_STREAM_CHANNEL_HALT_LULD]: updateStreamChannelHaltLuld,
  [Types.UPDATE_RANGE_FILTER]: updateRangeFilter,
  [Types.UPDATE_NEWS_ICON_VISIBLE]: updateNewsIconVisible,
  [Types.UPDATE_NEWS_RECENCY]: updateNewsRecency,
  [Types.UPDATE_NEWS_HIDE_RF]: updateNewsHideRF,
  [Types.UPDATE_INDUSTRY_FILTER]: updateIndustryFilter,
  [Types.UPDATE_DISCOVERY_COLUMNS]: updateDiscoveryColumns,
  [Types.UPDATE_STREAM_CUSTOM_SYMBOLS]: updateStreamCustomSymbols,
  [Types.UPDATE_STREAM_COLOR]: updateStreamColor,
  [Types.UPDATE_VOICE_NOTI]: updateVoiceNoti,
  [Types.CREATE_VOICE_NOTI_ITEM]: createVoiceNotiItem,
  [Types.DELETE_VOICE_NOTI_ITEM]: deleteVoiceNotiItem,
  [Types.UPDATE_VOICE_BUFFER]: updateVoiceBuffer,
  [Types.UPDATE_HALT_CONFIG]: updateHaltConfig,
  [Types.SET_CONFIG]: setConfig,

  //Non setting config
  [Types.UPDATE_QUOTE_CONFIG]: updateQuoteConfig,
});

export default Creators;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars-2";

import styles from "./index.module.scss";

function HaltBar(props) {
  const { halts, config, onClose, onResumeEnd } = props;

  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      for (const item of halts) {
        if (item.type === "resume") {
          const mt = moment(new Date(item.t));
          const mnow = moment(new Date());
          const duration = moment.duration(mnow.diff(mt));
          const seconds = parseInt(duration.asSeconds());
          if (seconds > 10) {
            onResumeEnd && onResumeEnd(item.s);
          }
        }
      }
      setUpdateKey((key) => key + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [halts]);

  const renderHaltItem = ({ s, type, t }) => {
    let timeStrElem;
    if (type === "halt") {
      const mt = moment(new Date(t));
      const mnow = moment(new Date());
      const duration = moment.duration(mnow.diff(mt));
      let hours = parseInt(duration.asHours());
      let minutes = parseInt(duration.asMinutes()) % 60;
      let seconds = parseInt(duration.asSeconds()) % 60;
      if (hours < 10) hours = `0${hours}`;
      if (minutes < 10) minutes = `0${minutes}`;
      if (seconds < 10) seconds = `0${seconds}`;
      timeStrElem = (
        <span className="ml-2">{`${hours}:${minutes}:${seconds}`}</span>
      );
    } else {
      timeStrElem = (
        <span className={`${styles["stock-resumed"]} ml-2`}>RESUMED</span>
      );
    }
    return (
      <span key={`halt-item-${s}`} className={styles["halt-item"]}>
        <span>{s}</span>
        {timeStrElem}
      </span>
    );
  };

  const filterAppliedHalts = halts.filter((item) => {
    if (config?.symbolLenLimit) {
      if (item.s.length > 4) {
        return false;
      }
    }
    if (!isNaN(config?.durationLimit) && config?.durationLimit !== null) {
      if (item.type === "halt") {
        const mt = moment(new Date(item.t));
        const mnow = moment(new Date());
        const duration = moment.duration(mnow.diff(mt));
        if (duration.asSeconds() > config?.durationLimit * 3600) {
          return false;
        }
      }
    }
    return true;
  });

  return (
    <div className={styles["halt-bar-container"]}>
      <span className={styles["halt-bar-title"]}>HALTS</span>
      <Scrollbars
        autoHide
        style={{
          width: "100%",
          height: "calc(100%)",
          textTransform: "uppercase",
        }}
        renderTrackVertical={(props) => (
          <div className="track-vertical" {...props} />
        )}
      >
        <div
          key={`halt-bar-${updateKey}`}
          className={styles["halt-bar-symbols"]}
        >
          {filterAppliedHalts.map((item) => renderHaltItem(item))}
          {filterAppliedHalts.length ? null : <span>---</span>}
        </div>
      </Scrollbars>
      <span className={styles["halt-bar-close"]}>
        <i
          className={"mdi mdi-close"}
          onClick={() => {
            onClose && onClose();
          }}
        />
      </span>
    </div>
  );
}

HaltBar.propTypes = {
  halts: PropTypes.array.isRequired,
  config: PropTypes.object,
  onClose: PropTypes.func,
  onResumeEnd: PropTypes.func,
};

export default React.memo(HaltBar, (a, b) => {
  return _.isEqual(a.halts, b.halts);
});

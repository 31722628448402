import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";

import MsgBalloon from "./MsgBalloon";
import MsgDateLabel from "./MsgDateLabel";

import styles from "./index.module.scss";

function MessageList({ disableActions, onReachEnd, onOpenImageViewer }) {
  const _scrollRef = React.useRef();
  const _scrollPos = React.useRef();
  const _channelDataLoaded = React.useRef(false);
  const _messagesPrevLength = React.useRef(0);
  const _messageFirstItemId = React.useRef();

  const { user: auth_user } = useSelector((state) => state.auth);
  const { channelSelected, messages, loadingMessage, channelReachedEnd } =
    useSelector((state) => state.chat);

  React.useEffect(() => {
    _channelDataLoaded.current = false;
    _scrollPos.current = null;
    _messageFirstItemId.current = null;
    _messagesPrevLength.current = 0;
  }, [channelSelected]);

  React.useLayoutEffect(() => {
    if (_scrollRef.current) {
      if (!_channelDataLoaded.current && messages[0]) {
        _scrollRef.current.scrollToBottom();
      }
      if (_channelDataLoaded.current && _scrollPos.current) {
        const values = _scrollRef.current.getValues();
        const prevOffsetBottom =
          _scrollPos.current.scrollHeight - _scrollPos.current.scrollTop;
        const newOffsetTop = values.scrollHeight - prevOffsetBottom;
        _scrollRef.current.scrollTop(newOffsetTop);
      }
    }
    if (messages[0]) {
      _channelDataLoaded.current = true;
    }
  }, [messages[0]]);

  React.useLayoutEffect(() => {
    if (Array.isArray(messages)) {
      if (
        messages.length > _messagesPrevLength.current &&
        _messagesPrevLength.current &&
        _messageFirstItemId.current === messages[0].id &&
        _scrollRef.current &&
        _scrollPos.current
      ) {
        let shouldScrollToBottom;
        if (messages[messages.length - 1]?.user?.id === auth_user?.id) {
          shouldScrollToBottom = true;
        } else {
          const offsetBottom =
            _scrollPos.current.scrollHeight -
            _scrollPos.current.clientHeight -
            _scrollPos.current.scrollTop;
          if (offsetBottom < 120) {
            shouldScrollToBottom = true;
          }
        }
        shouldScrollToBottom && _scrollRef.current.scrollToBottom();
      }
    }
    _messagesPrevLength.current = messages?.length;
    _messageFirstItemId.current = messages[0]?.id;
  }, [messages, auth_user]);

  const onScrollFrame = (ev) => {
    if (_channelDataLoaded.current) {
      if (ev.top == 0) {
        if (Array.isArray(messages) && messages.length) {
          onReachEnd && onReachEnd(messages[0]?.created_at);
        }
      }
      _scrollPos.current = _scrollRef.current.getValues();
    }
  };

  const groupMessages = () => {
    const groups = [];
    let curGroupMsgs = [];
    let curDateStr = null;
    for (const item of messages) {
      const dateStr = moment(item.created_at).format("dddd, MMM Do");
      if (curDateStr != dateStr) {
        if (curGroupMsgs.length) {
          groups.push({
            dateStr: curDateStr,
            messages: curGroupMsgs,
          });
        }
        curDateStr = dateStr;
        curGroupMsgs = [];
      }
      curGroupMsgs.push(item);
    }
    if (curGroupMsgs.length) {
      if (curDateStr === moment().format("dddd, MMM Do")) {
        curDateStr = "Today";
      }
      groups.push({
        dateStr: curDateStr,
        messages: curGroupMsgs,
      });
    }
    return groups;
  };

  return (
    <div className={`${styles["message-list"]}`}>
      <Scrollbars
        ref={_scrollRef}
        autoHide
        style={{
          width: "100%",
          height: "calc(100% - 5px)",
        }}
        renderTrackVertical={(props) => (
          <div className="track-vertical" {...props} />
        )}
        onScrollFrame={onScrollFrame}
      >
        {loadingMessage && (
          <div className={styles["loading-history"]}>loading history...</div>
        )}
        {!loadingMessage && channelReachedEnd && (
          <div className={styles["reached-end"]}>Reached the End</div>
        )}
        {groupMessages().map(({ dateStr, messages }) => (
          <div key={dateStr}>
            <MsgDateLabel date={dateStr} />
            {messages.map(({ id: chatId, ...item }, index) => (
              <MsgBalloon
                key={index}
                {...item}
                chatId={chatId}
                disableActions={disableActions}
                onOpenImageViewer={onOpenImageViewer}
              />
            ))}
          </div>
        ))}
      </Scrollbars>
    </div>
  );
}

MessageList.propTypes = {
  disableActions: PropTypes.bool,
  onReachEnd: PropTypes.func,
  onOpenImageViewer: PropTypes.func,
};

export default MessageList;

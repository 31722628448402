import React from "react";
import { Menu, Item } from "react-contexify";
import { connect } from "react-redux";
import NumAbbr from "number-abbreviate";
import numeral from "numeral";
import { DEFAULT_TREND_DATA, TREND_CONTEXT_MENU_ID } from "../../constants";
import "./style.scss";

class TrendPopup extends React.Component {
  render() {
    const { widget } = this.props;
    const { symbol, trend, pricePoint, ROI } =
      (this.props.trendData || {})[widget] || DEFAULT_TREND_DATA;
    return (
      <Menu
        id={`${TREND_CONTEXT_MENU_ID}-${widget}`}
        className="p-0 trend-context-menu"
        theme={"dark"}
      >
        <div className="context-menu-style">
          <div className="mt-2" />
          {/* <div className="">
            <div className="mr-3" style={{fontSize: '1.0rem', fontWeight: 'bold'}}>{symbol}</div>
          </div> */}
          <div className="d-flex justify-content-between">
            <div className="arrow-price-point mt-1"></div>
            <div className="flex-grow-1 pl-3">
              <div className="row mx-0">
                <span className="col-4 px-0">{trend ? "SELL" : "BUY"}</span>
                <span className="col-2 px-0">@</span>
                <span className="col-6 px-0">
                  {numeral(pricePoint).format("0.00")}
                </span>
              </div>
              {/* <div className="row mx-0">
                <span className="col-6 px-0">ROI :</span>
                <span className="col-6 px-0">{ROI}</span>
              </div> */}
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  trendData: state.discovery.trendData,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(TrendPopup);

import React, { useState, useEffect, createContext, useContext } from "react";
import DataSourcePrimary from "./instances/primary";
import DataSourceRealtime from "./instances/realtime";

export const DataSourceContext = createContext();

const DataSourceProvider = ({ children }) => {
  const [primarySocket, setPrimarySocket] = useState(null);
  const [realtimeSocket, setRealtimeSocket] = useState(null);

  useEffect(() => {
    setPrimarySocket(new DataSourcePrimary());
    setRealtimeSocket(new DataSourceRealtime());
  }, []);

  return (
    <DataSourceContext.Provider
      value={{
        primary: primarySocket,
        realtime: realtimeSocket,
      }}
    >
      {children}
    </DataSourceContext.Provider>
  );
};

export function useDataSource() {
  const datasource = useContext(DataSourceContext);
  return (
    datasource || {
      primary: null,
      realtime: null,
    }
  );
}

export default DataSourceProvider;

import React from "react";
import { DataSourceContext } from "../provider";

export const withDataSource = (WrappedComponent) => {
  return (props) => (
    <DataSourceContext.Consumer>
      {(datasource) => <WrappedComponent {...props} datasource={datasource} />}
    </DataSourceContext.Consumer>
  );
};

import _ from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { withDataSource } from "../../contexts/datasource/hoc/withSocket";

import { DashboardActions, StockNamesActions } from "../store";
import Flow from "../flow/Flow";

import "./dashboard.css";

export class DashboardFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.props.setIsPageFlow(true);
  }

  componentDidMount() {
    // Connect datasource
    if (this.props.datasource.primary) {
      this.onPrimaryDatasourceInit();
    }

    this.props.stockNamesFetch();
  }

  componentWillUnmount() {
    this.props.datasource.primary?.disconnect();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.datasource.primary &&
      this.props.datasource.primary !== prevProps.datasource.primary
    ) {
      this.onPrimaryDatasourceInit();
    }
  }

  onPrimaryDatasourceInit() {
    const { customer, subscription, ...userInfo } = this.props.user;
    this.props.datasource.primary.init(userInfo);
  }

  render() {
    return (
      <div className="row dashboard-content dashboard-flow mx-0">
        <Flow />
      </div>
    );
  }
}

const mapDispatchToProps = {
  stockNamesFetch: StockNamesActions.stockNamesFetch,
  setIsPageFlow: DashboardActions.setIsPageFlow,
};

const mapStateToProps = (state, props) => ({
  user: state.auth.user,
  ...props,
});

export default withDataSource(
  withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardFlow))
  )
);

import React from "react";
import { useMediaQuery } from "react-responsive";

function withScreenSizes(Component) {
  return function WrappedComponent(props) {
    const isAboveSmall = useMediaQuery({ query: "(min-width: 768px)" });
    const isAboveMedium = useMediaQuery({ query: "(min-width: 1024px)" });
    const isAboveLarge = useMediaQuery({ query: "(min-width: 1280px)" });

    return (
      <Component
        {...props}
        aboveSmallScreen={isAboveSmall}
        aboveMediumScreen={isAboveMedium}
        aboveLargeScreen={isAboveLarge}
      />
    );
  };
}

export default withScreenSizes;

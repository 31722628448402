import cogoToast from "cogo-toast";
import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "../api";
import { MiscTypes } from "./misc";

function* getUsernamesData() {
  try {
    const data = yield call(API.getUsernames);
    yield put({ type: MiscTypes.SET_USERNAMES, data });
  } catch (e) {
    console.log(e);
    cogoToast.error("Failed to fetch usernames!");
  }
}

function* stocknamesSaga() {
  yield takeLatest(MiscTypes.USERNAMES_FETCH, getUsernamesData);
}

export default stocknamesSaga;

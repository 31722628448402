class FlowService {
  datasource = null;

  init(DataSource) {
    this.datasource = DataSource;
  }

  subscribe(timeframe) {
    const tfMap = {
      "1min": "1m",
      "5min": "5m",
    };
    this.datasource?.subscribeFlow(tfMap[timeframe]);
  }

  unsubscribe() {
    this.datasource?.unsubscribeFlow();
  }
}

export default new FlowService();

import styled from "styled-components";

export const SettingLabel = styled.span`
  font-size: 14px;
  color: white;
  display: block;
  text-align: right;
  width: fit-content;
  margin-right: 15px;
`;
export const SettingValue = styled.span`
  font-size: 12px;
  color: white;
  text-align: right;
  min-width: 21px;
  margin-left: 8px;
  display: block;
  white-space: nowrap;
`;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const BTN_STATE_PLAY = "BTN-STATE-PLAY";
export const BTN_STATE_PAUSE = "BTN-STATE-PAUSE";

export const PlayPauseButtonWrapper = styled.div`
  cursor: pointer;
  font-size: 20px;
  user-select: none;
`;

function PlayPauseButton({ state, onClick }) {
  return (
    <PlayPauseButtonWrapper onClick={() => onClick && onClick()}>
      {state === BTN_STATE_PLAY && <i className={"mdi mdi-play"} />}
      {state === BTN_STATE_PAUSE && <i className={"mdi mdi-pause"} />}
    </PlayPauseButtonWrapper>
  );
}

PlayPauseButton.propTypes = {
  state: PropTypes.oneOf([BTN_STATE_PLAY, BTN_STATE_PAUSE]).isRequired,
  onClick: PropTypes.func,
};

export default PlayPauseButton;

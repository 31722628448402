import { createActions, createReducer } from "reduxsauce";

export const DEFAULT_FILTER_VALUE = "all";
const MAX_RECENTS = 10;

const defaultState = {
  data: [],
  recents: [],
  filterValue: DEFAULT_FILTER_VALUE,
  loading: false,
  loadingMore: false,
};

export const { Types, Creators } = createActions({
  addToRecent: ["symbol"],
  removeFromRecent: ["symbol"],
  updateFilter: ["value"],
  updateLoadingMore: ["value"],
  newsFetch: [],
  newsFetchMore: [],
  newsFetchSuccess: ["news"],
});

const addToRecent = (state, { symbol }) => {
  const recents = state.recents || [];
  if (recents.indexOf(symbol) === -1) {
    recents.unshift(symbol);
  }
  return {
    ...state,
    recents: [...recents.slice(0, MAX_RECENTS)],
  };
};

const removeFromRecent = (state, { symbol }) => {
  const recents = state.recents || [];
  let filterValue = state.filterValue;
  const index = recents.indexOf(symbol);
  if (index > -1) {
    recents.splice(index, 1);
    if (filterValue == symbol) {
      filterValue = DEFAULT_FILTER_VALUE;

      // TODO: call saga
    }
  }
  return {
    ...state,
    filterValue,
    recents: [...recents],
  };
};

const updateFilter = (state, { value }) => {
  return {
    ...state,
    filterValue: value,
  };
};

const updateLoadingMore = (state, { value }) => {
  return {
    ...state,
    loadingMore: !!value,
  };
};

const newsFetchSuccess = (state, { news }) => {
  const data = Array.isArray(news) ? news : [];
  return {
    ...state,
    data: data,
  };
};

export const NewsTypes = Types;

export const newsReducer = createReducer(defaultState, {
  [Types.NEWS_FETCH_SUCCESS]: newsFetchSuccess,
  [Types.ADD_TO_RECENT]: addToRecent,
  [Types.REMOVE_FROM_RECENT]: removeFromRecent,
  [Types.UPDATE_FILTER]: updateFilter,
  [Types.UPDATE_LOADING_MORE]: updateLoadingMore,
});

export default Creators;

import io from "socket.io-client";
import { CHANNEL_AUTH, CHANNEL_TYPE } from "./channels";

const socketHost = process.env.REACT_APP_STREAM_SOURCE;

export default class DataSource {
  socket;
  type;
  eventListeners;

  constructor(type) {
    this.socket = null;
    this.type = type;
    this.eventListeners = {};
  }

  init(userInfo) {}

  connect(userInfo) {
    this.disconnect();

    this.socket = io(socketHost, {
      transports: ["websocket", "polling"],
      upgrade: true,
    });

    this.socket.on("connect", () => {
      this.socket.emit(CHANNEL_TYPE, "client");
      this.socket.emit(CHANNEL_AUTH, userInfo);

      this.log("connected");

      setTimeout(() => {
        this.emit("connected");
      }, 1000);
    });

    this.socket.on("connect_error", () => {
      // revert to classic upgrade
      this.socket.io.opts.transports = ["polling", "websocket"];
    });

    this.socket.on("disconnect", () => {
      this.log("disconnected");
      this.emit("disconnected");
    });
  }

  disconnect() {
    this.socket && this.socket.disconnect();
    this.socket = null;
  }

  on(event, func) {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
    this.eventListeners[event].push(func);
  }

  off(event, func) {
    if (Array.isArray(this.eventListeners[event])) {
      for (let i = 0; i < this.eventListeners[event].length; i++) {
        const item = this.eventListeners[event][i];
        if (item === func) {
          this.eventListeners[event].splice(i, 1);
          i--;
        }
      }
    }
  }

  emit(event, data) {
    if (Array.isArray(this.eventListeners[event])) {
      for (const func of this.eventListeners[event]) {
        func && func(data);
      }
    }
  }

  log(text) {
    console.log(`Datasource ${this.type}:`, text);
  }
}

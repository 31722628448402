import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

function MsgSendButton({ onClick, disabled }) {
  return (
    <div
      className={`${styles["message-send"]} ${disabled ? styles["send-disabled"] : ""}`}
      onClick={() => !disabled && onClick && onClick()}
    >
      <i className="mdi mdi-chevron-up" />
    </div>
  );
}

MsgSendButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MsgSendButton;

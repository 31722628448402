import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setOptions: ["user"],
  optionsFetch: [],
});

export const OptionsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  options: [],
};

/* ------------- Reducers ------------- */
const setOptions = (state, { options }) => {
  return {
    ...state,
    options: [...options],
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const optionsReducer = createReducer(defaultState, {
  [Types.SET_OPTIONS]: setOptions,
});

import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setUsernames: ["data"],
  usernamesFetch: [],
});

export const MiscTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  usernames: [],
};

/* ------------- Reducers ------------- */
const setUsernames = (state, { data }) => {
  return {
    ...state,
    usernames: data.users || [],
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const miscReducer = createReducer(defaultState, {
  [Types.SET_USERNAMES]: setUsernames,
});

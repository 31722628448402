import React from "react";
import { Menu, Item } from "react-contexify";
import { connect } from "react-redux";
import {
  DEFAULT_TRADECOUNT_DATA,
  TRADE_COUNT_CONTEXT_MENU_ID,
} from "../../constants";
import VolumeMoneyFlowPopupContent from "../VolumeMoneyFlowPopupContent";

class TradeCountPopup extends React.Component {
  render() {
    const { widget } = this.props;
    const { symbol, loading, tf, data, err } =
      (this.props.tradeCountData || {})[widget] || DEFAULT_TRADECOUNT_DATA;

    let dist;
    if (Array.isArray(data) && data.length > 1) {
      if (!data[0].cnt) {
        dist = 0;
      } else if (data[1].cnt <= 0) {
        dist = 100;
      } else {
        dist = parseFloat(((data[0].cnt / data[1].cnt - 1) * 100).toFixed(2));
      }
    }

    return (
      <Menu
        id={`${TRADE_COUNT_CONTEXT_MENU_ID}-${widget}`}
        className="p-0 trade-count-context-menu"
        theme={"dark"}
      >
        <VolumeMoneyFlowPopupContent
          symbol={symbol}
          dist={dist}
          loading={loading}
          data={(data || []).map(({ cnt, t }) => ({
            val: cnt,
            t,
          }))}
          err={err}
        />
      </Menu>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  tradeCountData: state.discovery.tradeCountData,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(TradeCountPopup);

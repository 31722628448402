import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

function StepBar(props) {
  const { steps, current, goToStep } = props;

  const onClickStep = (id) => {
    if (id < current) {
      goToStep && goToStep(id);
    }
  };

  return (
    <div className={styles["step-bar"]}>
      {steps.map(({ id, label }, index) => {
        let badgeClass;
        if (id < current) {
          badgeClass = "step-done";
        } else if (id === current) {
          badgeClass = "step-current";
        }
        return (
          <div
            key={`conditional-alert-step-${id}`}
            className={`${styles["step-item"]} ${styles[badgeClass]}`}
          >
            <div
              className={styles["step-badge"]}
              onClick={() => onClickStep(id)}
            >
              {id < current ? <i className="fa fa-check" /> : <span>{id}</span>}
            </div>
            {index < steps.length - 1 && (
              <span className={styles["step-separater"]}></span>
            )}
          </div>
        );
      })}
    </div>
  );
}

StepBar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  current: PropTypes.number.isRequired,
  goToStep: PropTypes.func,
};

export default StepBar;

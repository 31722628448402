import React from "react";
import cogoToast from "cogo-toast";

import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import API from "../api";
import newsActions, { NewsTypes, DEFAULT_FILTER_VALUE } from "./newsReducer";
import { store } from "../store/createStore";

const getFilterValue = (state) => state.news.filterValue;
const getNewsData = (state) => state.news.data;
const getQuotes = (state) => state.quote.quotes;
const getNewsConfig = (state) => state.config.news;

function getPayload(load_more) {
  const filterValue = store.getState().news.filterValue;
  const newsConfig = store.getState().config.news;

  let symbols = "";
  let ig_rf = newsConfig.hide_rf;
  if (filterValue === "fav") {
    const quotes = store.getState().quote.quotes;
    symbols = (quotes || []).map((item) => item.symbol).join(",");
  } else if (filterValue !== DEFAULT_FILTER_VALUE) {
    ig_rf = false;
    symbols = filterValue;
  }

  const newsCurrentData = store.getState().news.data || [];

  const params = { symbols, ig_rf, publishedAt: "", last_id: "" };
  if (load_more) {
    if (newsCurrentData.length > 0) {
      params.publishedAt =
        newsCurrentData[newsCurrentData.length - 1].publishedAt;
      params.last_id = newsCurrentData[newsCurrentData.length - 1].id;
    }
  }
  return params;
}

function* getNews() {
  try {
    const params = getPayload();
    const { total, articles } = yield call(API.getNews, params);
    yield put(newsActions.newsFetchSuccess(articles));
  } catch (e) {
    console.log(e);
    cogoToast.error("Failed to fetch news!");
  }
}

function* getNewsMore() {
  try {
    yield put(newsActions.updateLoadingMore(true));

    const params = getPayload(true);
    const { total, articles } = yield call(API.getNews, params);

    const newsCurrentData = yield select(getNewsData) || [];
    let newsNewData = [...newsCurrentData, ...articles];
    yield put(newsActions.newsFetchSuccess(newsNewData));
  } catch (e) {
    console.log(e);
    cogoToast.error("Failed to fetch news!");
  } finally {
    yield put(newsActions.updateLoadingMore(false));
  }
}

function* newsSaga() {
  yield takeLatest(NewsTypes.NEWS_FETCH, getNews);
  yield takeLatest(NewsTypes.NEWS_FETCH_MORE, getNewsMore);
  yield takeEvery(NewsTypes.UPDATE_FILTER, getNews);
}

export default newsSaga;

import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const InputWrapper = styled.div`
  padding: 1px;
  border-bottom: 1px solid white;
`;

const Input = styled.input`
  border: none;
  width: 54px;
  background: transparent;
  color: white;
  caret-color: white;
`;

function QuoteInlineInput(props, ref) {
  const { onEnter, value: initialValue } = props;

  const [value, setValue] = useState(initialValue || "");

  return (
    <InputWrapper>
      <Input
        ref={ref}
        value={value}
        onChange={(e) => setValue(e.target.value.toUpperCase())}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter && onEnter(value);
            setValue("");
          }
        }}
      />
    </InputWrapper>
  );
}

const QuoteInlineInputForwarded = forwardRef(QuoteInlineInput);

QuoteInlineInputForwarded.propTypes = {
  onEnter: PropTypes.func,
  value: PropTypes.string,
};

export default QuoteInlineInputForwarded;

import {
  CHANNEL_ALERTS_SUBSCRIBE,
  CHANNEL_ALERTS_UNSUBSCRIBE,
  CHANNEL_DISCOVERY_SUBSCRIBE,
  CHANNEL_DISCOVERY_UNSUBSCRIBE,
  CHANNEL_QUOTES_SUBSCRIBE,
  CHANNEL_QUOTES_UNSUBSCRIBE,
} from "./channels";
import DataSource from "./creator";

export default class DataSourceRealtime extends DataSource {
  constructor() {
    super("realtime");
  }

  init(userInfo) {
    this.connect(userInfo);
  }

  subscribeDiscovery(subscription) {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_DISCOVERY_SUBSCRIBE, subscription);
      this.socket.off("discovery").on("discovery", (data) => {
        this.emit("discovery", { detail: data });
      });
    }
  }

  unsubscribeDiscovery() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_DISCOVERY_UNSUBSCRIBE);
    }
  }

  subscribeQuotes(subscription) {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_QUOTES_SUBSCRIBE, subscription);
      this.socket.off("quotes").on("quotes", (data) => {
        this.emit("quotes", { detail: data });
      });
    }
  }

  unsubscribeQuotes() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_QUOTES_UNSUBSCRIBE);
    }
  }

  subscribeAlerts() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_ALERTS_SUBSCRIBE);
      this.socket.off("alerts").on("alerts", (data) => {
        this.emit("alerts", { detail: data });
      });
    }
  }

  unsubscribeAlerts() {
    if (this.socket && this.socket.connected) {
      this.socket.emit(CHANNEL_ALERTS_UNSUBSCRIBE);
    }
  }
}

import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setStockNames: ["data"],
  stockNamesFetch: [],
});

export const StockNamesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

const defaultState = {
  data: {},
};

/* ------------- Reducers ------------- */
const setStockNames = (state, { data }) => {
  const dataSet = {};
  for (const item of data) {
    dataSet[item.s] = item;
  }
  return {
    ...state,
    data: dataSet,
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const stockNamesReducer = createReducer(defaultState, {
  [Types.SET_STOCK_NAMES]: setStockNames,
});

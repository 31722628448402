import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const colors = [
  "#c40000",
  "#88005a",
  "#491fb6",
  "#0055ff",
  "#008aed",
  "#00b9ca",
  "#00d744",
  "#6ce327",
  "#eedb66",
];

const PaletteWrapper = styled.div`
  display: inline-block;
  height: 24px;
`;

const ColorItem = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 24px;
  ${({ color }) => `
    background-color: ${color};
  `}
  ${({ active }) =>
    active &&
    `
    border: 2px solid white;
  `}
  &:hover {
    border: 2px solid #cccccc;
  }
`;

function ColorPalette(props) {
  const { activeColor, onChangeColor } = props;

  return (
    <PaletteWrapper>
      {colors.map((color) => (
        <ColorItem
          key={color}
          color={color}
          active={color === activeColor}
          onClick={() =>
            onChangeColor && onChangeColor(activeColor === color ? null : color)
          }
        />
      ))}
    </PaletteWrapper>
  );
}

ColorPalette.propTypes = {
  activeColor: PropTypes.string.isRequired,
  onChangeColor: PropTypes.func,
};

export default ColorPalette;

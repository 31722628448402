import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SymbolDetail from "./symbol-detail";
import { SymbolPopupContainer } from "./styles";
import { Types as DashboardActionTypes } from "../../dashboard/dashboardReducer";

const popupWidth = 250;
const popupHeight = 58;

const SymbolPopup = function () {
  const { visible, symbol, onClick, ...position } = useSelector(
    (state) => state.dashboard?.symbolPopup || {}
  );

  const dispatch = useDispatch();

  const [detailKey, setDetailKey] = useState(0);

  useEffect(() => {
    setDetailKey((value) => value + 1);
  }, [symbol]);

  const getPosition = () => {
    const scrollbarWidth = 25; // Don't need to be precise
    let left = Math.min(
      position.left,
      window.innerWidth - popupWidth - scrollbarWidth
    );
    let top = position.bottom;
    if (top > window.innerHeight - popupHeight) {
      top = position.top - popupHeight;
    }

    return {
      left,
      top,
    };
  };

  const onMouseEnter = (e) => {
    dispatch({
      type: DashboardActionTypes.UPDATE_SYMBOL_POPUP,
      value: {
        visible: true,
      },
    });
  };

  const onMouseLeave = (e) => {
    dispatch({
      type: DashboardActionTypes.UPDATE_SYMBOL_POPUP,
      value: {
        visible: false,
      },
    });
  };

  return symbol && visible ? (
    <SymbolPopupContainer
      {...getPosition()}
      width={popupWidth}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <SymbolDetail key={detailKey} symbol={symbol} onClick={onClick} />
    </SymbolPopupContainer>
  ) : null;
};

export default SymbolPopup;

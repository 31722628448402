import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Symbol from "../shared/Symbol";

export const FlowSymbolItemWidth = 40;

const FlowSymbolItemWrapper = styled.div`
  height: 100%;
  width: ${FlowSymbolItemWidth}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ position }) => position * 40}px;
  transition: 1s all linear;
  background-color: #0d0e12;

  .quote-symbol {
    font-size: 12px;
  }
`;

const FlowSymbolBar = styled.div`
  flex-grow: 1;
  width: 8px;
  background: linear-gradient(#009942, #dba00a);
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: ${({ barHeight }) => `${barHeight}%`};
    background-color: #0d0e12;
    transition: all 0.3s ease-in-out;
  }
`;

function FlowSymbolItem({ symbol, ratio, topRatio, position }) {
  let barHeight = topRatio ? parseInt((ratio / topRatio) * 100) : 0;
  if (symbol) {
    barHeight = Math.max(5, parseInt((ratio / topRatio) * 100));
  }

  return (
    <FlowSymbolItemWrapper position={position - 1}>
      <FlowSymbolBar barHeight={barHeight} />
      <Symbol symbol={symbol} />
    </FlowSymbolItemWrapper>
  );
}

FlowSymbolItem.propTypes = {
  symbol: PropTypes.string.isRequired,
  ratio: PropTypes.number.isRequired,
  topRatio: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
};

export default FlowSymbolItem;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AudioPlayerActions } from "../../../store";
import style from "./style.module.scss";
import { SettingLabel, SettingValue } from "./components";

function Pop() {
  const dispatch = useDispatch();

  const masterVolume = useSelector((state) => state.audioplayer.masterVolume);

  return (
    <div
      className={style.root + " audio-control-popup"}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={style.items}>
        <SettingLabel> Volume </SettingLabel>
        <input
          type="range"
          min="0"
          max="100"
          value={masterVolume * 100}
          onChange={(e) => {
            dispatch(AudioPlayerActions.setMasterVolume(e.target.value / 100));
          }}
          className={style.masterVolumeSlider}
        />
        <SettingValue>{Math.round(masterVolume * 100)}</SettingValue>
      </div>
    </div>
  );
}

export default Pop;

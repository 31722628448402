import React from "react";
import { Menu, Item } from "react-contexify";
import { connect } from "react-redux";
import {
  DEFAULT_MONEYFLOW_DATA,
  MONEYFLOW_CONTEXT_MENU_ID,
} from "../../constants";
import VolumeMoneyFlowPopupContent from "../VolumeMoneyFlowPopupContent";

class MoneyFlowPopup extends React.Component {
  render() {
    const { widget } = this.props;
    const { symbol, dist, loading, tf, data, err } =
      (this.props.moneyFlowData || {})[widget] || DEFAULT_MONEYFLOW_DATA;
    return (
      <Menu
        id={`${MONEYFLOW_CONTEXT_MENU_ID}-${widget}`}
        className="p-0 moneyflow-context-menu"
        theme={"dark"}
      >
        <VolumeMoneyFlowPopupContent
          symbol={symbol}
          dist={dist}
          loading={loading}
          data={data || []}
          err={err}
        />
      </Menu>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  moneyFlowData: state.discovery.moneyFlowData,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(MoneyFlowPopup);

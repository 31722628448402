import React, { Component } from "react";

export class Spinner extends Component {
  render() {
    const content = (
      <div
        className="spinner-wrapper"
        style={this.props.small ? { width: "unset", height: "unset" } : {}}
      >
        <div className="donut"></div>
      </div>
    );
    return this.props.small ? content : <div>{content}</div>;
  }
}

export default Spinner;

import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

function LULD(props) {
  const { up, down } = props;

  return (
    <div className={styles["cell-luld"]}>
      <span>
        <span className={styles["up"]}>LU</span> {up}
      </span>
      <span>
        <span className={styles["down"]}>LD</span> {down}
      </span>
    </div>
  );
}

LULD.propTypes = {
  up: PropTypes.number.isRequired,
  down: PropTypes.number.isRequired,
};

export default LULD;

import _ from "lodash";
import { createActions, createReducer } from "reduxsauce";

const defaultState = {
  stream1: {
    shown: { lows: true, highs: true },
  },
  stream2: {
    shown: { lows: true, highs: true },
  },
};

function updateShown(state, { widget, value }) {
  return _.merge({}, state, { [widget]: { shown: value } });
}

const { Types, Creators } = createActions({
  updateShown: ["widget", "value"],
});

export const streamReducer = createReducer(defaultState, {
  [Types.UPDATE_SHOWN]: updateShown,
});

export default Creators;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BarWrapper = styled.div`
  display: block;
  width: 6px;
  height: 24px;
  ${({ color }) =>
    color &&
    `
    background-color: ${color};
  `}
`;

function ColorBar(props) {
  const { color, className } = props;

  return <BarWrapper color={color} className={`${className || ""}`} />;
}

ColorBar.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default ColorBar;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { isMobile } from "react-device-detect";
import cogoToast from "cogo-toast";
import moment from "moment-timezone";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/dist/plugins/counter";
import Download from "yet-another-react-lightbox/dist/plugins/download";
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";

import { useDataSource } from "../../contexts/datasource/provider";
import ChatService, { MessageTypes } from "./ChatService";
import API from "../api";

import { ChatTypes } from "./chatReducer";
import { AuthTypes } from "../store/auth";
import { DashboardActions } from "../store";

import SearchInput from "../shared/SearchInput";
import { LeftArrow, RightArrow } from "../news/Arrows";
import MessageListPanel from "./MessageListPanel";
import MessageInputPanel from "./MessageInputPanel";

import {
  CHAT_FILES_LIMIT_CNT,
  SEARCH_DROPDOWN_MODE_SYMBOL,
  SEARCH_DROPDOWN_MODE_USER,
} from "../constants";

import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/counter/counter.css";
import styles from "./index.module.css";

const imgDropZone = require("../../assets/images/chat/image.png");

function Chat(props) {
  const { maximisedView, updateSearchDropdown, updateSearchDropdownClicked } =
    props;

  const dispatch = useDispatch();
  const { channels, channelSelected, channelReachedEnd, searchInput } =
    useSelector((state) => state.chat);
  const { user: auth_user } = useSelector((state) => state.auth);

  const datasource = useDataSource();

  const [searchValue, setSearchValue] = useState("");
  const [slowModeTimeRemaining, setSlowModeTimeRemaining] = useState(0);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [imageViewerFiles, setImageViewerFiles] = useState([]);
  const [imageViewerIndex, setImageViewerIndex] = React.useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const _scrollbarRef = useRef(null);
  const _draggingCount = useRef(0);

  useEffect(() => {
    dispatch({
      type: ChatTypes.FETCH_CHANNELS,
    });

    loadUserProfile();
  }, []);

  useEffect(() => {
    if (datasource.primary) {
      ChatService.init(datasource.primary);
      ChatService.subscribe();
      datasource.primary.on("connected", onPrimarySocketConnected);
    }

    return () => {
      ChatService.unsubscribe();
      datasource.primary?.off("connected", onPrimarySocketConnected);
    };
  }, [datasource.primary]);

  const loadUserProfile = async () => {
    try {
      const response = await API.getUserProfile();
      dispatch({
        type: AuthTypes.SET_USER_CHAT_BAN,
        value: response.profile?.ban_exp_at,
      });
    } catch (e) {
      console.log(e);
      cogoToast.error("Failed to fetch profile!");
    }
  };

  useEffect(() => {
    if (datasource.primary) {
      datasource.primary.on("chatInboundMessage", onReceiveInboundMessage);
    }

    return () => {
      datasource.primary?.off("chatInboundMessage", onReceiveInboundMessage);
    };
  }, [datasource.primary, channelSelected]);

  const onPrimarySocketConnected = () => {
    ChatService.subscribe();
  };

  const onReceiveInboundMessage = useCallback(
    ({ detail }) => {
      if (detail?.type === MessageTypes.Message) {
        if (detail?.channel_id === channelSelected) {
          dispatch({
            type: ChatTypes.RECEIVE_MESSAGE,
            value: detail,
          });
        }
      } else if (detail?.type === MessageTypes.Vote) {
        if (detail.user?.id === auth_user.id) {
          detail.me_vote = detail.user.vote;
        }
        dispatch({
          type: ChatTypes.RECEIVE_VOTE,
          value: detail,
        });
      } else if (detail?.type === MessageTypes.Typing) {
        const { users, tot } = detail;
        dispatch({
          type: ChatTypes.SET_TYPING_INFO,
          value: {
            users,
            tot,
          },
        });
      } else if (detail?.type === MessageTypes.Ban) {
        dispatch({
          type: AuthTypes.SET_USER_CHAT_BAN,
          value: detail.exp_at,
        });
      }
    },
    [channelSelected, auth_user.id]
  );

  useEffect(() => {
    if (Array.isArray(channels) && channels.length > 0 && channelSelected) {
      dispatch({
        type: ChatTypes.RESET_MESSAGES,
      });
      loadMessages(channelSelected);
    }
  }, [channels, channelSelected, searchInput]);

  const onClickChannel = useCallback((id) => {
    dispatch({
      type: ChatTypes.SELECT_CHANNEL,
      value: id,
    });
  }, []);

  const loadMessages = async (channel_id, page_timestamp) => {
    try {
      dispatch({
        type: ChatTypes.SET_LOADING_MESSAGE,
        value: true,
      });

      const filters = {};
      if (searchInput) {
        if (searchInput.startsWith("@")) {
          filters["u"] = searchInput.slice(1);
        } else {
          filters["s"] = searchInput;
        }
      }
      if (page_timestamp) {
        filters["p_ts"] = page_timestamp;
      }
      const response = await API.getChatMessages(channel_id, filters);
      if (Array.isArray(response.messages)) {
        dispatch({
          type: ChatTypes.SET_MESSAGES,
          value: response.messages.reverse(),
        });
        dispatch({
          type: ChatTypes.SET_CHANNEL_REACHED_END,
          value: response.reachedEnd,
        });
      } else {
        const e = new Error();
        e.message = "Failed to fetch messages";
        throw e;
      }
    } catch (e) {
      console.log(e);
      cogoToast.error("Failed to fetch messages!");
    }
    dispatch({
      type: ChatTypes.SET_LOADING_MESSAGE,
      value: false,
    });
  };

  const onListReachEnd = (created_at) => {
    !channelReachedEnd && loadMessages(channelSelected, created_at);
  };

  const { mode: searchDropdownMode } = useSelector(
    (state) => state?.dashboard?.searchDropdown || {}
  );
  const {
    symbol: searchSymbol,
    source: searchSource,
    extra: searchExtra,
  } = useSelector((state) => state?.dashboard?.searchDropdown?.clicked || {});
  useEffect(() => {
    if (searchSource === "chat" && searchSymbol && searchDropdownMode) {
      updateSearchDropdownClicked("");

      const clickedValue = `${searchDropdownMode === SEARCH_DROPDOWN_MODE_SYMBOL ? "" : "@"}${searchSymbol}`;
      const clickedId =
        searchDropdownMode === SEARCH_DROPDOWN_MODE_SYMBOL
          ? clickedValue
          : `@${searchExtra.id}`;
      dispatch({
        type: ChatTypes.SET_SEARCH_INPUT,
        value: clickedId,
      });
      searchValue !== clickedValue && setSearchValue(clickedValue);
    }
  }, [searchSource, searchSymbol, searchDropdownMode, searchValue]);

  const onChangeSearch = (text, boundingRect) => {
    setSearchValue(text);

    const rect = boundingRect;
    const isUsernameSearch = text.startsWith("@");
    const dropdownSearchText = isUsernameSearch ? text.slice(1) : text;

    updateSearchDropdown({
      visible: true,
      mobileVisible: true,
      mode: isUsernameSearch
        ? SEARCH_DROPDOWN_MODE_USER
        : SEARCH_DROPDOWN_MODE_SYMBOL,
      mobileSearch: dropdownSearchText,
      search: dropdownSearchText,
      source: "chat", // TODO: get classname
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
    });

    dispatch({
      type: ChatTypes.SET_SEARCH_INPUT,
      value: "",
    });
  };

  const onClearSearch = (e) => {
    setSearchValue("");
    updateSearchDropdown({
      mode: "",
      visible: false,
      mobileVisible: false,
      mobileSearch: "",
      search: "",
      source: null,
    });
    dispatch({
      type: ChatTypes.SET_SEARCH_INPUT,
      value: "",
    });
  };

  const onFocusSearch = (e, focused) => {
    if (focused) {
      const rect = e.target.getBoundingClientRect();
      const isUsernameSearch = (searchValue || "").startsWith("@");
      const dropdownSearchText = isUsernameSearch
        ? (searchValue || "").slice(1)
        : searchValue;
      updateSearchDropdown({
        visible: !!searchValue,
        mobileVisible: true,
        mode: isUsernameSearch
          ? SEARCH_DROPDOWN_MODE_USER
          : SEARCH_DROPDOWN_MODE_SYMBOL,
        mobileSearch: dropdownSearchText,
        search: dropdownSearchText,
        source: "chat", // TODO: get classname
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
      });
    } else {
      if (!isMobile) {
        setTimeout(() => {
          updateSearchDropdown({
            mode: "",
            visible: false,
            mobileVisible: false,
            mobileSearch: "",
            search: "",
            source: null,
          });
        }, 200);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("paste", onImagePaste);

    return () => {
      document.removeEventListener("paste", onImagePaste);
    };
  }, [files]);

  const onImagePaste = useCallback(
    async (e) => {
      e.preventDefault();
      const images = [];
      for (const clipboardItem of e.clipboardData.files) {
        if (clipboardItem.type?.startsWith("image/")) {
          images.push(clipboardItem);
        } else if (Array.isArray(clipboardItem.types)) {
          const imageTypes = clipboardItem.types?.filter((type) =>
            type.startsWith("image/")
          );
          for (const imageType of imageTypes) {
            const blob = await clipboardItem.getType(imageType);
            images.push(blob);
          }
        }
      }
      if (files.length + images.length > CHAT_FILES_LIMIT_CNT) {
        cogoToast.warn(
          <span>
            You can upload up to <strong>{CHAT_FILES_LIMIT_CNT} images</strong>{" "}
            at a time.
          </span>
        );
        return;
      }

      setFiles([...files, ...images]);
    },
    [files]
  );

  const onDragEnter = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    _draggingCount.current++;
    if (_draggingCount.current === 1) {
      setIsDragging(true);
    }
  }, []);

  const onDragLeave = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    _draggingCount.current--;
    if (_draggingCount.current === 0) {
      setIsDragging(false);
    }
  }, []);

  const onDragOver = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const onDrop = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      _draggingCount.current = 0;
      setIsDragging(false);

      const images = [];
      for (const file of e.dataTransfer.files) {
        if (file.type?.startsWith("image/")) {
          images.push(file);
        }
      }

      if (files.length + images.length > CHAT_FILES_LIMIT_CNT) {
        cogoToast.warn(
          <span>
            You can upload up to <strong>{CHAT_FILES_LIMIT_CNT} images</strong>{" "}
            at a time.
          </span>
        );
        return;
      }

      setFiles([...files, ...images]);
    },
    [files]
  );

  useEffect(() => {
    let timerId = setInterval(() => {
      let secsRemaining = 0;
      if (auth_user?.profile?.ban_exp_at) {
        const now = moment();
        const banExpAt = moment(auth_user?.profile?.ban_exp_at);
        if (banExpAt.isValid() && banExpAt.isAfter(now)) {
          secsRemaining = parseInt(
            moment.duration(banExpAt.diff(now)).asSeconds()
          );
        }
      }
      setSlowModeTimeRemaining(secsRemaining);
      if (!secsRemaining) {
        clearInterval(timerId);
        timerId = null;
      }
    }, 1000);

    return () => timerId && clearTimeout(timerId);
  }, [auth_user?.profile?.ban_exp_at]);

  const ChannelBar = useMemo(() => {
    return (channels || []).map(({ id, label }, index) => {
      return (
        <div
          key={id}
          itemID={id}
          className={`chat-channel-item mr-1 ${channelSelected == id ? "active" : ""}`}
          onClick={() => onClickChannel(id)}
        >
          {label}
        </div>
      );
    });
  }, [channels, channelSelected]);

  const imageViewerSlides = useMemo(() => {
    if (!Array.isArray(imageViewerFiles)) {
      return [];
    } else {
      return imageViewerFiles.map((item) => ({
        src: item,
      }));
    }
  }, [imageViewerFiles]);

  return (
    <div className={`card w-100 ${maximisedView ? "h-auto" : "h-100"}`}>
      <div
        className={`chat-container ${styles["chat-container"]}`}
        onDragEnter={(e) => onDragEnter(e)}
        onDragLeave={(e) => onDragLeave(e)}
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => onDrop(e)}
      >
        {isDragging && (
          <div className={styles["chat-drop-zone"]}>
            <div>
              <img src={imgDropZone} />
              <span>Upload images here</span>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between mb-1">
          <h4 style={{ marginBottom: "0px" }}>Chat</h4>
          <div className="d-flex flex-wrap justify-content-end align-items-center mt-n2">
            <SearchInput
              variation="chat"
              value={searchValue}
              onChange={onChangeSearch}
              onFocus={(e) => onFocusSearch(e, true)}
              onBlur={(e) => onFocusSearch(e, false)}
              onClear={onClearSearch}
              className={`ml-2`}
              placeholder="@ or Symbol"
            />
          </div>
        </div>
        {Array.isArray(channels) && channels.length > 0 && (
          <div className="d-flex align-items-center mt-1 mb-2">
            <div
              className="flex-shrink-1 flex-shrink-1 mx-0"
              style={{ overflow: "hidden" }}
            >
              <ScrollMenu
                apiRef={_scrollbarRef}
                LeftArrow={LeftArrow}
                RightArrow={RightArrow}
                // selected={filterValue}
                // onWheel={onWheel}
              >
                {ChannelBar}
              </ScrollMenu>
            </div>
          </div>
        )}
        <MessageListPanel
          disableActions={slowModeTimeRemaining > 0}
          onReachEnd={onListReachEnd}
          onOpenImageViewer={({ files, index }) => {
            setImageViewerOpen(true);
            setImageViewerFiles(files);
            setImageViewerIndex(index);
          }}
        />
        <MessageInputPanel
          slowModeTimeRemaining={slowModeTimeRemaining}
          files={files}
          onFileRemove={(file) => {
            setFiles(files.filter((item) => item !== file));
          }}
          onFileRemoveAll={() => {
            setFiles([]);
          }}
        />
      </div>
      <Lightbox
        plugins={[Counter, Download, Fullscreen, Zoom]}
        animation={{ zoom: 300 }}
        zoom={{
          maxZoomPixelRatio: 10,
          zoomInMultiplier: 1.8,
          doubleTapDelay: 500,
          doubleClickDelay: 500,
          doubleClickMaxStops: 2,
          keyboardMoveDistance: 2,
          wheelZoomDistanceFactor: 2,
          pinchZoomDistanceFactor: 2,
          scrollToZoom: false,
        }}
        index={imageViewerIndex}
        carousel={{
          finite: imageViewerSlides?.length <= 1,
          preload: 2,
          padding: "16px",
          spacing: "30%",
          imageFit: "contain",
        }}
        render={{
          ...(imageViewerSlides?.length > 1
            ? null
            : {
                buttonPrev: () => null,
                buttonNext: () => null,
              }),
        }}
        open={imageViewerOpen}
        close={() => setImageViewerOpen(false)}
        slides={imageViewerSlides}
      />
    </div>
  );
}

const mapDispatchToProps = {
  updateSearchDropdown: DashboardActions.updateSearchDropdown,
  updateSearchDropdownClicked: DashboardActions.updateSearchDropdownClicked,
};

const mapStateToProps = (state, props) => ({
  maximisedView: state.dashboard.maximisedView,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

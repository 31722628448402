import { createActions, createReducer } from "reduxsauce";
import { SortDirection } from "react-virtualized";

const defaultState = {
  data: [],
  tableSort: {
    sortBy: "username",
    sortDirection: SortDirection.ASC,
  },
};

export const { Types, Creators } = createActions({
  updateTableSort: ["value"],
  fetchUsers: [],
  fetchUsersSucceed: ["value"],
  updateUserSettingSucceed: ["value"],
});

const updateTableSort = (state, { value }) => {
  return {
    ...state,
    tableSort: {
      ...value,
    },
  };
};

const fetchUsersSucceed = (state, { value }) => {
  return {
    ...state,
    data: value.map((item) => {
      item.active = ["active", "past_due"].includes(item.subscription)
        ? "Yes"
        : "-";
      delete item.subscription;
      return item;
    }),
  };
};

const updateUserSettingSucceed = (state, { value }) => {
  const { user_id, ...props } = value;
  return {
    ...state,
    data: state.data.map((item) => {
      if (item.id !== user_id) {
        return item;
      }
      return {
        ...item,
        ...props,
      };
    }),
  };
};

export const AdminTypes = Types;

export const adminReducer = createReducer(defaultState, {
  [Types.UPDATE_TABLE_SORT]: updateTableSort,
  [Types.FETCH_USERS_SUCCEED]: fetchUsersSucceed,
  [Types.UPDATE_USER_SETTING_SUCCEED]: updateUserSettingSucceed,
});

export default Creators;

import React from "react";
import { Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import { connect } from "react-redux";

import LayoutButton from "./LayoutButton";

import { DashboardActions } from "../store";

import { useMediaQueryDetector } from "../util";
import {
  isPro,
  isProNew,
  isProPlusNew,
  isActiveSubscription,
  DEFAULT_DASHBOARD_LAYOUT_CONFIG,
} from "../constants";
import AudioButton from "./AudioButton";

function Navbar(props) {
  const {
    onLogout,
    isPro,
    isProPlus,
    layout,
    resetLayout,
    setLayoutActive,
    toggleLayoutLock,
    triggerLayoutForceUpdate,
  } = props;
  // const { optionsMode, updateOptionsMode} = props;

  const [isSmallDevice] = useMediaQueryDetector("(max-width: 768px)");

  // const [ setting_sub1_show, setSetting_sub1_show ] = useState(false);
  // const [ setting_options_sub2_show, setSetting_options_sub2_show ] = useState(false);

  // const toggleOffcanvas = () => {
  //   document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  // }
  // const toggleRightSidebar = () => {
  //   document.querySelector('.right-sidebar').classList.toggle('open');
  // }

  // const getPlanType = (type) => {
  //   switch (type) {
  //     case 'standard_monthly':
  //       return 'Standers';
  //     case 'pro_monthly':
  //       return 'Monthly';
  //     case 'pro_semi_annual':
  //       return 'Annual';
  //     case 'price_1I1ACxAlsOvzObxuaGBSOkd8':
  //       return 'Annual-New';

  //     case 'plan_IIwGl8jhyA9Bqd':
  //       return 'Standers';
  //     case 'plan_IIwFu1E5o53e3B':
  //       return 'Monthly';
  //     case 'plan_IIwFXxMwy3rXnW':
  //       return 'Annual';
  //     case 'price_1I0X14AlsOvzObxugSbKwlp2':
  //       return 'Annual-New';
  //   }
  //   return 'None';
  // };

  const layoutInfo = layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG;

  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <div
          className="d-flex flex-row"
          style={{
            flex: 1,
            position: "absolute",
            width: "fit-content",
            ...(isSmallDevice
              ? { position: "unset", alignItems: "center" }
              : {}),
          }}
        >
          <div
            className="logo"
            style={{
              cursor: "pointer",
              ...(isSmallDevice ? { height: "fit-content" } : {}),
            }}
            onClick={() => props.history.push("/dashboard")}
          >
            <span
              className="brand-logo"
              style={{
                marginRight: 4,
                ...(isSmallDevice
                  ? { fontSize: "27px", height: "fit-content" }
                  : {}),
              }}
            >
              MOMO
            </span>
            {(isPro || isProPlus) && (
              <div
                style={{
                  marginTop: 6,
                  ...(isSmallDevice
                    ? { display: "flex", flexDirection: "column" }
                    : {}),
                }}
              >
                <span
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    fontSize: "13px",
                    color: "#000000",
                    fontWeight: "600",
                    background: "#ffff",
                    whiteSpace: "nowrap",
                    ...(isSmallDevice ? { fontSize: "9px" } : {}),
                  }}
                >
                  {isPro && "PRO"}
                  {isProPlus && "PRO +"}
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <AudioButton />
          {isSmallDevice && (
            <LayoutButton
              layout={layoutInfo}
              setLayoutActive={setLayoutActive}
              resetLayout={resetLayout}
              toggleLayoutLock={toggleLayoutLock}
              triggerLayoutForceUpdate={triggerLayoutForceUpdate}
            />
          )}
          <ul className="navbar-nav navbar-nav-right">
            <Dropdown
              alignRight
              as="li"
              className="nav-item"
              style={{ zIndex: 100 }}
              // onToggle={(isOpen, event, metaData) => {
              //   this.setState({
              //     setting_sub1_show: false,
              //     setting_options_sub2_show: false
              //   })
              // }}
            >
              <Dropdown.Toggle
                as="a"
                className="nav-link cursor-pointer no-caret"
              >
                <i className="mdi mdi-menu" style={{ fontSize: "1.4rem" }} />
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <Dropdown.Divider />
                <Dropdown.Item
                  // onClick={(evt) => {
                  //   this.props.history.push('/dashboard');
                  // }}
                  href="/dashboard"
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-gauge text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">Dashboard</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Divider />
                <Dropdown.Item
                  // onClick={(evt) => {
                  //   this.props.history.push('/settings');
                  // }}
                  href="/settings"
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>Settings</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={(evt) => {
                    this.props.history.push('/settings');
                  }}
                  className='preview-item settings-item'
                >
                  <div>
                    <div className="menu-toggler" onClick={() => {
                      if (setting_sub1_show) {
                        this.setState({
                          setting_sub1_show: false,
                          setting_options_sub2_show: false
                        })
                      } else {
                        this.setState({
                          setting_sub1_show: true
                        })
                      }
                    }}>
                      <div className='preview-thumbnail'>
                        <div className='preview-icon bg-dark rounded-circle'>
                          <i className='mdi mdi-settings text-success'></i>
                        </div>
                      </div>
                      <div className='preview-item-content'>
                        <p className='preview-subject mb-1'>
                          <Trans>Settings</Trans>
                        </p>
                      </div>
                    </div>
                    <ul className={!setting_sub1_show ? "menu-sub" : "menu-sub show"}>
                      <li>
                        <span onClick={() => {
                          this.props.history.push('/settings');
                          document.body.click();
                        }}>Main</span>
                      </li>
                      <li>
                        <span onClick={() => {
                          this.setState({
                            setting_options_sub2_show: !setting_options_sub2_show
                          })
                        }}>Options</span>
                        <ul className={!setting_options_sub2_show ? "menu-sub-sub" : "menu-sub-sub show"}>
                          {
                            ["Off", "Icon", "Filter"].map((item, index) => {
                              return (
                                <li className={optionsMode === item ? "active" : ""} key={index} onClick={() => {
                                  updateOptionsMode(item)
                                  document.body.click();
                                }}>
                                  <span>{item}</span>
                                </li>)
                            })
                          }
                        </ul>
                      </li>
                    </ul>
                  </div>
                </Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item
                  // onClick={(evt) => {
                  //   evt.preventDefault();
                  //   this.props.history.push('/profile');
                  // }}
                  href="/profile"
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-account-outline text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>Account</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  // onClick={() => {
                  //   window.open(
                  //     'https://doc.mometic.com/',
                  //     '_blank'
                  //   );
                  // }}
                  href="https://doc.mometic.com/"
                  target="_blank"
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-help text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>Help</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  href="!#"
                  onClick={(evt) => {
                    evt.preventDefault();
                    onLogout && onLogout();
                  }}
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>Log Out</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </div>
    </nav>
  );
}

const mapDispatchToProps = {
  resetLayout: DashboardActions.resetLayout,
  setLayoutActive: DashboardActions.setLayoutActive,
  toggleLayoutLock: DashboardActions.toggleLayoutLock,
  triggerLayoutForceUpdate: DashboardActions.triggerLayoutForceUpdate,
};

export default withRouter(
  connect((state) => {
    return {
      layout: state.dashboard.layout,
      isPro:
        isActiveSubscription(state.auth.user.subscription) &&
        (isPro(state.auth.user.subscription.plan) ||
          isProNew(state.auth.user.subscription.plan)),
      isProPlus:
        isActiveSubscription(state.auth.user.subscription) &&
        isProPlusNew(state.auth.user.subscription.plan),
    };
  }, mapDispatchToProps)(Navbar)
);

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cogoToast from "cogo-toast";

import API from "../../../api";

import { AuthTypes } from "../../../store/auth";

import styles from "./index.module.scss";

const defaultAvatar = require("../../../../assets/images/chat/no-image-user.png");
const badgeVIP = require("../../../../assets/images/chat/crownvip.png");
const badgeModAdmin = require("../../../../assets/images/chat/mod-admin.png");
const badge1yrService = require("../../../../assets/images/chat/diamond1yr.png");

const USERNAME_MAX_LENGTH = 12;

function Profile() {
  const dispatch = useDispatch();

  const auth_user = useSelector((state) => state.auth.user);

  const _refImageInput = useRef(null);
  const [username, setUsername] = useState("");
  const [image, setImage] = useState(null);
  const [chatNotification, setChatNotification] = useState(false);
  const [profile, setProfile] = useState();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setUsername(auth_user.username);
    setImage(auth_user?.profile?.image);
    setChatNotification(
      auth_user?.profile?.chat_notification ||
        auth_user?.profile?.chat_notification === null
    );
  }, [auth_user, setUsername, setImage, setChatNotification]);

  useEffect(() => {
    loadUserProfile();
  }, []);

  const loadUserProfile = async () => {
    try {
      const response = await API.getUserProfile();
      setProfile(response.profile);
    } catch (e) {
      console.log(e);
      cogoToast.error("Failed to fetch profile!");
    }
  };

  const onClickSelectImage = () => {
    _refImageInput.current && _refImageInput.current.click();
  };

  const onChangeUserImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onClickSave = async () => {
    try {
      const trimmedUsername = username.trim();
      if (!trimmedUsername) {
        cogoToast.error("Username is required!");
        return;
      }
      if (trimmedUsername.length > USERNAME_MAX_LENGTH) {
        cogoToast.error(
          `Username can be max ${USERNAME_MAX_LENGTH} characters!`
        );
        return;
      }

      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(trimmedUsername)) {
        cogoToast.error(`Username can be only alphanumeric characters!`);
        return;
      }

      const formData = new FormData();
      formData.append("username", trimmedUsername);
      formData.append("notification", chatNotification);
      if (_refImageInput.current.files.length) {
        formData.append("image", _refImageInput.current.files[0]);
      }

      cogoToast.loading("Please wait for a moment!");
      setSaving(true);

      const res = await API.updateProfile(formData);
      dispatch({
        type: AuthTypes.SET_USER_PROFILE,
        value: res.profile,
      });

      cogoToast.success("Profile updated successfully!");
    } catch (e) {
      console.log(e);
      if (e === "LIMIT_FILE_SIZE") {
        cogoToast.error(
          "Failed to update profile! Image file size is too large."
        );
      } else if (e === "FILE_UPLOAD_FAIL") {
        cogoToast.error("Failed to upload profile image!");
      } else {
        cogoToast.error("Failed to update profile!");
      }
    } finally {
      setSaving(false);
    }
  };

  const renderRole = () => {
    if (!profile) {
      return <span>-</span>;
    }
    let roleLabel = "Trader";
    let isModAdmin;
    if (profile.role === "admin") {
      roleLabel = "Admin";
      isModAdmin = true;
    } else if (profile.role === "mod") {
      roleLabel = "Moderator";
      isModAdmin = true;
    }
    return (
      <>
        <span className={""}>{roleLabel}</span>
        {isModAdmin && (
          <img className={styles["account-badge"]} src={badgeModAdmin} />
        )}
      </>
    );
  };

  const renderStatus = () => {
    if (!profile) {
      return <span>-</span>;
    }
    return (
      <>
        {profile.chat ? (
          <span className="text-success">Active</span>
        ) : (
          <span className="text-danger">Inactive</span>
        )}
        {profile.service1yr && (
          <img className={styles["account-badge"]} src={badge1yrService} />
        )}
        {profile.vip && (
          <img className={styles["account-badge"]} src={badgeVIP} />
        )}
      </>
    );
  };

  return (
    <section>
      <h2 className="mb-4">Profile</h2>
      <div className="row px-0 px-sm-5 mx-0 mx-sm-5">
        <div className="col-12 col-sm-6">
          <div>
            <label className="font-weight-bold mr-2">Role:</label>
            {renderRole()}
          </div>
          <div>
            <label className="font-weight-bold mr-2">Status:</label>
            {renderStatus()}
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <h4 className="mb-2 mb-sm-3 mt-3 mt-sm-0">Monthly Stats</h4>
          <div>
            <label className="font-weight-bold mr-2">Posts:</label>
            <span className={styles["monthly-states-value"]}>
              {profile?.cur_posts || "-"}
            </span>
          </div>
          <div>
            <label className="font-weight-bold mr-2">Vibe:</label>
            <span className={styles["monthly-states-value"]}>
              {profile?.vibe || "-"}
            </span>
          </div>
        </div>
      </div>
      <hr className={`${styles["separater"]} my-4`} />
      <div className={`form-group ${styles["update-form-group"]}`}>
        <label>Username:</label>
        <input
          type="text"
          className="form-control"
          aria-describedby="username"
          placeholder="Enter username"
          maxLength={USERNAME_MAX_LENGTH}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ color: "white" }}
        />
        <small className="form-text text-muted">
          Required. Max <strong>{USERNAME_MAX_LENGTH}</strong> characters.
        </small>
      </div>
      <div
        className={`form-group d-flex align-items-start ${styles["update-form-group"]}`}
      >
        <label className="mt-2 mr-5">
          Profile image:
          <small className="form-text text-muted">
            Max <strong>10MB</strong>
          </small>
        </label>
        <div className={styles["user-avatar-preview"]}>
          <img src={image ? image : defaultAvatar} />
          <div
            className={styles["file-select-wrapper"]}
            onClick={() => onClickSelectImage()}
          >
            <i className="fa fa-pencil-square-o" />
          </div>
          <input
            ref={_refImageInput}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={onChangeUserImage}
          />
        </div>
        {/* <button className="btn btn-primary btn-md mt-2 ml-3">
          Upload
        </button> */}
      </div>
      <div
        className={`form-group d-flex align-items-center ${styles["update-form-group"]}`}
      >
        <label className="mr-5">Chat notification:</label>
        <div className="form-check mt-0">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input text-success"
              checked={chatNotification ? "checked" : ""}
              onChange={(e) => setChatNotification(e.target.checked)}
            />
            <i className="input-helper"></i>
          </label>
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <button
          className="btn btn-primary btn-sm-block btn-lg font-weight-medium float-right"
          onClick={() => onClickSave()}
          disabled={saving ? "disabled" : ""}
        >
          Save
        </button>
      </div>
    </section>
  );
}

export default Profile;

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import LayoutButton from "../LayoutButton";
import { LeftArrow, RightArrow } from "../../news/Arrows";

import { DashboardActions } from "../../store";
import { getActiveLayout } from "../../dashboard/dashboardReducer";

import { useMediaQueryDetector } from "../../util";

import {
  isPro,
  isProNew,
  isProPlusNew,
  isActiveSubscription,
  DEFAULT_DASHBOARD_LAYOUT_CONFIG,
} from "../../constants";

import "./MainMenu.scss";
import { Menu } from "react-contexify";

const imgChatActive = require("../../../assets/images/dashboard/chat-active.png");
// const imgChatInactive = require('../../../assets/images/dashboard/chat-inactive.png');

const menuList = [
  "flow",
  "stream1",
  "stream2",
  "alerts",
  "meters",
  "popular",
  "chat",
  "news",
  "quotes",
  "discovery1",
  "discovery2",
  "chart",
];

const MainMenu = (props) => {
  const {
    isPro,
    isProPlus,
    history,
    layout,
    setLayoutActive,
    toggleLayoutLock,
    toggleLayoutWidget,
    triggerLayoutForceUpdate,
    resetLayout,
  } = props;

  const layoutInfo = layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG;
  const { menu, locked: layoutLocked } = getActiveLayout(
    layout,
    setLayoutActive
  );

  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
  const [isExtraSmallDevice] = useMediaQueryDetector("(max-width: 575px)");
  const [isSmallDevice] = useMediaQueryDetector(
    "(min-width: 576px) and (max-width: 767px)"
  );
  const [isMediumDevice] = useMediaQueryDetector(
    "(min-width: 768px) and (max-width: 1023px)"
  );
  const [isLargeDevice] = useMediaQueryDetector("(min-width: 1024px)");

  const renderUpgradeModal = () => {
    return (
      <Modal
        show={upgradeModalVisible}
        onHide={() => {
          setUpgradeModalVisible(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="h3">Confirm</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-left">
            Only available with Pro plan - Upgrade?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUpgradeModalVisible(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              history.push("/profile/plan");
            }}
          >
            &nbsp;OK&nbsp;
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const proItems = ["discovery1", "discovery2", "chart"];
  const menuItems = {
    flow: {
      text: "FLOW",
      icon: "mdi-waves text-white",
    },
    stream1: {
      text: "STREAM 1",
      icon: "mdi-speedometer text-primary",
    },
    stream2: {
      text: "STREAM 2",
      icon: "mdi-speedometer text-primary",
    },
    alerts: {
      text: "ALERTS",
      icon: "mdi-file-restore text-success",
    },
    meters: {
      text: "METERS",
      icon: "mdi-crosshairs-gps text-warning",
    },
    popular: {
      text: "POPULAR",
      icon: "mdi-clipboard-text text-danger",
    },
    news: {
      text: "NEWS",
      icon: "mdi-wifi text-warning",
    },
    quotes: {
      text: "QUOTE",
      icon: "mdi-chart-bar text-primary",
    },
    discovery1: {
      text: "DISCOVERY 1",
      icon: "mdi-content-copy text-success",
    },
    discovery2: {
      text: "DISCOVERY 2",
      icon: "mdi-content-copy text-success",
    },
    chart: {
      text: "CHART",
      icon: "mdi-chart-line chart-menu-icon-color",
    },
  };

  const renderMenuPinBtn = (widget) => {
    return (
      <span
        className="btn-menu-pin"
        onClick={() => {
          !layoutLocked && toggleLayoutWidget(widget);
        }}
      >
        <i className="mdi mdi-plus" />
      </span>
    );
  };

  const onClickMenuItem = (e, widget) => {
    if (!menu[widget]) {
      e.preventDefault();
      e.stopPropagation();
      toggleLayoutWidget(widget);
      return;
    }
    if (proItems.includes(widget)) {
      if (isPro || isProPlus) {
        !layoutLocked && toggleLayoutWidget(widget);
      } else {
        setUpgradeModalVisible(true);
      }
    } else {
      !layoutLocked && toggleLayoutWidget(widget);
    }
  };

  const renderMenuItem = (widget) => {
    if (Object.keys(menuItems).includes(widget)) {
      return (
        <MenuItem
          key={widget}
          text={menuItems[widget].text}
          icon={menuItems[widget].icon}
          onClickMenuItem={onClickMenuItem}
          widget={widget}
          layoutLocked={layoutLocked}
          showWidget={proItems.includes(widget) ? isPro || isProPlus : true}
        />
      );
    } else if (widget === "chat") {
      return (
        <div
          className={`widget-btn d-flex justify-content-between flex-row align-items-center ml-1 ml-sm-2 mr-2 mr-sm-3 my-2 showWidget
            ${layoutLocked ? "widget-btn-disabled" : "widget-btn-enabled"}`}
          onClick={(e) => onClickMenuItem(e, widget)}
        >
          <div className="d-flex align-items-center">
            <span className="bar-icon">
              <img src={imgChatActive} style={{ width: 16 }} />
            </span>
            <span className="small white-no-wrap bar-txt">CHAT</span>
          </div>
          {/* {
            !menu[widget] && renderMenuPinBtn(widget)
          } */}
        </div>
      );
    }
    return null;
  };

  const renderActiveMenuItems = () => {
    return activeMenuItems.map((item) => (
      <div key={item}>{renderMenuItem(item)}</div>
    ));
  };

  const activeMenuItems = menuList.filter((item) => menu[item]);
  const inactiveMenuItems = menuList.filter((item) => !menu[item]);

  let scrollThreshold;
  let scrollContainerMaxWidth;
  if (isLargeDevice) {
    scrollThreshold = 7;
    scrollContainerMaxWidth = 800;
  } else if (isMediumDevice) {
    scrollThreshold = 5;
    scrollContainerMaxWidth = 500;
  } else if (isSmallDevice) {
    scrollThreshold = 4;
    scrollContainerMaxWidth = 420;
  } else {
    scrollThreshold = 4;
    scrollContainerMaxWidth = 250;
  }

  return (
    <>
      <div className="menu-container d-flex justify-content-between align-items-center static-bar">
        <div className="d-flex align-items-center mt-1 mb-2 ml-1">
          {activeMenuItems.length > scrollThreshold ? (
            <div
              className="flex-shrink-1 mx-0"
              style={{ overflow: "hidden", maxWidth: scrollContainerMaxWidth }}
            >
              <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {renderActiveMenuItems()}
              </ScrollMenu>
            </div>
          ) : (
            renderActiveMenuItems()
          )}
          <Dropdown varaint="btn btn-outline-secondary" key="btn-menu-more">
            <Dropdown.Toggle className="industry_input btn-menu-more">
              <div
                className={`widget-btn d-flex flex-row align-items-center ml-1 ml-sm-2 mr-2 mr-sm-3 my-2 showWidget widget-btn-enabled`}
              >
                <span className="bar-icon">
                  <i
                    className="fa fa-plus mr-0"
                    style={{ marginTop: 2, color: "#cbc11f" }}
                  />
                </span>
                <span
                  className="small white-no-wrap bar-txt d-none d-sm-block"
                  style={{ display: "flex" }}
                >
                  MORE
                </span>
              </div>
            </Dropdown.Toggle>
            {inactiveMenuItems.length > 0 && (
              <Dropdown.Menu className="btn-menu-more-content">
                {inactiveMenuItems.map((item) => (
                  <Dropdown.Item key={item} onClick={() => {}}>
                    {renderMenuItem(item)}
                  </Dropdown.Item>
                ))}
                {!isExtraSmallDevice &&
                  !!inactiveMenuItems.length &&
                  inactiveMenuItems.length % 2 > 0 && (
                    <Dropdown.Item disabled>
                      <div className={`widget-btn widget-btn-gap-filler`}>
                        <span>GAP FILLER</span>
                      </div>
                    </Dropdown.Item>
                  )}
                {layoutLocked && (
                  <div className="lock-overlay">
                    <p>UNLOCK SELECTED LAYOUT TO ADD ADDITIONAL COMPONENTS</p>
                  </div>
                )}
              </Dropdown.Menu>
            )}
            {!inactiveMenuItems.length && (
              <Dropdown.Menu>
                <Dropdown.Item>
                  <span>NO MORE ITEMS</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
        {!isSmallDevice && !isExtraSmallDevice && (
          <LayoutButton
            layout={layoutInfo}
            setLayoutActive={setLayoutActive}
            resetLayout={resetLayout}
            toggleLayoutLock={toggleLayoutLock}
            triggerLayoutForceUpdate={triggerLayoutForceUpdate}
          />
        )}
      </div>
      {renderUpgradeModal()}
    </>
  );
};

function MenuItem({
  text,
  icon,
  onClickMenuItem,
  widget,
  layoutLocked,
  showWidget,
}) {
  return (
    <div
      className={`widget-btn d-flex justify-content-between flex-row align-items-center ml-1 ml-sm-2 mr-2 mr-sm-3 my-2 ${
        showWidget ? "showWidget" : "hideWidget"
      } ${layoutLocked ? "widget-btn-disabled" : "widget-btn-enabled"}`}
      onClick={(e) => onClickMenuItem(e, widget)}
    >
      <div className="d-flex align-items-center">
        <span className="bar-icon">
          <i className={"mdi " + icon} />
        </span>
        <span className="small white-no-wrap bar-txt">{text}</span>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  resetLayout: DashboardActions.resetLayout,
  setLayoutActive: DashboardActions.setLayoutActive,
  toggleLayoutLock: DashboardActions.toggleLayoutLock,
  toggleLayoutWidget: DashboardActions.toggleLayoutWidget,
  triggerLayoutForceUpdate: DashboardActions.triggerLayoutForceUpdate,
};

const mapStateToProps = (state) => ({
  layout: state.dashboard.layout,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) ||
      isProNew(state.auth.user.subscription.plan)),
  isProPlus:
    isActiveSubscription(state.auth.user.subscription) &&
    isProPlusNew(state.auth.user.subscription.plan),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMenu)
);

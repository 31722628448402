import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setAuthenticated: ["authenticated"],
  setLoading: ["loading"],
  setUser: ["user"],
  setUserProfile: ["value"],
  setUserChatBan: ["value"],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
const defaultState = {
  authenticated: false,
  loading: false,
  user: {},
};

/* ------------- Reducers ------------- */
const setAuthenticated = (state, { authenticated }) => ({
  ...state,
  authenticated,
});

const setLoading = (state, { loading }) => ({
  ...state,
  loading,
});

const setUser = (state, { user }) => {
  return {
    ...state,
    user,
  };
};

const setUserProfile = (state, { value }) => {
  const { username, image, chat_notification } = value;
  return {
    ...state,
    user: {
      ...(state?.user || {}),
      username,
      profile: {
        ...(state?.user?.profile || {}),
        image: image || state?.user?.profile?.image,
        chat_notification,
      },
    },
  };
};

const setUserChatBan = (state, { value }) => {
  return {
    ...state,
    user: {
      ...(state?.user || {}),
      profile: {
        ...(state?.user?.profile || {}),
        ban_exp_at: value,
      },
    },
  };
};

/* ------------- Hookup Reducers To Types ------------- */
export const authReducer = createReducer(defaultState, {
  [Types.SET_AUTHENTICATED]: setAuthenticated,
  [Types.SET_LOADING]: setLoading,
  [Types.SET_USER]: setUser,
  [Types.SET_USER_PROFILE]: setUserProfile,
  [Types.SET_USER_CHAT_BAN]: setUserChatBan,
});

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BtnClose = styled.a`
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  border: 1px solid white;
  padding: 0 5px 0 8px;
  border-radius: 13px;
  font-size: 14px;
  user-select: none;
  white-space: nowrap;

  &:hover {
    background-color: #505050;
  }
`;

function TagButton({ label, hasClose, onClose }) {
  return (
    <ButtonWrapper className="mr-2 my-1">
      {label}
      {hasClose && (
        <>
          &nbsp;
          <BtnClose
            className="mdi mdi-close"
            onClick={() => {
              onClose && onClose();
            }}
          ></BtnClose>
        </>
      )}
    </ButtonWrapper>
  );
}

TagButton.propTypes = {
  label: PropTypes.string.isRequired,
  hasClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TagButton;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./style.module.scss";
import { AudioPlayerActions } from "../../store";
import Pop from "./popup";

function AudioButton() {
  const dispatch = useDispatch();
  const muted = useSelector((state) => state.audioplayer.masterMuted);
  const [showPanel, setShowPanel] = React.useState(false);

  return (
    <div className={"d-flex " + style.root}>
      <div
        className={["mdi", "mdi-volume-" + (muted ? "off" : "high")].join(" ")}
        style={{ fontSize: "22px", color: muted ? "#e62c2c" : "inherit" }}
        onClick={() => {
          dispatch(AudioPlayerActions.setMasterMuted(!muted));
        }}
      ></div>
      <div
        className={"mdi mdi-chevron-down " + style.dropdown}
        style={{ fontSize: "20px", ...(showPanel ? { opacity: 1 } : {}) }}
        tabIndex={0}
        onClick={() => setShowPanel(!showPanel)}
        onMouseLeave={() => setShowPanel(false)}
      >
        {showPanel && <Pop />}
      </div>
    </div>
  );
}

export default AudioButton;

const MessageTypes = {
  Message: "m",
  Vote: "v",
  Typing: "t",
  Ban: "b",
};

class ChatService {
  datasource = null;

  init(DataSource) {
    this.datasource = DataSource;
  }

  subscribe() {
    this.datasource?.subscribeChat();
  }

  unsubscribe() {
    this.datasource?.unsubscribeChat();
  }

  sendMessage({ channel, msg, files, replyMsg }) {
    this.datasource?.sendChat({
      t: MessageTypes.Message,
      c: channel,
      m: msg,
      f: files,
      r: replyMsg,
    });
  }

  vote(chatId, value) {
    this.datasource?.sendChat({
      t: MessageTypes.Vote,
      vc: chatId,
      v: value,
    });
  }

  notifyTypingIndicator() {
    this.datasource?.sendChat({
      t: MessageTypes.Typing,
    });
  }
}

export default new ChatService();

export { MessageTypes };

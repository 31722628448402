import _ from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import { withTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import RGL, { WidthProvider } from "react-grid-layout";
import Popout from "react-popout";
import {
  deviceDetect,
  isMobile,
  isChrome,
  isChromium,
  isEdge,
  isFirefox,
  isSafari,
  isMobileSafari,
  isEdgeChromium,
  isWindows,
  isMacOs,
} from "react-device-detect";
const moment = require("moment-timezone");

import API from "../api";

import {
  AuthActions,
  DashboardActions,
  OptionsActions,
  ConfigActions,
  DiscoveryActions,
  StockNamesActions,
  NewsActions,
  MiscActions,
  ChartActions,
  StreamActions,
} from "../store";
import { store } from "../store/createStore";
import {
  getActiveLayout,
  getLayoutForMediaQuery,
  hasMultipleStreamWidget,
  isWidgetPopout,
  isWidgetVisible,
} from "./dashboardReducer";
import { showAllTableFilter } from "../discovery/discoveryReducer";

import { withDataSource } from "../../contexts/datasource/hoc/withSocket";

import MainMenu from "../shared/MainMenu/MainMenu";
import Meters from "../meters/Meters";
import Popular from "../popular/Popular";
import Alerts from "../alerts/Alerts";
import Discovery from "../discovery/Discovery";
import Quote from "../quotes/Quote";
import News from "../news/News";
import Chat from "../chat";
import Flow from "../flow/Flow";
import HaltBar from "./HaltBar";
import Chart from "../chart";

import Symbol from "../shared/Symbol";
import ColorBar from "../shared/ColorBar";
import LULD from "../shared/LULD";
import { round } from "../shared/helper";
import { VoiceAlertHandler } from "../../services/VoiceAlertHandler";
import {
  addGTMAnalyticsScripts,
  checkModified,
  getMarketStartEndTime,
  isRegularMarketOpen,
  isWebAppStandalone,
} from "../util";
import {
  AVG_VOL_MAX,
  PRICE_MAX,
  FLOAT_MAX,
  SECTORS_FILTER,
  DEFAULT_STREAM_SETTING,
  DEFAULT_NEWS_CONFIG,
  isPro,
  isProNew,
  isProPlusNew,
  isActiveSubscription,
  TEST_SYMBOLS,
  COUNT_MAX,
  DEFAULT_OPTIONS_MODE,
  STREAM_CHANNEL_TYPE,
  SHOW_ALL_DISCOVERY_FILTER,
  ATR_MAX,
  LAYOUT_BREAKPOINT_MEDIA_QUERIES,
  WINDOWS_CHROME_Y,
  WINDOWS_CHROME_X,
  WINDOWS_CHROME_PWA_X,
  WINDOWS_CHROME_PWA_Y,
  MAC_CHROME_PWA_X,
  MAC_CHROME_PWA_Y,
  WINDOWS_EDGE_PWA_X,
  WINDOWS_EDGE_PWA_Y,
  WINDOWS_FIREFOX_Y,
  WINDOWS_FIREFOX_X,
  WINDOWS_EDGE_X,
  WINDOWS_EDGE_Y,
  MAC_CHROME_X,
  MAC_CHROME_Y,
  MAC_SAFARI_X,
  MAC_SAFARI_Y,
  MAC_FIREFOX_X,
  MAC_FIREFOX_Y,
  GAP_MAX,
  STREAM_CHANNEL_MODE,
  TIMEFRAME_TRANSFORM_MAP,
  MARKET_CAP_MAX,
  DEFAULT_DASHBOARD_LAYOUT_CONFIG,
} from "../constants";

const ReactGridLayout = WidthProvider(RGL);

import "swiper/css/swiper.css";
import "./dashboard.css";
import StreamSettingPopover from "../stream/StreamSettingPopover";

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    const { widget } = this.props.match.params;
    this.popoutWidget = widget;
    if (widget) {
      this.props.setIsPagePopout(true);
    }

    this.state = this.getInitialState();

    this.onPrimarySocketConnected = this.onPrimarySocketConnected.bind(this);
    this.onRealtimeSocketConnected = this.onRealtimeSocketConnected.bind(this);
    this.onCompressedUpdate = this.onCompressedUpdate.bind(this);
    this.onFilteredAlerts = this.onFilteredAlerts.bind(this);
    this.onSymbolClick = this.onSymbolClick.bind(this);
  }

  buffer = {};
  haltsBuffer = [];

  voiceAlerter = new VoiceAlertHandler();

  storeSubscriptions = [];
  prevLayoutConfig = null;
  streamSubscriptionStatus = null;
  discoverySubscriptionStatus = null;
  quotesSubscriptionStatus = null;
  layoutPendingSaveTimeoutId = null;

  popoutWidget = null;
  popoutScreenInfo = {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  };

  stocknameFetchInterval = null;

  componentDidMount() {
    // window.addEventListener("resize", this.updateDimensions);
    // this.updateDimensions();

    this.requestWindowManagementPermission();

    // Small device change handling
    const mediaQuerySmallDeviceChange = window.matchMedia("(max-width: 767px)");
    if (mediaQuerySmallDeviceChange?.addEventListener) {
      mediaQuerySmallDeviceChange.addEventListener(
        "change",
        this.smallDeviceChangeHandler
      );
    } else {
      mediaQuerySmallDeviceChange.addListener(this.smallDeviceChangeHandler);
    }

    // Window size change handling for layout arrangement
    if (!this.popoutWidget) {
      for (const query of LAYOUT_BREAKPOINT_MEDIA_QUERIES) {
        const matches = window.matchMedia(query);
        if (matches?.addEventListener) {
          matches.addEventListener("change", this.rearrangeLayout);
        } else {
          matches.addListener(this.rearrangeLayout);
        }
      }
      this.initarrangeLayout(true);
    }

    // Connect datasource
    if (this.props.datasource.primary) {
      this.onPrimaryDatasourceInit();
    }
    if (this.props.datasource.realtime) {
      this.onRealtimeDatasourceInit();
    }

    // this.requestNotificationPermissions().then(r => {});

    this.props.optionsFetch();
    this.props.stockNamesFetch();
    this.props.usernamesFetch();
    this.getHalts();

    this.stocknameFetchInterval = setInterval(() => {
      this.props.stockNamesFetch();
      this.props.usernamesFetch();
    }, 3 * 60 * 1000);

    // Get config settings
    this.getSetting();
    setTimeout(() => {
      // Give it a delay bcz setting might be still being saved
      this.getSettingConfig();
    }, 2000);

    // Screen pos, size change handler
    this.initScreenPosChangeHandler();

    // Store change handler for Dashboard layout handling
    if (!this.popoutWidget) {
      this.prevLayoutConfig = {
        layout: store.getState().dashboard.layout,
        layoutDeviceMedia: store.getState().dashboard.layoutDeviceMedia,
      };
      let prevLayoutForceUpdate = store.getState().dashboard.layoutForceUpdate;
      this.storeSubscriptions.push(
        store.subscribe(() => {
          const { auth, dashboard } = store.getState();
          if (!auth.authenticated) {
            return;
          }

          const { key: activeLayoutKey, locked: activeLayoutLocked } =
            getActiveLayout(dashboard.layout, this.props.setLayoutActive);
          const { key: prevActiveLayoutKey, locked: prevActiveLayoutLocked } =
            getActiveLayout(this.prevLayoutConfig.layout);

          let layoutConfig = {
            layout: dashboard.layout,
            layoutDeviceMedia: dashboard.layoutDeviceMedia,
          };
          if (
            !activeLayoutLocked ||
            (prevActiveLayoutKey === activeLayoutKey && !prevActiveLayoutLocked)
          ) {
            if (checkModified(layoutConfig, this.prevLayoutConfig)) {
              this.layoutConfigChanged(layoutConfig);
            }
          }
          this.prevLayoutConfig = layoutConfig;

          let shouldArrangeLayout, shouldForceLayoutUpdate;

          if (!dashboard.layoutDeviceMedia) {
            shouldArrangeLayout = true;
          }

          const layoutForceUpdate = dashboard.layoutForceUpdate;
          if (
            prevActiveLayoutKey !== activeLayoutKey ||
            prevLayoutForceUpdate !== layoutForceUpdate
          ) {
            shouldForceLayoutUpdate = true;
            if (activeLayoutKey === "dynamic") {
              shouldArrangeLayout = true;
            }
          }
          prevLayoutForceUpdate = layoutForceUpdate;

          if (shouldArrangeLayout) {
            setTimeout(() => {
              this.initarrangeLayout(true);
            }, 200);
          }
          if (shouldForceLayoutUpdate) {
            this.setState({
              layoutElemKey: this.state.layoutElemKey + 1,
            });
          }
        })
      );
    }

    // Store change handler for Stream & Discovery datasource channel subscription handling
    {
      this.streamSubscriptionStatus = {
        subscribed: false,
      };
      this.discoverySubscriptionStatus = {};
      this.quotesSubscriptionStatus = [];
      this.storeSubscriptions.push(
        store.subscribe(() => {
          const { dashboard, discovery, quote } = store.getState();
          const streamSubscriptionStatus = {
            subscribed: false,
          };
          const discoverySubscriptionStatus = {};
          let quotesSubscriptionStatus = [];
          const activeStreamWidgets = [];
          const activeDiscoveryWidgets = [];
          if (this.popoutWidget && this.isStreamWidget(this.popoutWidget)) {
            activeStreamWidgets.push(this.popoutWidget);
          }
          if (this.popoutWidget && this.isDiscoveryWidget(this.popoutWidget)) {
            activeDiscoveryWidgets.push(this.popoutWidget);
          }
          if (!this.popoutWidget) {
            const { menu, popout } = getActiveLayout(dashboard.layout);
            for (const widget in menu) {
              if (menu[widget] && this.isStreamWidget(widget)) {
                activeStreamWidgets.push(widget);
              }
              if (menu[widget] && this.isDiscoveryWidget(widget)) {
                activeDiscoveryWidgets.push(widget);
              }
            }
            for (const { widget } of popout) {
              if (
                this.isStreamWidget(widget) &&
                activeStreamWidgets.includes(widget)
              ) {
                const index = activeStreamWidgets.indexOf(widget);
                activeStreamWidgets.splice(index, 1);
              }
              if (
                this.isDiscoveryWidget(widget) &&
                activeDiscoveryWidgets.includes(widget)
              ) {
                const index = activeDiscoveryWidgets.indexOf(widget);
                activeDiscoveryWidgets.splice(index, 1);
              }
            }
            if (menu["meters"]) {
              streamSubscriptionStatus.subscribed = true;
            }
            if (menu["quotes"] && Array.isArray(quote.quotes)) {
              quotesSubscriptionStatus = quote.quotes.map(
                (item) => item.symbol
              );
            }
          }
          if (activeStreamWidgets.length > 0) {
            streamSubscriptionStatus.subscribed = true;
          }
          if (activeDiscoveryWidgets.length > 0) {
            const { discoveryTimeframe, viewportSymbols } = discovery;
            for (const widget of activeDiscoveryWidgets) {
              const timeframe =
                TIMEFRAME_TRANSFORM_MAP[discoveryTimeframe[widget]];
              if (!discoverySubscriptionStatus[timeframe]) {
                discoverySubscriptionStatus[timeframe] = [];
              }
              if (viewportSymbols && Array.isArray(viewportSymbols[widget])) {
                for (const symbol of viewportSymbols[widget]) {
                  if (
                    !discoverySubscriptionStatus[timeframe].includes(symbol)
                  ) {
                    discoverySubscriptionStatus[timeframe].push(symbol);
                  }
                }
              }
            }
          }
          if (
            checkModified(
              streamSubscriptionStatus,
              this.streamSubscriptionStatus
            )
          ) {
            this.handleStreamSubscriptionStatusChange(streamSubscriptionStatus);
          }
          if (
            checkModified(
              discoverySubscriptionStatus,
              this.discoverySubscriptionStatus
            )
          ) {
            this.handleDiscoverySubscriptionStatusChange(
              discoverySubscriptionStatus
            );
          }
          if (
            checkModified(
              quotesSubscriptionStatus,
              this.quotesSubscriptionStatus
            )
          ) {
            this.handleQuotesSubscriptionStatusChange(quotesSubscriptionStatus);
          }
        })
      );
    }

    // Add symbol click event listener
    window.addEventListener("symbolClick", this.onSymbolClick, false);
    window.opener &&
      window.opener.addEventListener("symbolClick", this.onSymbolClick, false);

    // Add GTM Analytics Scripts
    addGTMAnalyticsScripts();
  }

  componentWillUnmount() {
    this.props.datasource.primary?.off(
      "connected",
      this.onPrimarySocketConnected
    );
    this.props.datasource.primary?.off(
      "compressedUpdate",
      this.onCompressedUpdate
    );
    this.props.datasource.primary?.off("filteredAlerts", this.onFilteredAlerts);
    this.props.datasource.realtime?.off(
      "connected",
      this.onRealtimeSocketConnected
    );

    window.removeEventListener("symbolClick", this.onSymbolClick);
    window.opener &&
      window.opener.removeEventListener("symbolClick", this.onSymbolClick);

    this.flushBufferIntervalId && clearInterval(this.flushBufferIntervalId);
    this.flushBufferIntervalId = null;
    this.clearMarketStatusTimer();

    if (this.layoutPendingSaveTimeoutId) {
      this.updateLayoutConfig(this.prevLayoutConfig);
      clearTimeout(this.layoutPendingSaveTimeoutId);
    }
    this.layoutPendingSaveTimeoutId = null;

    if (this.stocknameFetchInterval) {
      clearInterval(this.stocknameFetchInterval);
    }
    this.stocknameFetchInterval = null;

    this.props.datasource.primary?.disconnect();
    this.props.datasource.realtime?.disconnect();

    this.voiceAlerter.clearLastPlayedSymbolsHandler();

    this.storeSubscriptions.forEach(
      (subscription) => subscription && subscription()
    );
    this.storeSubscriptions = [];
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.datasource.primary &&
      this.props.datasource.primary !== prevProps.datasource.primary
    ) {
      this.onPrimaryDatasourceInit();
    }
    if (
      this.props.datasource.realtime &&
      this.props.datasource.realtime !== prevProps.datasource.realtime
    ) {
      this.onRealtimeDatasourceInit();
    }
  }

  onSymbolClick(event) {
    const { symbol } = event.detail;
    this.props.addToRecent(symbol);
    this.props.updateFilter(symbol);

    this.props.updateChartWidgetSymbol(symbol);
  }

  async requestWindowManagementPermission() {
    const device = deviceDetect();

    if (device?.isMobile || device?.isTablet) {
      return;
    }

    const {
      windowPlacementPermissionRequested,
      updateWindowPlacementPermissionRequested,
    } = this.props;

    if (!windowPlacementPermissionRequested) {
      try {
        const { state } = await navigator.permissions.query({
          name: "window-placement",
        });
        let granted = state === "granted";
        if (!granted) {
          const { hide } = cogoToast.warn(
            <div>
              Enable multi-screen display permissions for best
              experience!&nbsp;&nbsp;&nbsp;
              <span
                className="link-button"
                onClick={async () => {
                  hide();

                  try {
                    updateWindowPlacementPermissionRequested(true);
                    await window.getScreenDetails();
                  } catch (error) {
                    console.log(error);
                    // setTimeout(() => {
                    //   cogoToast.error('Please allow the window management permissions in the browser settings!')
                    // }, 500);
                  }
                }}
              >
                Enable
              </span>
            </div>,
            {
              hideAfter: 0,
              onClick: async () => {
                updateWindowPlacementPermissionRequested(true);
                hide();
              },
            }
          );
        }
      } catch (e) {
        console.log(e);
        // Nothing.
      }
    }
  }

  onPrimaryDatasourceInit() {
    const { customer, subscription, ...userInfo } = this.props.user;
    const shouldConnectPrimaryDatasource =
      !this.popoutWidget ||
      ["chat", "flow"].includes(this.popoutWidget) ||
      this.isStreamWidget(this.popoutWidget);
    if (shouldConnectPrimaryDatasource) {
      this.props.datasource.primary.init(userInfo);
      this.props.datasource.primary.on(
        "connected",
        this.onPrimarySocketConnected
      );
      // Filtered alert voice handler
      if (!this.popoutWidget) {
        this.props.datasource.primary.on(
          "filteredAlerts",
          this.onFilteredAlerts
        );
      }
    }
  }

  onRealtimeDatasourceInit() {
    const { customer, subscription, ...userInfo } = this.props.user;
    const shouldConnectRealtimeDatasource =
      !this.popoutWidget || this.isDiscoveryWidget(this.popoutWidget);
    if (shouldConnectRealtimeDatasource) {
      this.props.datasource.realtime.init(userInfo);
      this.props.datasource.realtime.on(
        "connected",
        this.onRealtimeSocketConnected
      );
    }
  }

  onPrimarySocketConnected() {
    if (this.streamSubscriptionStatus?.subscribed) {
      this.props.datasource.primary?.subscribeStream();
      this.props.datasource.primary?.off(
        "compressedUpdate",
        this.onCompressedUpdate
      );
      this.props.datasource.primary?.on(
        "compressedUpdate",
        this.onCompressedUpdate
      );
    }
  }

  onRealtimeSocketConnected() {
    this.props.datasource.realtime?.subscribeDiscovery(
      JSON.stringify(this.discoverySubscriptionStatus)
    );
    this.props.datasource.realtime?.subscribeQuotes(
      JSON.stringify(this.quotesSubscriptionStatus)
    );
  }

  handleStreamSubscriptionStatusChange(streamSubscriptionStatus) {
    this.streamSubscriptionStatus = streamSubscriptionStatus;
    if (this.streamSubscriptionStatus.subscribed) {
      this.props.datasource.primary?.subscribeStream();
      this.props.datasource.primary?.on(
        "compressedUpdate",
        this.onCompressedUpdate
      );

      this.buffer = {};
      this.flushBufferIntervalId = setInterval(this.flushBuffer, 1000);

      // init for market closed status
      this.initMarketStatusTimer();
    } else {
      this.props.datasource.primary?.unsubscribeStream();
      this.props.datasource.primary?.off(
        "compressedUpdate",
        this.onCompressedUpdate
      );

      this.flushBufferIntervalId && clearInterval(this.flushBufferIntervalId);
      this.flushBufferIntervalId = null;
      this.clearMarketStatusTimer();
    }
  }

  handleDiscoverySubscriptionStatusChange(discoverySubscriptionStatus) {
    this.discoverySubscriptionStatus = discoverySubscriptionStatus;
    this.props.datasource.realtime?.subscribeDiscovery(
      JSON.stringify(this.discoverySubscriptionStatus)
    );
  }

  handleQuotesSubscriptionStatusChange(quotesSubscriptionStatus) {
    this.quotesSubscriptionStatus = quotesSubscriptionStatus;
    this.props.datasource.realtime?.subscribeQuotes(
      JSON.stringify(this.quotesSubscriptionStatus)
    );
  }

  initScreenPosChangeHandler() {
    if (this.popoutWidget) {
      setInterval(() => {
        const isStandalone = isWebAppStandalone();
        let offsetX = 0,
          offsetY = 0;
        if (isWindows) {
          if (isChrome || isChromium) {
            offsetX = WINDOWS_CHROME_X;
            offsetY = WINDOWS_CHROME_Y;
            if (isStandalone) {
              offsetX = WINDOWS_CHROME_PWA_X;
              offsetY = WINDOWS_CHROME_PWA_Y;
            }
          } else if (isEdge || isEdgeChromium) {
            offsetX = WINDOWS_EDGE_X;
            offsetY = WINDOWS_EDGE_Y;
            if (isStandalone) {
              offsetX = WINDOWS_EDGE_PWA_X;
              offsetY = WINDOWS_EDGE_PWA_Y;
            }
          } else if (isFirefox) {
            offsetX = WINDOWS_FIREFOX_X;
            offsetY = WINDOWS_FIREFOX_Y;
          }
        } else if (isMacOs) {
          if (isChrome || isChromium) {
            offsetX = MAC_CHROME_X;
            offsetY = MAC_CHROME_Y;
            if (isStandalone) {
              offsetX = MAC_CHROME_PWA_X;
              offsetY = MAC_CHROME_PWA_Y;
            }
          } else if (isSafari || isMobileSafari) {
            offsetX = MAC_SAFARI_X;
            offsetY = MAC_SAFARI_Y;
          } else if (isFirefox) {
            offsetX = MAC_FIREFOX_X;
            offsetY = MAC_FIREFOX_Y;
          }
        }
        const screenInfo = {
          x: window.screenX,
          y: window.screenY,
          w: window.outerWidth - offsetX,
          h: window.outerHeight - offsetY,
        };
        if (checkModified(screenInfo, this.popoutScreenInfo)) {
          this.popoutScreenInfo = screenInfo;
          // this.props.updateLayoutWidgetScreenInfo({
          //   widget: this.popoutWidget,
          //   screen: screenInfo
          // })
          if (window.opener) {
            const event = new CustomEvent("popoutScreenChange", {
              detail: {
                widget: this.popoutWidget,
                screen: screenInfo,
              },
            });
            window.opener.dispatchEvent(event);
          }
        }
      }, 1000);
    } else {
      window.addEventListener(
        "popoutScreenChange",
        this.onPopoutScreenChange.bind(this)
      );
    }
  }

  onPopoutScreenChange = (event) => {
    const { locked } = getActiveLayout(this.props.layout);
    if (!locked) {
      const { widget, screen } = event.detail;
      this.props.updateLayoutWidgetScreenInfo({
        widget,
        screen,
      });
    }
  };

  promptForVoiceAlert = (config) => {
    const { voiceNoti } = config;
    if (
      Array.isArray(voiceNoti) &&
      voiceNoti.length > 0 &&
      !this.popoutWidget
    ) {
      const { hide } = cogoToast.warn(
        <div className="d-flex flex-row justify-content-between align-items-center">
          <i
            className="fa fa-volume-up ml-n3 mr-1 mr-md-2"
            style={{ fontSize: "24px" }}
          />
          <div className="text-center">
            Press any key or click this message to enable Voice Notifications
          </div>
        </div>,
        {
          position: "top-center",
          hideAfter: 5,
          bar: {
            color: "white",
          },
          renderIcon: () => {
            return <></>;
          },
          onClick: () => {
            hide();
          },
        }
      );
    }
  };

  layoutConfigChanged = async (layout) => {
    if (this.layoutPendingSaveTimeoutId) {
      clearTimeout(this.layoutPendingSaveTimeoutId);
    }
    this.layoutPendingSaveTimeoutId = setTimeout(async () => {
      this.layoutPendingSaveTimeoutId = null;
      this.updateLayoutConfig(layout);
    }, 1000);
  };

  updateLayoutConfig = async (layout) => {
    try {
      const res = await API.updateDashboardLayout(layout);
      if (!res.success) {
        throw "error";
      }
    } catch (e) {
      cogoToast.error(`Failed to update layout changes`);
    }
  };

  getSettingConfig = async () => {
    try {
      const res = await API.getSetting();
      if (!res.success) {
        throw "error";
      }
      try {
        const config = JSON.parse(res.config);
        this.props.setConfig(config);
        this.promptForVoiceAlert(config);
      } catch {
        // Save current config
        const { config } = this.props;
        try {
          this.promptForVoiceAlert(config);
          await API.updateSettingConfig(config);
        } catch (e) {}
      }
    } catch (e) {
      cogoToast.error(`Failed to get settings`);
    }
  };

  getSetting = async () => {
    const {
      discovery,
      setDiscovery,
      layout,
      layoutDeviceMedia,
      setLayoutConfig,
      triggerLayoutForceUpdate,
    } = this.props;
    try {
      const res = await API.getSetting();
      if (!res.success) {
        throw "error";
      }

      // Update Discovery
      try {
        const resDiscovery = JSON.parse(res.discovery);
        setDiscovery(resDiscovery);

        const tableFilters =
          resDiscovery.tableFilters || discovery.tableFilters;

        let shouldUpdateSelectedDiscoveryFilter;
        const updatedSelectedTableFilter = {
          ...discovery.selectedTableFilter,
        };
        for (const key in updatedSelectedTableFilter) {
          if (!tableFilters || !tableFilters[updatedSelectedTableFilter[key]]) {
            updatedSelectedTableFilter[key] = SHOW_ALL_DISCOVERY_FILTER;
            shouldUpdateSelectedDiscoveryFilter = true;
          }
        }
        shouldUpdateSelectedDiscoveryFilter &&
          setDiscovery({
            selectedTableFilter: updatedSelectedTableFilter,
          });
      } catch {
        // Save current discovery filters
        const payload = {
          tableFilters: discovery.tableFilters,
        };
        if (!payload.tableFilters) {
          payload.tableFilters = {
            [SHOW_ALL_DISCOVERY_FILTER]: {
              ...showAllTableFilter,
            },
          };
        } else if (!payload.tableFilters[SHOW_ALL_DISCOVERY_FILTER]) {
          payload.tableFilters[SHOW_ALL_DISCOVERY_FILTER] = {
            ...showAllTableFilter,
          };
        }

        try {
          await API.updateSettingDiscovery(payload);
        } catch (e) {}
      } finally {
        this.setState({
          settingDiscoveryLoaded: true,
        });
      }

      // Update Dashboard Layout
      try {
        if (!res.layout) {
          throw "";
        }
        const layoutConfig = JSON.parse(res.layout);
        if (Array.isArray(layoutConfig.layout)) {
          for (const item of layoutConfig.layout) {
            if (item.menu && Array.isArray(item.popout)) {
              if (item.menu.chat === undefined) {
                item.menu.chat = false;
                item.popout.push({
                  widget: "chat",
                  screen: {
                    x: 0,
                    y: 0,
                    w: 400,
                    h: 600,
                  },
                });
              }
            }
          }
        }
        const isModified = checkModified(layout, layoutConfig?.layout, [
          /^[.]\d+[.]active$/,
        ]);

        setLayoutConfig({
          ...layoutConfig,
          layout: composeLayout(
            DEFAULT_DASHBOARD_LAYOUT_CONFIG,
            layoutConfig.layout
          ),
        });

        if (isModified) {
          triggerLayoutForceUpdate();
        }
      } catch {
        // Save current layout config
        const layoutConfig = {
          layout,
          layoutDeviceMedia,
        };
        if (!res.layout && isMobile) {
          layoutConfig.layout[2].popout = [];
        }
        setLayoutConfig(layoutConfig);
        this.updateLayoutConfig(layoutConfig);
      }
    } catch (e) {
      cogoToast.error(`Failed to get settings`);
    }

    // This enforces code's layout structure, instead of remote.
    // enables adding new widgets to the frontend code, without the new layout structure being replaced by old remote config
    function composeLayout(layout, remoteLayout) {
      const newCombined = [];
      for (let i = 0; i < layout.length; i++) {
        const item = layout[i];
        const remoteItem = remoteLayout[i];
        if (!remoteItem) {
          newCombined.push(item);
          continue;
        }
        let newItem = {};
        for (const key in item) {
          if (["layout", "menu"].includes(key)) {
            newItem[key] = {};
            continue;
          }
          newItem[key] = remoteItem[key] ?? item[key];
        }
        for (const key in item.layout) {
          newItem.layout[key] = remoteItem.layout[key] ?? item.layout[key];
        }
        for (const key in item.menu) {
          newItem.menu[key] = remoteItem.menu[key] ?? item.menu[key];
        }
        newCombined.push(newItem);
      }
      return newCombined;
    }
  };

  getHalts = async () => {
    try {
      const res = await API.getHalts();
      if (!res.success) {
        throw "error";
      }
      this.setState({
        halts: res.halts
          .map((item) => ({
            ...item,
            type: "halt",
          }))
          .sort((a, b) => {
            if (a.t < b.t) return 1;
            if (a.t > b.t) return -1;
            if (a.s < b.s) return -1;
            if (a.s > b.s) return 1;
            return 0;
          }),
      });
    } catch (e) {
      cogoToast.error(`Failed to get halts`);
    }
  };

  initMarketStatusTimer = () => {
    let ESTTime = moment(new Date()).tz("America/New_York");
    let marketStartTime = getMarketStartEndTime(ESTTime).start;
    let marketEndTime = getMarketStartEndTime(ESTTime).end;

    if (ESTTime.isBetween(marketEndTime, marketStartTime)) {
      this.state.marketStatusTimerEnabled = true;

      const callback = () => {
        ESTTime = moment(new Date()).tz("America/New_York");
        marketStartTime = getMarketStartEndTime(ESTTime).start;
        marketEndTime = getMarketStartEndTime(ESTTime).end;

        if (!ESTTime.isBetween(marketEndTime, marketStartTime)) {
          this.clearMarketStatusTimer();
          this.setState({
            marketStatusTimerEnabled: false,
          });
          return;
        }

        const duration = moment.duration(marketStartTime.diff(ESTTime));
        let hours = parseInt(duration.asHours());
        let minutes = parseInt(duration.asMinutes()) % 60;
        let seconds = parseInt(duration.asSeconds()) % 60;
        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        if (seconds < 10) seconds = `0${seconds}`;
        this.setState({
          marketStatusCurrentTime: `${hours}:${minutes}:${seconds}`,
        });
      };
      this.marketStatusTimerId = setInterval(callback, 1000);
      callback();
    }
  };

  clearMarketStatusTimer = () => {
    this.marketStatusTimerId && clearInterval(this.marketStatusTimerId);
    this.marketStatusTimerId = null;
  };

  // updateDimensions = () => {
  //   if (!this.container) {
  //     return;
  //   }
  //   let restSpace = 300;
  //   const width = this.container.offsetWidth;
  //   if (width < 415) {
  //     restSpace = 30;
  //   } else if (width < 900) {
  //     restSpace = 150;
  //   }
  //   const total = Math.ceil(
  //     (this.container.offsetWidth - restSpace - 160) / 20
  //   );
  //   this.setState({ total });
  // };

  smallDeviceChangeHandler = (e) => {
    this.setState({ isSmallDevice: e.matches });
  };

  initarrangeLayout = (force) => {
    for (const query of LAYOUT_BREAKPOINT_MEDIA_QUERIES) {
      const matches = window.matchMedia(query);
      this.rearrangeLayout(matches, force);
    }
  };

  rearrangeLayout = (e, force) => {
    const {
      updateActiveLayoutConfig,
      updateLayoutDeviceMedia,
      layoutDeviceMedia,
    } = this.props;
    const { key: activeLayoutKey } = getActiveLayout(
      this.props.layout,
      this.props.setLayoutActive
    );

    const hasMultipleStreamWidget = this.hasMultipleStreamWidget();

    if (e.matches) {
      if (
        activeLayoutKey === "dynamic" &&
        (force || !layoutDeviceMedia || e.media != layoutDeviceMedia)
      ) {
        let layoutConfig = getLayoutForMediaQuery(
          e.media,
          hasMultipleStreamWidget
        );

        updateActiveLayoutConfig(layoutConfig);
        this.setState({
          layoutElemKey: this.state.layoutElemKey + 1,
        });
      }

      updateLayoutDeviceMedia(e.media);
    }
  };

  getInitialState = () => {
    return {
      highs: {},
      lows: {},
      bars: [1, 0.6, -1],
      stats: [],
      halts: [],
      showHaltBar: true,
      popoverOpened: false,
      stockCards: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      isSmallDevice: window.matchMedia("(max-width: 768px)").matches,
      total: 0,
      showSpinner: false,
      isFavFilter: false,
      marketStatusTimerEnabled: false,
      marketStatusCurrentTime: "",
      marketLoadingRecent: false,
      settingDiscoveryLoaded: false,
      layoutElemKey: 1,
    };
  };

  onCompressedUpdate = (event) => {
    this._handleData(event.detail);
  };

  onFilteredAlerts = (event) => {
    const data = event.detail;
    this.voiceAlerter.handleData({ filteredAlerts: data });
  };

  onFlowAdd = (symbol) => {
    this.voiceAlerter.handleData({
      flows: [symbol],
    });
  };

  _handleData = (data, ignoreTruncating) => {
    const msg = data[0];
    const highsDefault = msg[1];
    const lowsDefault = msg[2];
    const halts = msg[3];
    const highsT1 = msg[4];
    const highsT2 = msg[5];
    const lowsT1 = msg[6];
    const lowsT2 = msg[7];

    if ("DISABLED" in window) {
      return false;
    }

    // Forward events to VoiceAlert module
    if (!ignoreTruncating) {
      this.voiceAlerter.handleData({
        highs: highsDefault,
        highsT1,
        highsT2,
        lows: lowsDefault,
        lowsT1,
        lowsT2,
        halts,
      });
    }

    // Process Halts
    if (Array.isArray(halts)) {
      if (!Array.isArray(this.haltsBuffer)) {
        this.haltsBuffer = [];
      }
      this.haltsBuffer = this.haltsBuffer.concat(halts);
    }

    // Put into stream buffer
    const { stream } = this.props.config;
    for (const channelItem of stream) {
      let highs = highsDefault;
      let lows = lowsDefault;
      if (channelItem.mode === STREAM_CHANNEL_MODE.NEART1) {
        highs = highsT1;
        lows = lowsT1;
      } else if (channelItem.mode === STREAM_CHANNEL_MODE.NEART2) {
        highs = highsT2;
        lows = lowsT2;
      }
      const filtered_lows = this.applyFilter(lows, channelItem);
      const filtered_highs = this.applyFilter(highs, channelItem);

      if (!this.buffer[channelItem.channel]) {
        this.buffer[channelItem.channel] = [];
      }
      const buffer = this.buffer[channelItem.channel];
      if (filtered_lows.length + filtered_highs.length > 0) {
        if (!ignoreTruncating && buffer.length > 300) {
          buffer.splice(0);
          console.error("Buffer too big, truncating");
        }
        buffer.push({ highs: filtered_highs, lows: filtered_lows });
        if (buffer.length > 1000) {
          buffer.shift();
        }
      }
    }
  };

  subscribeChannels = (channels) => {
    channels.forEach((c) => {
      if (c.subscribed === true) this.socket.emit("subscribe", c.value);
      else this.socket.emit("unsubscribe", c.value);
    });
  };

  isSymbolFav = (symbol) => {
    const { quotes } = this.props;
    const qouteItem = quotes.find((item) => item.symbol === symbol);
    return qouteItem ? true : false;
  };

  // Keep synced with VoiceAlertHandler.filterPrimaryStream
  applyFilter = (data, { channel, title, type, value }) => {
    if (!type || type === STREAM_CHANNEL_TYPE.NONE) {
      return [];
    }

    const { optionsMode, options, popularSymbols } = this.props;

    if (!value.float) value.float = DEFAULT_STREAM_SETTING.float;
    if (!value.price) value.price = DEFAULT_STREAM_SETTING.price;
    if (!value.volume) value.volume = DEFAULT_STREAM_SETTING.volume;
    if (!value.count) value.count = DEFAULT_STREAM_SETTING.count;
    if (!value.atr) value.atr = DEFAULT_STREAM_SETTING.atr;
    if (!value.gap) value.gap = DEFAULT_STREAM_SETTING.gap;
    if (!value.marketCap) value.marketCap = DEFAULT_STREAM_SETTING.marketCap;
    if (!value.custom_view) value.custom_view = [];

    let sectorDigits = {};
    for (let key in value.industries) {
      if (value.industries[key]) {
        sectorDigits[SECTORS_FILTER[key]] = true;
      }
    }

    const popularFilterSymbols = (popularSymbols || []).slice(0, 100);

    const filtered_data = data.filter((item) => {
      return TEST_SYMBOLS.indexOf(item[0]) < 0;
    });
    if (type === STREAM_CHANNEL_TYPE.FILTER) {
      return filtered_data
        .filter((item, i) => {
          let price = item[1];
          let priceFilter = value.price;
          const min = priceFilter.min || 0;
          const max = priceFilter.max >= PRICE_MAX ? Infinity : priceFilter.max;
          return price >= min && price <= max;
        })
        .filter((item, i) => {
          let volume = item[5];
          let volumeFilter = value.volume;
          const min = volumeFilter.min || 0;
          const max =
            volumeFilter.max >= AVG_VOL_MAX ? Infinity : volumeFilter.max;
          return volume >= min && volume <= max;
        })
        .filter((item) => {
          const float = item[7];
          const floatFilter = value.float;
          const min = floatFilter.min || 0;
          const max = floatFilter.max >= FLOAT_MAX ? Infinity : floatFilter.max;
          return float >= min && float <= max;
        })
        .filter((item) => {
          const count = item[2];
          const countFilter = value.count;
          const min = countFilter.min || 0;
          const max = countFilter.max >= COUNT_MAX ? Infinity : countFilter.max;
          return count >= min && count <= max;
        })
        .filter((item) => {
          if (item[6]) {
            return sectorDigits[item[6]];
          }
        })
        .filter((item) => {
          if (!this.props.isProPlus) {
            return true;
          } else {
            const atr = item[9];
            const atrFilter = value.atr;
            const min = atrFilter.min || 0;
            const max = atrFilter.max >= ATR_MAX ? Infinity : atrFilter.max;
            return atr >= min && atr <= max;
          }
        })
        .filter((item) => {
          if (!this.props.isProPlus) {
            return true;
          } else {
            const gap = Math.abs(item[10]); // Gap Percent Dist
            const gapFilter = value.gap;
            const min = gapFilter.min || 0;
            const max = gapFilter.max >= GAP_MAX ? Infinity : gapFilter.max;
            return gap >= min && gap <= max;
          }
        })
        .filter((item) => {
          const marketCap = item[12];
          const marketCapFilter = value.marketCap;
          const min = marketCapFilter.min || 0;
          const max =
            marketCapFilter.max >= MARKET_CAP_MAX
              ? Infinity
              : marketCapFilter.max;
          return marketCap >= min && marketCap <= max;
        })
        .filter((item) => {
          if (optionsMode !== "Filter") return true;
          if (options.indexOf(item[0]) > -1) return true;
          if (this.isSymbolFav(item[0])) return true;
          return false;
        });
    }
    if (type === STREAM_CHANNEL_TYPE.CUSTOM) {
      return filtered_data.filter((item) => {
        return value.custom_view.indexOf(item[0]) > -1;
      });
    }
    if (type === STREAM_CHANNEL_TYPE.FAV) {
      return filtered_data.filter((item) => {
        return this.isSymbolFav(item[0]);
      });
    }
    if (type === STREAM_CHANNEL_TYPE.POPULAR) {
      return filtered_data.filter((item) => {
        return popularFilterSymbols.includes(item[0]);
      });
    }
    return [];
  };

  flushBuffer = () => {
    if (this.state.freezed) {
      console.log("Flush buffer freezed");
      return false;
    }

    // Process main stream
    if (!this.buffer) {
      this.buffer = {};
    }
    for (const channel in this.buffer) {
      if (!this.buffer[channel]) {
        this.buffer[channel] = [];
      }
      const buffer = this.buffer[channel];
      if (buffer.length) {
        let highs = (this.state.highs[channel] || []).slice();
        let lows = (this.state.lows[channel] || []).slice();
        buffer.forEach(function (item, i, arr) {
          highs = item.highs.concat(highs).slice(0, 300);
          lows = item.lows.concat(lows).slice(0, 300);
        });
        this.setState({
          lows: {
            ...this.state.lows,
            [channel]: lows,
          },
          highs: {
            ...this.state.highs,
            [channel]: highs,
          },
        });
        buffer.splice(0);
      }
    }

    // Process halts
    const halts = this.haltsBuffer || [];
    this.haltsBuffer = [];
    const newHalts = [];
    for (const item of this.state.halts || []) {
      const newItemId = halts.findIndex((a) => a[0] === item.s);
      const newItem = newItemId > -1 ? halts[newItemId] : null;
      if (!newItem) {
        newHalts.push(item);
      } else {
        if (newItem[1] === "halt") {
          newHalts.push({
            s: newItem[0],
            type: newItem[1],
            t: newItem[2],
          });
        } else {
          newHalts.push({
            s: newItem[0],
            type: newItem[1],
            t: new Date().getTime(),
          });
        }
        halts.splice(newItemId, 1);
      }
    }
    const remainingHalts = halts
      .sort((a, b) => {
        if (a[2] < b[2]) return 1;
        if (a[2] > b[2]) return -1;
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
      })
      .reverse();
    for (const item of remainingHalts) {
      newHalts.unshift({
        s: item[0],
        type: item[1],
        t: item[1] === "halt" ? item[2] : new Date().getTime(),
      });
    }
    this.setState({
      halts: newHalts,
    });
  };

  getLast = (OTC, ticker) => {
    return OTC === 1 ? round(ticker, 4) : round(ticker, 2);
  };

  getStreamBadgeStyle = (isFav, isSt, isNews) => {
    let badgeCnt = 0;
    if (isFav) badgeCnt++;
    if (isSt) badgeCnt++;
    if (isNews) badgeCnt++;

    return {
      sizeClass: `stream-badge-${badgeCnt}`,
      luldClass: `stream-badge-luld-spacing-${badgeCnt}`,
    };
  };

  renderData = (data, type, params) => {
    const { luld: isLULDVisible, showingOne } = params;
    const { maximisedView: max, optionsMode, options } = this.props;
    let {
      config: { news: news_config },
    } = this.props;
    if (!news_config) {
      news_config = DEFAULT_NEWS_CONFIG;
    }

    let renderData = [];
    let renderMenuItems = [];

    // Apply options
    const filtered = data.filter((item) => {
      if (optionsMode !== "Filter") return true;
      if (options.indexOf(item[0]) > -1) return true;
      if (this.isSymbolFav(item[0])) return true;
      return false;
    });

    if (type === "low") {
      filtered.map((low, index) => {
        /** Cover Table Cell With Popover Trigger */
        const isFav = this.isSymbolFav(low[0]);
        const isSt = low[4] === 1;
        const isLULD = low[11];
        let isNews = false;
        if (!!low[8]) {
          const duration = moment.duration(moment().diff(moment(low[8])));
          let diff_minutes = parseInt(duration.asMinutes());
          if (diff_minutes <= 60 * news_config.recency) {
            isNews = true;
          }
        }

        const { sizeClass, luldClass } = this.getStreamBadgeStyle(
          isFav,
          isSt,
          isNews
        );

        renderData.push(
          // high[3] === 1 means Active
          <tr key={`render-stock-data-table-low-${index}`}>
            <td className="text-low stream-symbol flex-fill">
              <label
                className={`stock-text ${
                  low[3] === 1 ? "stock-active-text stock-active-low" : ""
                }`}
              >
                <Symbol
                  symbol={low[0]}
                  showOptions={true}
                  optionsColor={low[3] === 1 ? "black" : "#fc424a"}
                />
                {isLULDVisible && isRegularMarketOpen() && (
                  <div className={`stream-badge-luld ${luldClass}`}>
                    {isLULD && <LULD up={isLULD[0]} down={isLULD[1]} />}
                  </div>
                )}
              </label>
              <div className="stream-badge-wrapper">
                <div
                  className={`stream-badge-container d-flex flex-row flex-wrap ${sizeClass}`}
                >
                  {!isFav || (
                    <i className={`mdi mdi-star stream-badge-fav quote-star`} />
                  )}
                  {!isSt || (
                    <img
                      className={`stream-badge-st`}
                      src={require("../../assets/images/dashboard/stock-tweets.svg")}
                    />
                  )}
                  {!isNews || (
                    <img
                      className={`stream-badge-news`}
                      src={require("../../assets/images/dashboard/news-icon-color.svg")}
                    />
                  )}
                </div>
              </div>
            </td>
            <td className="text-low flex-fill">
              <label className="stock-text stock-count">{low[2]}</label>
            </td>
            <td className="text-low flex-fill">
              <label className="stock-text">
                {`${round(this.getLast(low[6], low[1]), 2)}`}
              </label>
            </td>
          </tr>
        );
      });
    } else {
      filtered.map((high, index) => {
        /** Cover Table Cell With Popover Trigger */
        const isFav = this.isSymbolFav(high[0]);
        const isSt = high[4] === 1;
        const isLULD = high[11];
        let isNews = false;
        if (!!high[8]) {
          const duration = moment.duration(moment().diff(moment(high[8])));
          let diff_minutes = parseInt(duration.asMinutes());
          if (diff_minutes <= 60 * news_config.recency) {
            isNews = true;
          }
        }

        const { sizeClass, luldClass } = this.getStreamBadgeStyle(
          isFav,
          isSt,
          isNews
        );

        renderData.push(
          // high[3] === 1 means Active
          <tr key={`render-stock-data-table-high-${index}`}>
            <td className="text-high stream-symbol flex-fill">
              <label
                className={`stock-text ${
                  high[3] === 1 ? "stock-active-text stock-active-high" : ""
                }`}
              >
                <Symbol
                  symbol={high[0]}
                  showOptions={true}
                  optionsColor={high[3] === 1 ? "black" : "#00d25b"}
                />
                {isLULDVisible && isRegularMarketOpen() && (
                  <div className={`stream-badge-luld ${luldClass}`}>
                    {isLULD && <LULD up={isLULD[0]} down={isLULD[1]} />}
                  </div>
                )}
              </label>
              <div className="stream-badge-wrapper">
                <div
                  className={`stream-badge-container d-flex flex-row flex-wrap ${sizeClass}`}
                >
                  {!isFav || (
                    <i className={`mdi mdi-star stream-badge-fav quote-star`} />
                  )}
                  {!isSt || (
                    <img
                      className={`stream-badge-st`}
                      src={require("../../assets/images/dashboard/stock-tweets.svg")}
                    />
                  )}
                  {!isNews || (
                    <img
                      className={`stream-badge-news`}
                      src={require("../../assets/images/dashboard/news-icon-color.svg")}
                    />
                  )}
                </div>
              </div>
            </td>
            <td className="text-high flex-fill">
              <label className="stock-text stock-count">{high[2]}</label>
            </td>
            <td className="text-high flex-fill">
              <label className="stock-text">
                {`${round(this.getLast(high[6], high[1]), 2)}`}
              </label>
            </td>
          </tr>
        );
      });
    }
    return (
      <div
        className={
          "tableFixHead stream-section-wrapper nopadding" +
          (showingOne ? " w-100" : " col-6") +
          (max ? " table-max" : "")
        }
        style={{ height: "auto" }}
      >
        <Scrollbars
          autoHide
          style={{
            width: "100%",
            height: "calc(100%)",
            textTransform: "uppercase",
          }}
          renderTrackVertical={(props) => (
            <div className="track-vertical" {...props} />
          )}
        >
          <table className="table table-striped h-100">
            <tbody>{renderData}</tbody>
          </table>
        </Scrollbars>
        {renderMenuItems}
      </div>
    );
  };

  renderStreamHeader = (showingOne) => {
    const { maximisedView: max } = this.props;
    return (
      <div
        className={
          "tableFixHead stream-section-wrapper nopadding" +
          (showingOne ? " w-100" : " col-6") +
          (max ? " table-max" : "")
        }
        style={{ height: "auto" }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-white">
                <div className={"th-item-wrapper"}> Symbol </div>
              </th>
              <th className="text-white">
                <div className={"th-item-wrapper"}> Count </div>
              </th>
              <th className="text-white">
                <div className={"th-item-wrapper"}> Last </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  };

  renderHaltBar = () => {
    const { halt: haltConfig } = this.props.config;
    return (
      <HaltBar
        halts={this.state.halts.filter(
          (item) => !TEST_SYMBOLS.includes(item.s)
        )}
        config={haltConfig}
        onClose={() => {
          this.setState({
            showHaltBar: false,
          });
        }}
        onResumeEnd={(symbol) => {
          this.setState({
            halts: this.state.halts.filter((item) => item.s !== symbol),
          });
        }}
      />
    );
  };

  getRecentStreamData = async () => {
    if (this.state.marketLoadingRecent) {
      return;
    }
    try {
      this.setState({
        marketLoadingRecent: true,
      });

      const response = await API.getRecentStreams();
      const { data } = response;

      for (let i = 0; i < data.length; i++) {
        this._handleData([data[i]]);
      }

      this.clearMarketStatusTimer();
      this.setState({
        marketStatusTimerEnabled: false,
      });
    } catch (e) {
      cogoToast.error("Failed to get recent stream.");
    } finally {
      this.setState({
        marketLoadingRecent: false,
      });
    }
  };

  renderStreamLoadRecentSection = () => {
    const { marketStatusCurrentTime, marketLoadingRecent } = this.state;
    return (
      <div
        className={
          "col-12 stream-load-recent-section text-center mt-3 mt-sm-5 pt-sm-5"
        }
      >
        <div>
          <span>MARKET IS CURRENTLY CLOSED</span>
        </div>
        <div>
          <span>OPENS IN {marketStatusCurrentTime || ""}</span>
        </div>
        <div
          className={"d-flex justify-content-center align-items-center mt-4"}
        >
          <div className={"btn-load-recent"} onClick={this.getRecentStreamData}>
            LOAD RECENT
            {!marketLoadingRecent || (
              <i className="fa fa-circle-o-notch fa-spin ml-1"></i>
            )}
          </div>
        </div>
      </div>
    );
  };

  isStreamWidget = (widget) => {
    return (widget || "").startsWith("stream");
  };

  isDiscoveryWidget = (widget) => {
    return (widget || "").startsWith("discovery");
  };

  hasMultipleStreamWidget = () => {
    const { layout, setLayoutActive } = this.props;
    return hasMultipleStreamWidget(layout, setLayoutActive);
  };

  renderStream = (widget) => {
    const { isSmallDevice, marketStatusTimerEnabled, showHaltBar } = this.state;
    const {
      maximisedView,
      config: { stream },
      stream: {
        [widget]: { shown },
      },
    } = this.props;

    // const streamWidgets = (stream || []).filter(item => item.type && item.type !== STREAM_CHANNEL_TYPE.NONE);
    const streamConfig = (stream || []).find((item) => item.channel === widget);

    const isHaltBarVisible = streamConfig?.haltluld?.halt && showHaltBar;
    const isLULDVisible = streamConfig?.haltluld?.luld;

    // const minPrice = price.min;
    // const maxPrice = price.max === PRICE_MAX ? "∞" : price.max;
    // const minVolume = abbreviate(volume.min);
    // const maxVolume = volume.max === AVG_VOL_MAX ? "∞" : abbreviate(volume.max);
    // const minFloat = abbreviate(float.min);
    // const maxFloat = float.max === FLOAT_MAX ? "∞" : abbreviate(float.max);

    const lows = this.state.lows[widget] || [];
    const highs = this.state.highs[widget] || [];

    return (
      <div className={`card w-100 ${maximisedView ? "h-auto" : "h-100"}`}>
        <div className="px-3 pt-3 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <ColorBar color={streamConfig?.color} className="ml-n2 mr-2" />
            <h4 className="mb-0" style={{}}>
              <span
                style={{ textTransform: "capitalize" }}
              >{`${streamConfig?.title}`}</span>
              {/* { streamWidgets.length > 1 && this.hasMultipleStreamWidget()
                  ? <span style={{textTransform: 'capitalize'}}>{`${streamConfig?.title}`}</span>
                  : 'Stream' } */}
            </h4>
          </div>
          <StreamSettingPopover widget={widget} />
        </div>
        {isSmallDevice ? (
          <div className="d-flex flex-column h-100 mx-0" style={{}}>
            {!marketStatusTimerEnabled ? (
              <>
                <div className="row mx-0">
                  {[shown.lows, shown.highs].map(
                    (item) =>
                      item &&
                      this.renderStreamHeader(shown.lows !== shown.highs)
                  )}
                </div>
                {isHaltBarVisible && (
                  <div className="row mx-0">{this.renderHaltBar()}</div>
                )}
                <div
                  className="row mx-0 flex-grow-1"
                  style={{ height: "100%" }}
                >
                  {shown.lows &&
                    this.renderData(lows, "low", {
                      luld: isLULDVisible,
                      showingOne: shown.lows !== shown.highs,
                    })}
                  {shown.highs &&
                    this.renderData(highs, "high", {
                      luld: isLULDVisible,
                      showingOne: shown.lows !== shown.highs,
                    })}
                </div>
              </>
            ) : (
              <div className="row mx-0" style={{}}>
                {[shown.lows, shown.highs].map(
                  (item) =>
                    item && this.renderStreamHeader(shown.lows !== shown.highs)
                )}
                {this.renderStreamLoadRecentSection()}
              </div>
            )}
          </div>
        ) : (
          <div className="card-body stream-body d-flex flex-column">
            {!marketStatusTimerEnabled ? (
              <>
                <div className="row mx-0">
                  {[shown.lows, shown.highs].map(
                    (item) =>
                      item &&
                      this.renderStreamHeader(shown.lows !== shown.highs)
                  )}
                </div>
                {isHaltBarVisible && (
                  <div className="row mx-0">{this.renderHaltBar()}</div>
                )}
                <div
                  className="row mx-0 flex-grow-1"
                  style={{ height: "100%" }}
                >
                  {shown.lows &&
                    this.renderData(lows, "low", {
                      luld: isLULDVisible,
                      showingOne: shown.lows !== shown.highs,
                    })}
                  {shown.highs &&
                    this.renderData(highs, "high", {
                      luld: isLULDVisible,
                      showingOne: shown.lows !== shown.highs,
                    })}
                </div>
              </>
            ) : (
              <div className="row mx-0" style={{}}>
                {[shown.lows, shown.highs].map(
                  (item) =>
                    item && this.renderStreamHeader(shown.lows !== shown.highs)
                )}
                {this.renderStreamLoadRecentSection()}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  renderDiscovery = (widget) => {
    return (
      (this.props.isPro || this.props.isProPlus) && (
        <Discovery
          widget={widget}
          settingLoaded={this.state.settingDiscoveryLoaded}
        />
      )
    );
  };

  renderNews = () => {
    return <News />;
  };

  renderPopular = () => {
    return <Popular />;
  };

  renderAlerts = () => {
    return <Alerts />;
  };

  renderChat = () => {
    return <Chat />;
  };

  renderFlow = () => {
    return <Flow onFlowAdd={this.onFlowAdd.bind(this)} />;
  };

  renderChart = () => {
    return (this.props.isPro || this.props.isProPlus) && <Chart />;
  };

  renderLayoutWidgetToolbar = (widget, options = {}) => {
    const { fullscreen } = options;

    const {
      maximiseView,
      openLayoutWidgetPopout,
      updateWindowPlacementPermissionRequested,
    } = this.props;

    let { stream } = this.props.config;
    stream = (stream || []).filter(
      (item) => item.type && item.type !== STREAM_CHANNEL_TYPE.NONE
    );

    return (
      <div className="layout-widget-toolbar">
        <i className="layout-dnd-handler mdi mdi-drag-horizontal"></i>
        <span>
          <i
            className="fa fa-external-link"
            onClick={async () => {
              try {
                const { state } = await navigator.permissions.query({
                  name: "window-placement",
                });
                const granted = state === "granted";
                if (!granted) {
                  await window.getScreenDetails();
                }
              } catch (e) {
                console.log(e);
                // Do nothing
              }
              maximiseView(null);
              openLayoutWidgetPopout(widget);
              updateWindowPlacementPermissionRequested(true);
            }}
          />
        </span>
        <span>
          <i
            className={fullscreen ? "mdi mdi-close" : "fa fa-expand"}
            onClick={() => {
              maximiseView(fullscreen ? null : widget);
            }}
          />
        </span>
      </div>
    );
  };

  onLayoutChange = (layout) => {
    const { updateActiveLayoutConfig } = this.props;
    const transformed = {};
    for (const item of layout) {
      const { x, y, w, h, minW, minH, i } = item;
      transformed[i] = {
        x,
        y,
        w,
        h,
        minW,
        minH,
      };
    }
    updateActiveLayoutConfig(transformed);
  };

  getPopoutOptions = (widget, activeLayout) => {
    let options = {};
    const popoutInfo = (activeLayout?.popout || []).find(
      (item) => item.widget === widget
    );
    if (popoutInfo?.screen) {
      const screen = popoutInfo?.screen;
      options.left = screen.x + "px";
      options.top = screen.y + "px";
      options.width = screen.w + "px";
      options.height = screen.h + "px";
    } else {
      const layout = activeLayout?.layout?.[widget];
      if (layout) {
        options.left = "100px";
        options.top = "100px";
        options.width = layout.w * 100 + "px";
        options.height = layout.h * 30 + "px";
      }
    }
    return options;
  };

  onPopoutError = (widget) => {
    this.props.setIsPopoutBlocked(true);
  };

  onPopoutClose = (widget, prevLayout) => {
    const { layout, closeLayoutWidgetPopout, openLayoutWidgetPopout } =
      this.props;

    const { key: activeLayoutKey, locked: activeLayoutLocked } =
      getActiveLayout(layout);
    const { key: prevActiveLayoutKey } = prevLayout;

    closeLayoutWidgetPopout(widget);

    activeLayoutKey === prevActiveLayoutKey &&
      activeLayoutLocked &&
      setTimeout(() => {
        openLayoutWidgetPopout(widget);
      }, 500);
  };

  renderPopouts = () => {
    const activeLayout = getActiveLayout(
      this.props.layout,
      this.props.setLayoutActive
    );

    return (
      <>
        {!this.popoutWidget && isWidgetPopout("stream1", activeLayout) && (
          <Popout
            title="MOMO Pro Stream1"
            url="/popout/stream1"
            options={this.getPopoutOptions("stream1", activeLayout)}
            onClosing={() => this.onPopoutClose("stream1", activeLayout)}
            onError={() => this.onPopoutError("stream1")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("stream2", activeLayout) && (
          <Popout
            title="MOMO Pro Stream2"
            url="/popout/stream2"
            options={this.getPopoutOptions("stream2", activeLayout)}
            onClosing={() => this.onPopoutClose("stream2", activeLayout)}
            onError={() => this.onPopoutError("stream2")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("flow", activeLayout) && (
          <Popout
            title="MOMO Flow"
            url="/popout/flow"
            options={this.getPopoutOptions("flow", activeLayout)}
            onClosing={() => this.onPopoutClose("flow", activeLayout)}
            onError={() => this.onPopoutError("flow")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("popular", activeLayout) && (
          <Popout
            title="MOMO Pro Popular"
            url="/popout/popular"
            options={this.getPopoutOptions("popular", activeLayout)}
            onClosing={() => this.onPopoutClose("popular", activeLayout)}
            onError={() => this.onPopoutError("popular")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("alerts", activeLayout) && (
          <Popout
            title="MOMO Pro Alerts"
            url="/popout/alerts"
            options={this.getPopoutOptions("alerts", activeLayout)}
            onClosing={() => this.onPopoutClose("alerts", activeLayout)}
            onError={() => this.onPopoutError("alerts")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("news", activeLayout) && (
          <Popout
            title="MOMO Pro News"
            url="/popout/news"
            options={this.getPopoutOptions("news", activeLayout)}
            onClosing={() => this.onPopoutClose("news", activeLayout)}
            onError={() => this.onPopoutError("news")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("chart", activeLayout) && (
          <Popout
            title="MOMO Pro Live Chart"
            url="/popout/chart"
            options={this.getPopoutOptions("chart", activeLayout)}
            onClosing={() => this.onPopoutClose("chart", activeLayout)}
            onError={() => this.onPopoutError("chart")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("chat", activeLayout) && (
          <Popout
            title="MOMO Pro Chat"
            url="/popout/chat"
            options={this.getPopoutOptions("chat", activeLayout)}
            onClosing={() => this.onPopoutClose("chat", activeLayout)}
            onError={() => this.onPopoutError("chat")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("discovery1", activeLayout) && (
          <Popout
            title="MOMO Pro Discovery1"
            url="/popout/discovery1"
            options={this.getPopoutOptions("discovery1", activeLayout)}
            onClosing={() => this.onPopoutClose("discovery1", activeLayout)}
            onError={() => this.onPopoutError("discovery1")}
          />
        )}

        {!this.popoutWidget && isWidgetPopout("discovery2", activeLayout) && (
          <Popout
            title="MOMO Pro Discovery2"
            url="/popout/discovery2"
            options={this.getPopoutOptions("discovery2", activeLayout)}
            onClosing={() => this.onPopoutClose("discovery2", activeLayout)}
            onError={() => this.onPopoutError("discovery2")}
          />
        )}
      </>
    );
  };

  render() {
    if (this.popoutWidget) {
      const widget = this.popoutWidget;
      if (widget === "flow") {
        return this.renderFlow();
      } else if (widget === "popular") {
        return this.renderPopular();
      } else if (widget === "alerts") {
        return this.renderAlerts();
      } else if (widget === "news") {
        return this.renderNews();
      } else if (widget === "chart") {
        return this.renderChart();
      } else if (widget === "chat") {
        return this.renderChat();
      } else if (this.isDiscoveryWidget(widget)) {
        return this.renderDiscovery(widget);
      } else if (this.isStreamWidget(widget)) {
        return this.renderStream(widget);
      } else {
        return null;
      }
    }

    if (!this.props.layoutRestored) {
      return (
        <div className="overlay">
          <Spinner
            className={"overlay-content"}
            animation="border"
            variant="success"
          />
        </div>
      );
    }

    const { maximisedView, history, layoutDeviceMedia } = this.props;
    const activeLayout = getActiveLayout(
      this.props.layout,
      this.props.setLayoutActive
    );
    const { layout: layoutConfig, locked: layoutLocked } = activeLayout;

    const screenMatchingLayout = getLayoutForMediaQuery(
      layoutDeviceMedia,
      this.hasMultipleStreamWidget()
    );
    if (isWidgetVisible("stream1", activeLayout) && !layoutConfig["stream1"]) {
      layoutConfig["stream1"] = screenMatchingLayout.stream1;
      if (
        this.hasMultipleStreamWidget() &&
        isWidgetVisible("stream2", activeLayout)
      ) {
        layoutConfig["stream2"] = screenMatchingLayout.stream2;
      }
    }
    if (isWidgetVisible("stream2", activeLayout) && !layoutConfig["stream2"]) {
      layoutConfig["stream2"] = screenMatchingLayout.stream1;
      if (
        this.hasMultipleStreamWidget() &&
        isWidgetVisible("stream1", activeLayout)
      ) {
        layoutConfig["stream1"] = screenMatchingLayout.stream1;
      }
    }
    if (isWidgetVisible("flow", activeLayout) && !layoutConfig["flow"]) {
      layoutConfig["flow"] = screenMatchingLayout.flow;
    }
    if (isWidgetVisible("popular", activeLayout) && !layoutConfig["popular"]) {
      layoutConfig["popular"] = screenMatchingLayout.popular;
    }
    if (isWidgetVisible("alerts", activeLayout) && !layoutConfig["alerts"]) {
      layoutConfig["alerts"] = screenMatchingLayout.alerts;
    }
    if (isWidgetVisible("news", activeLayout) && !layoutConfig["news"]) {
      layoutConfig["news"] = screenMatchingLayout.news;
    }
    if (isWidgetVisible("chart", activeLayout) && !layoutConfig["chart"]) {
      layoutConfig["chart"] = screenMatchingLayout.chart;
    }
    if (isWidgetVisible("chat", activeLayout) && !layoutConfig["chat"]) {
      layoutConfig["chat"] = screenMatchingLayout.chat;
    }
    if (
      isWidgetVisible("discovery1", activeLayout) &&
      !layoutConfig["discovery1"]
    ) {
      layoutConfig["discovery1"] = screenMatchingLayout.discovery1;
    }
    if (
      isWidgetVisible("discovery2", activeLayout) &&
      !layoutConfig["discovery2"]
    ) {
      layoutConfig["discovery2"] = screenMatchingLayout.discovery2;
    }

    if (maximisedView) {
      return (
        <div className="row dashboard-content dashboard-maximized flex-grow-1 mx-0">
          {this.renderPopouts()}
          {this.renderLayoutWidgetToolbar(maximisedView, { fullscreen: true })}
          {this.isStreamWidget(maximisedView) &&
            this.renderStream(maximisedView)}
          {this.isDiscoveryWidget(maximisedView) &&
            this.renderDiscovery(maximisedView)}
          {maximisedView === "flow" && this.renderFlow()}
          {maximisedView === "alerts" && this.renderAlerts()}
          {maximisedView === "popular" && this.renderPopular()}
          {maximisedView === "news" && this.renderNews()}
          {maximisedView === "chart" && this.renderChart()}
          {maximisedView === "chat" && this.renderChat()}
        </div>
      );
    }

    return (
      <div>
        {this.renderPopouts()}

        {this.props.showSpinner && (
          <div className="overlay">
            <Spinner
              className={"overlay-content"}
              animation="border"
              variant="success"
            />
          </div>
        )}

        <div
          className="row dashboard-content flex-grow-1 mx-0"
          ref={(ref) => {
            this.container = ref;
          }}
        >
          <div className="col-12 stretch-card px-0">
            <div className="col-12 card-body py-0 px-0">
              <MainMenu history={history} />

              {isWidgetVisible("meters", activeLayout) && (
                <Meters
                  onClose={() => {
                    this.setState({
                      showMeters: false,
                    });
                  }}
                />
              )}

              {isWidgetVisible("quotes", activeLayout) && <Quote />}

              <ReactGridLayout
                key={this.state.layoutElemKey}
                className={layoutLocked ? "layout-locked" : "layout-unlocked"}
                isDraggable={!layoutLocked}
                isResizable={!layoutLocked}
                draggableHandle=".layout-dnd-handler"
                rowHeight={25}
                cols={12}
                // margin={[0, 0]}
                containerPadding={[0, 0]}
                onLayoutChange={this.onLayoutChange}
              >
                {isWidgetVisible("flow", activeLayout) && (
                  <div key="flow" data-grid={layoutConfig["flow"]}>
                    {this.renderLayoutWidgetToolbar("flow")}
                    {this.renderFlow()}
                  </div>
                )}

                {["stream1", "stream2"]
                  .filter((widget) => isWidgetVisible(widget, activeLayout))
                  .map((widget) => (
                    <div key={widget} data-grid={layoutConfig[widget]}>
                      {this.renderLayoutWidgetToolbar(widget)}
                      {this.renderStream(widget)}
                    </div>
                  ))}

                {(this.props.isPro || this.props.isProPlus) &&
                  isWidgetVisible("chart", activeLayout) && (
                    <div key="chart" data-grid={layoutConfig["chart"]}>
                      {this.renderLayoutWidgetToolbar("chart")}
                      {this.renderChart()}
                    </div>
                  )}

                {isWidgetVisible("chat", activeLayout) && (
                  <div key="chat" data-grid={layoutConfig["chat"]}>
                    {this.renderLayoutWidgetToolbar("chat")}
                    {this.renderChat()}
                  </div>
                )}

                {isWidgetVisible("popular", activeLayout) && (
                  <div key="popular" data-grid={layoutConfig["popular"]}>
                    {this.renderLayoutWidgetToolbar("popular")}
                    {this.renderPopular()}
                  </div>
                )}

                {isWidgetVisible("alerts", activeLayout) && (
                  <div key="alerts" data-grid={layoutConfig["alerts"]}>
                    {this.renderLayoutWidgetToolbar("alerts")}
                    {this.renderAlerts()}
                  </div>
                )}

                {isWidgetVisible("news", activeLayout) && (
                  <div key="news" data-grid={layoutConfig["news"]}>
                    {this.renderLayoutWidgetToolbar("news")}
                    {this.renderNews()}
                  </div>
                )}

                {["discovery1", "discovery2"].map(
                  (widget) =>
                    (this.props.isPro || this.props.isProPlus) &&
                    isWidgetVisible(widget, activeLayout) && (
                      <div key={widget} data-grid={layoutConfig[widget]}>
                        {this.renderLayoutWidgetToolbar(widget)}
                        {this.renderDiscovery(widget)}
                      </div>
                    )
                )}
              </ReactGridLayout>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
  maximiseView: DashboardActions.maximiseView,
  optionsFetch: OptionsActions.optionsFetch,
  stockNamesFetch: StockNamesActions.stockNamesFetch,
  usernamesFetch: MiscActions.usernamesFetch,
  setConfig: ConfigActions.setConfig,
  updateTableFilter: DiscoveryActions.updateTableFilter,
  setDiscovery: DiscoveryActions.setDiscovery,
  updateActiveLayoutConfig: DashboardActions.updateActiveLayoutConfig,
  updateLayoutDeviceMedia: DashboardActions.updateLayoutDeviceMedia,
  setLayoutActive: DashboardActions.setLayoutActive,
  openLayoutWidgetPopout: DashboardActions.openLayoutWidgetPopout,
  closeLayoutWidgetPopout: DashboardActions.closeLayoutWidgetPopout,
  updateLayoutWidgetScreenInfo: DashboardActions.updateLayoutWidgetScreenInfo,
  setIsPagePopout: DashboardActions.setIsPagePopout,
  setIsPopoutBlocked: DashboardActions.setIsPopoutBlocked,
  setLayoutConfig: DashboardActions.setLayoutConfig,
  triggerLayoutForceUpdate: DashboardActions.triggerLayoutForceUpdate,
  updateWindowPlacementPermissionRequested:
    DashboardActions.updateWindowPlacementPermissionRequested,
  updateFilter: NewsActions.updateFilter,
  addToRecent: NewsActions.addToRecent,
  updateChartWidgetSymbol: ChartActions.updateSymbol,
};

const mapStateToProps = (state, props) => ({
  authenticated: state.auth.authenticated,
  loading: state.auth.loading,
  user: state.auth.user,
  config: state.config,
  discovery: state.discovery,
  quotes: state.quote.quotes,
  stream: state.stream,
  optionsMode: state.config.optionsMode || DEFAULT_OPTIONS_MODE,
  options: state.options.options,
  ...state.dashboard,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) ||
      isProNew(state.auth.user.subscription.plan)),
  isProPlus:
    isActiveSubscription(state.auth.user.subscription) &&
    isProPlusNew(state.auth.user.subscription.plan),
  ...props,
});

export default withDataSource(
  withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
  )
);

import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "../api";
import { OptionsTypes } from "./options";

function* getOptionsData() {
  try {
    const options = yield call(API.getOptions);
    yield put({ type: OptionsTypes.SET_OPTIONS, options });
  } catch (e) {
    console.log(e);
    // yield put({ type: OptionsTypes.SET_OPTIONS, options: [] });
  }
}

function* optionsSaga() {
  yield takeLatest(OptionsTypes.OPTIONS_FETCH, getOptionsData);
}

export default optionsSaga;

import moment from "moment-timezone";

import {
  DEFAULT_DISCOVERY_SECTOR,
  DISCOVERY_FILTER_TEMP_PREFIX,
  LAST_AT_FILTER_UNIT,
  TREND_TYPE,
} from "../constants";

export const transformDiscoveryItem = (item, priceDistSPY) => {
  const res = {
    symbol: item.s,
    marketCap: item.c,
    last: item.l || 0,
    price_dist: item.p || [],
    priorDayLast: item.pl,
    volume: item.v || 0,
    avgVolume: item.av || 0,
    momentum: item.m || 0,
    uVol: item.u || 0,
    vWapDist: item.vw || 0,
    sector: item.e,
    float: item.f || 0,
    short_ratio: item.sr || 0,
    trending: item.t,
    news: item.n,
    gap: item.g,
    squeeze: item.z,
    atr: item.tr,
    trend: item.st || [],
    moneyflow: item.mf || [],
    realVol: item.rv || [],
    halt: item.h,
    lastHalt: item.lht,
    luld: item.ud,
    tradeCount: item.tc,
  };

  const pd = res["price_dist"] || [];
  const mf = res["moneyflow"] || [];
  const rv = res["realVol"] || [];
  const tc = res["tradeCount"] || [];
  res["price_dist"] = pd[0];
  res["dollar_dist"] = pd[1];
  res["moneyflow"] = mf[0];
  res["moneyflow_dist"] = mf[1];
  res["volume"] = rv[0];
  res["volume_dist"] = rv[1];
  delete res["realVol"];
  res["tradeCount"] = tc[0];
  res["tradeCount_dist"] = tc[1];
  if (!res["squeeze"] && res["squeeze"] !== 0 && res["squeeze"] !== "0") {
    res["squeeze"] = "-";
  }
  if (!res["moneyflow"] && res["moneyflow"] !== 0 && res["moneyflow"] !== "0") {
    res["moneyflow"] = "-";
  }
  if (!res["trend"]) {
    res["trend"] = [null, 0];
  }

  res["atr_dollar_dist"] = (res["atr"] * res["last"]) / 100;
  if (!isNaN(res["atr_dollar_dist"])) {
    res["atr_dollar_dist"] = Number(res["atr_dollar_dist"].toFixed(2));
  }

  res["price_dist"] = res["price_dist"] || 0;
  res["dollar_dist"] = res["dollar_dist"] || 0;

  res["gap_percent_dist"] = 0;
  if (res["gap"] && res["priorDayLast"]) {
    res["gap_percent_dist"] = (res["gap"] / res["priorDayLast"]) * 100;
    res["gap_percent_dist"] = Number(res["gap_percent_dist"].toFixed(2));
  }

  res["rs"] = res["price_dist"] - priceDistSPY; // (Math.random() - 0.5) * 20 . toFixed(2)

  for (const key of [
    "volume_dist",
    "moneyflow_dist",
    "tradeCount_dist",
    "uVol",
  ]) {
    if (res[key] === "Inf") {
      res[key] = Infinity;
    }
  }

  return res;
};

export const sectorFilter = (item, sector) => {
  if (!Array.isArray(sector) || sector.length === 0) {
    return true;
  }
  if (sector.includes(DEFAULT_DISCOVERY_SECTOR)) {
    return true;
  }
  return sector.includes(item.sector);
};

export const inRange = (item, min, max, key) => {
  let x = item[key];
  if (key === "squeeze") {
    let res = false;
    min = min || {};
    if (min["NOW"] && x == 0) res = true;
    if (min["PRE"] && !isNaN(x) && x > 0) {
      if (min["PRE"] === true || min["PRE"] === "true") {
        res = true;
      } else {
        const preParsed = parseInt(min["PRE"]);
        if (!isNaN(preParsed) && preParsed > 0 && x >= preParsed) {
          res = true;
        }
      }
    }
    if (min["POST"] && !isNaN(x) && x < 0 && x >= -5) {
      if (min["POST"] === true || min["POST"] === "true") {
        res = true;
      } else {
        const postParsed = parseInt(min["POST"]);
        if (
          !isNaN(postParsed) &&
          postParsed < 0 &&
          postParsed >= -5 &&
          x >= postParsed
        ) {
          res = true;
        }
      }
    }
    return res;
  } else if (key === "trend") {
    return (
      (min === TREND_TYPE.BUY && x[0]) || (min === TREND_TYPE.SELL && !x[0])
    );
  } else if (key === "news") {
    let res = false;
    if (
      !isNaN(min?.threshold) &&
      LAST_AT_FILTER_UNIT.includes(min?.unit) &&
      x
    ) {
      const thresholdMoment = moment();
      thresholdMoment.subtract(
        min.threshold,
        min.unit === "mins" ? "minutes" : min.unit
      );
      if (x && moment(x).isAfter(thresholdMoment)) {
        res = true;
      }
    }
    return res;
  } else if (key === "halt") {
    let res = false;
    x = item["lastHalt"];
    if (
      !isNaN(min?.threshold) &&
      LAST_AT_FILTER_UNIT.includes(min?.unit) &&
      x
    ) {
      const thresholdMoment = moment();
      thresholdMoment.subtract(
        min.threshold,
        min.unit === "mins" ? "minutes" : min.unit
      );
      if (x && moment(new Date(x)).isAfter(thresholdMoment)) {
        res = true;
      }
    }
    return res;
  } else {
    const fieldMap = {
      mf_price: "moneyflow",
      mf_dist: "moneyflow_dist",
      rv_price: "volume",
      rv_dist: "volume_dist",
      gap: "gap_percent_dist",
    };
    if (fieldMap[key]) {
      x = item[fieldMap[key]];
    }
    if (key === "mf_dist" || key === "rv_dist") {
      if (isNaN(x)) return false;
    }
    if (x >= min && x <= max) {
      return true;
    }
    return false;
  }
};

export const getPersistedTableFilters = (state, widget) => {
  const { tableFilters, selectedTableFilter } = state;
  return Object.keys(tableFilters || {}).filter(
    (name) =>
      !name.startsWith(DISCOVERY_FILTER_TEMP_PREFIX) ||
      name === selectedTableFilter[widget]
  );
};

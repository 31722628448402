import React, { Component } from "react";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import * as _ from "lodash";
import { SortDirection } from "react-virtualized";

import {
  SHOW_ALL_DISCOVERY_FILTER,
  DEFAULT_DISCOVERY_SETTING_LIST,
  DEFAULT_OPTIONS_MODE,
  DISCOVERY_DISPLAY_DOLLAR_CHANGE,
  DISCOVERY_DISPLAY_PERCENT_CHANGE,
  SQUEEZE_SORT,
  SQUEEZE_SORT_NEXT,
  TEST_SYMBOLS,
  TIMEFRAME_TRANSFORM_MAP,
  TREND_TYPE,
} from "../constants";
import { registerAlert } from "../shared/helper";
import { isMarketOpen, isRegularMarketOpen } from "../util";
import {
  inRange,
  sectorFilter,
  transformDiscoveryItem,
} from "./DiscoveryUtils";

import { withDataSource } from "../../contexts/datasource/hoc/withSocket";

import { DashboardActions, DiscoveryActions, QuoteActions } from "../store";
import { FILTER_TYPE } from "./discoveryReducer";

import API from "../api";

import DiscoveryFilters from "./DiscoveryFilters";
import TrendPopup from "./TrendPopup";
import AlertPopup from "./AlertPopup";
import VolumePopup from "./VolumePopup";
import MoneyFlowPopup from "./MoneyFlowPopup";
import TradeCountPopup from "./TradeCountPopup";
import DiscoveryTableGrid from "./DiscoveryTableGrid";

class Discovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discoveryData: [],
    };

    this.onRealtimeData = this.onRealtimeData.bind(this);
  }

  componentDidMount() {
    const discoveryTable = document.getElementById("discovery-table");
    if (discoveryTable) {
      discoveryTable.addEventListener("scroll", this.handleScroll);
    }

    if (this.props.datasource.realtime) {
      this.onRealtimeDatasourceInit();
    }
  }

  componentWillUnmount() {
    this.props.datasource.realtime?.off("discovery", this.onRealtimeData);
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.datasource.realtime &&
      this.props.datasource.realtime !== prevProps.datasource.realtime
    ) {
      this.onRealtimeDatasourceInit();
    }
  }

  onRealtimeDatasourceInit() {
    this.props.datasource.realtime.on("discovery", this.onRealtimeData);
  }

  onRealtimeData(event) {
    const {
      detail: [timeframe, data],
    } = event;
    const { widget, discoveryTimeframe } = this.props;
    if (timeframe !== TIMEFRAME_TRANSFORM_MAP[discoveryTimeframe[widget]]) {
      return;
    }

    const { discoveryData } = this.state;

    this.setState({
      discoveryData: discoveryData.map((item) => {
        if (item.symbol === data.s) {
          const updated = {
            ...item,
          };
          if (data.l && !isNaN(data.l)) {
            updated.last = data.l;
          }
          if (data.p) {
            updated.price_dist = data.p[0];
            updated.dollar_dist = data.p[1];
          }
          if (data.m) {
            updated.momentum = data.m;
          }
          if (data.rv) {
            updated.volume = data.rv[0];
            updated.volume_dist = data.rv[1];
            if (updated.volume_dist === "Inf") {
              updated.volume_dist = Infinity;
            }
          }
          if (data.mf) {
            updated.moneyflow = data.mf[0];
            updated.moneyflow_dist = data.mf[1];
            if (updated.moneyflow_dist === "Inf") {
              updated.moneyflow_dist = Infinity;
            }
          }
          if (data.h) {
            if (data.h === 1) {
              updated.halt = true;
            }
            if (data.h === -1) {
              updated.halt = false;
            }
          }
          if (data.ud) {
            if (data.ud === -1) {
              updated.luld = null;
            }
            if (Array.isArray(data.ud)) {
              updated.luld = data.ud;
            }
          }
          return updated;
        } else {
          return item;
        }
      }),
    });
  }

  async onTriggerDataFetch(params = {}) {
    const { manual = false, ignoreOldData = false } = params;
    const { widget, discoveryTimeframe } = this.props;
    const viewportSymbols = (this.props.viewportSymbols || {})[widget] || [];
    const timeframe = discoveryTimeframe[widget] || "1day";

    try {
      const fetchMethod = manual ? API.getDiscoveryManual : API.getDiscovery;
      const stats = await fetchMethod({
        timeframe: TIMEFRAME_TRANSFORM_MAP[timeframe],
      });

      const symbolSPY = (stats || []).find((item) => item.s === "SPY");
      const priceDistSPY = symbolSPY ? (symbolSPY.p || [])[0] : 0;

      const { discoveryData } = this.state;

      const data = (stats || []).map((item) => {
        const transformedItem = transformDiscoveryItem(item, priceDistSPY);
        if (
          isMarketOpen() &&
          viewportSymbols.includes(item.s) &&
          !ignoreOldData
        ) {
          const oldItem = discoveryData.find(
            (item1) => item1.symbol === item.s
          );
          if (oldItem) {
            transformedItem.last = oldItem.last;
            transformedItem.price_dist = oldItem.price_dist;
            transformedItem.dollar_dist = oldItem.dollar_dist;
            transformedItem.momentum = oldItem.momentum;
            transformedItem.volume = oldItem.volume;
            transformedItem.volume_dist = oldItem.volume_dist;
            transformedItem.moneyflow = oldItem.moneyflow;
            transformedItem.moneyflow_dist = oldItem.moneyflow_dist;
            transformedItem.halt = oldItem.halt;
            transformedItem.luld = oldItem.luld;
          }
        }
        return transformedItem;
      });

      if (data.length > 0) {
        this.setState({
          discoveryData: data,
        });
      }
    } catch (e) {
      cogoToast.error("Error fetching discovery data!");
    }
  }

  getWidgetConfig() {
    const { config, widget } = this.props;
    const discoveryConfig = config?.discovery || DEFAULT_DISCOVERY_SETTING_LIST;
    return (
      discoveryConfig.find((item) => item.id === widget) || discoveryConfig[0]
    );
  }

  getScrollPercent() {
    const h = document.getElementById("discovery-table"),
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }

  handleScroll = (e) => {
    if (this.getScrollPercent() === 100) {
      const { discoveryIndex } = this.props;
      this.props.updateDiscoveryIndex(discoveryIndex + 25);
    }
  };

  onFavPress = () => {
    const { toggleFavFilter, widget } = this.props;
    toggleFavFilter(widget);
  };

  isSorted = (field, type) => {
    const { discoverySort, widget } = this.props;
    return (
      discoverySort[widget].sortBy === field &&
      discoverySort[widget].sortDirection === type
    );
  };

  async onAlertMenuClick(symbol, type, vWAPDist) {
    await registerAlert(symbol, type, vWAPDist, vWAPDist);
  }

  // renderAlertMenu = (key) => {
  //   const symbol = this.props.discoverAlerySelected.symbol;
  //   const vWAPDist = this.props.discoverAlerySelected.vWAPDist;
  //   return (
  //     <ContextMenu id={key} className="p-0" key={`alert_menu-item-${key}`}>
  //       <div className="context-menu-alert-style">
  //         <MenuItem
  //           onClick={async () => {
  //             await this.onAlertMenuClick(symbol, "price", vWAPDist);
  //           }}
  //         >
  //           <div className="row align-items-center mt-1">
  //             <span className="medium white-no-wrap bar-txt">Price</span>
  //           </div>
  //         </MenuItem>
  //         <MenuItem
  //           onClick={async () => {
  //             await this.onAlertMenuClick(symbol, "vwap", vWAPDist);
  //           }}
  //         >
  //           <div className="row align-items-center mt-1">
  //             <span className="medium white-no-wrap bar-txt">VWAP</span>
  //           </div>
  //         </MenuItem>
  //         <MenuItem
  //           onClick={async () => {
  //             await this.onAlertMenuClick(symbol, "uv", vWAPDist);
  //           }}
  //         >
  //           <div className="row align-items-center mt-1">
  //             <span className="medium white-no-wrap bar-txt">UnVol</span>
  //           </div>
  //         </MenuItem>
  //         <MenuItem
  //           onClick={async () => {
  //             await this.onAlertMenuClick(symbol, "hi/lo", vWAPDist);
  //           }}
  //         >
  //           <div className="row align-items-center mt-1">
  //             <span className="medium white-no-wrap bar-txt">Hi/Lo</span>
  //           </div>
  //         </MenuItem>
  //       </div>
  //     </ContextMenu>
  //   );
  // };

  isSymbolFav = (symbol) => {
    const { quotes } = this.props;
    const qouteItem = quotes.find((item) => item.symbol === symbol);
    return qouteItem ? true : false;
  };

  getNextSortDirection = (column, current) => {
    if (column === "squeeze") {
      return SQUEEZE_SORT_NEXT(current);
    } else {
      return current === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC;
    }
  };

  getInitialSortDirection = (column) => {
    if (column === "squeeze") {
      return SQUEEZE_SORT.NOW;
    } else {
      return SortDirection.DESC;
    }
  };

  _sort({ sortBy }) {
    const { updateDiscoverySort, discoverySort, widget } = this.props;
    if (!["alert", "sparkline"].includes(sortBy)) {
      if (sortBy === discoverySort[widget].sortBy) {
        updateDiscoverySort(
          {
            sortBy,
            sortDirection: this.getNextSortDirection(
              sortBy,
              discoverySort[widget].sortDirection
            ),
          },
          widget
        );
      } else {
        updateDiscoverySort(
          {
            sortBy,
            sortDirection: this.getInitialSortDirection(sortBy),
          },
          widget
        );
      }
    }
  }

  _sortList(sortBy, sortDirection, items) {
    if (sortBy === "uVol" || sortBy === "float") {
      const sorted = _.sortBy(
        items.filter((value) => value[sortBy] && value[sortBy] > 0),
        sortBy
      );
      return [
        ...(sortDirection === SortDirection.ASC ? sorted : sorted.reverse()),
        ...items.filter((value) => !value[sortBy] || value[sortBy] < 0),
      ];
    }
    if (
      sortBy === "moneyflow" ||
      sortBy === "volume" ||
      sortBy === "marketCap" ||
      sortBy === "tradeCount"
    ) {
      const sorted = _.sortBy(
        items.filter((value) => !isNaN(value[sortBy]) && value[sortBy]),
        sortBy
      );
      return [
        ...(sortDirection === SortDirection.ASC ? sorted : sorted.reverse()),
        ...items.filter((value) => isNaN(value[sortBy]) || !value[sortBy]),
      ];
    }
    if (sortBy === "squeeze") {
      if (sortDirection === SQUEEZE_SORT.NOW) {
        return items.filter(
          (item) => item[sortBy] === 0 || item[sortBy] === "0"
        );
      } else if (
        sortDirection === SQUEEZE_SORT.PRE_ASC ||
        sortDirection === SQUEEZE_SORT.PRE_DESC
      ) {
        let sorted = items
          .filter((item) => item[sortBy] > 0)
          .sort((itema, itemb) => {
            const a = Number(itema[sortBy]);
            const b = Number(itemb[sortBy]);
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          });
        if (sortDirection === SQUEEZE_SORT.PRE_DESC) sorted = sorted.reverse();
        return sorted;
      } else if (
        sortDirection === SQUEEZE_SORT.POST_ASC ||
        sortDirection === SQUEEZE_SORT.POST_DESC
      ) {
        let sorted = items
          .filter((item) => item[sortBy] < 0)
          .sort((itema, itemb) => {
            const a = Number(itema[sortBy]);
            const b = Number(itemb[sortBy]);
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
          });
        if (sortDirection === SQUEEZE_SORT.POST_DESC) sorted = sorted.reverse();
        return sorted;
      }
      return items;
    }
    if (sortBy === "price_dist") {
      const discovery_config = this.getWidgetConfig().value;
      let lastDistDisplayOption;

      for (const item of discovery_config) {
        if (item.column === "price_dist") {
          lastDistDisplayOption =
            item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
          break;
        }
      }

      if (lastDistDisplayOption === DISCOVERY_DISPLAY_DOLLAR_CHANGE) {
        sortBy = "dollar_dist";
      }
    }
    if (sortBy === "atr") {
      const discovery_config = this.getWidgetConfig().value;
      let atrDistDisplayOption;

      for (const item of discovery_config) {
        if (item.column === "atr") {
          atrDistDisplayOption =
            item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
          break;
        }
      }

      if (atrDistDisplayOption === DISCOVERY_DISPLAY_DOLLAR_CHANGE) {
        sortBy = "atr_dollar_dist";
      }
    }
    if (sortBy === "gap") {
      const discovery_config = this.getWidgetConfig().value;
      let gapDistDisplayOption;

      for (const item of discovery_config) {
        if (item.column === "gap") {
          gapDistDisplayOption =
            item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
          break;
        }
      }

      if (gapDistDisplayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE) {
        sortBy = "gap_percent_dist";
      }
    }
    if (sortBy === "halt") {
      const sorted = items
        .filter((value) => value.halt || value.luld)
        .sort((a, b) => {
          if (a.halt) {
            if (b.halt) {
              return a.symbol > b.symbol ? 1 : a.symbol < b.symbol ? -1 : 0;
            } else {
              return 1;
            }
          } else {
            if (b.halt) {
              return -1;
            } else {
              return a.luld[0] > b.luld[0] ? 1 : a.luld[0] < b.luld[0] ? -1 : 0;
            }
          }
        });
      return [
        ...(sortDirection === SortDirection.ASC ? sorted : sorted.reverse()),
        ...items.filter((value) => !value.halt && !value.luld),
      ];
    }
    // if (sortBy === 'trend') {
    //   return items.filter(value => sortDirection === SortDirection.ASC ? value[sortBy][0] : !value[sortBy][0]);
    // }
    return sortDirection === SortDirection.ASC
      ? _.sortBy(items, sortBy)
      : _.sortBy(items, sortBy).reverse();
  }

  getTableData = () => {
    const {
      quotes,
      options,
      config,
      widget,
      discoverySort,
      discoveryFilter,
      discoveryFilterExactMatch,
      discoverySector,
      isFavFilter,
      tableFilters,
      selectedTableFilter,
    } = this.props;
    const optionsMode = config.optionsMode || DEFAULT_OPTIONS_MODE;
    const { discoveryData } = this.state;

    const discoveryDataFiltered = discoveryData
      .filter((item) => {
        return TEST_SYMBOLS.indexOf(item.symbol) < 0;
      })
      .filter((item) => sectorFilter(item, discoverySector[widget]))
      .filter((item) => {
        if (discoveryFilter[widget]) {
          return discoveryFilterExactMatch[widget]
            ? (item.symbol || "").toUpperCase() ===
                (discoveryFilter[widget] || "").toUpperCase()
            : (item.symbol || "")
                .toUpperCase()
                .includes((discoveryFilter[widget] || "").toUpperCase());
        } else {
          return true;
        }
      })
      .filter((item) => {
        if (isFavFilter[widget]) {
          return quotes.map((i) => i.symbol).includes(item.symbol);
        } else {
          return true;
        }
      })
      // .filter((item) => item.uVol >= 0) // uVol should not show negative values as it doesn't make sense to have "negative unusual volume".
      .filter((item) => {
        if (!tableFilters || !selectedTableFilter[widget]) {
          return true;
        }

        const tableFilter = tableFilters[selectedTableFilter[widget]];
        if (!tableFilter) {
          return true;
        }
        if (tableFilter.type === FILTER_TYPE.ALL) {
          return Object.keys(tableFilter.values).every((key) => {
            if (!key) {
              return true;
            }
            let { min, max } = tableFilter.values[key];
            if (key === "squeeze" || key === "news" || key === "halt") {
              if (Object.prototype.toString.call(min) !== "[object Object]") {
                min = {};
              }
              max = null;
            } else if (key === "trend") {
              if (min !== TREND_TYPE.BUY && min !== TREND_TYPE.SELL) {
                min = TREND_TYPE.BUY;
              }
              max = null;
            } else {
              if (isNaN(min)) min = -Infinity;
              else if (!min && min !== 0 && min !== "0") min = -Infinity;
              if (isNaN(max)) max = Infinity;
              else if (!max && max !== 0 && max !== "0") max = Infinity;
            }
            return inRange(item, min, max, key);
          });
        } else {
          return Object.keys(tableFilter.values).some((key) => {
            if (!key) {
              return true;
            }
            let { min, max } = tableFilter.values[key];
            if (key === "squeeze" || key === "news" || key === "halt") {
              if (Object.prototype.toString.call(min) !== "[object Object]") {
                min = {};
              }
              max = null;
            } else if (key === "trend") {
              if (min !== TREND_TYPE.BUY && min !== TREND_TYPE.SELL) {
                min = TREND_TYPE.BUY;
              }
              max = null;
            } else {
              if (isNaN(min)) min = -Infinity;
              else if (!min && min !== 0 && min !== "0") min = -Infinity;
              if (isNaN(max)) max = Infinity;
              else if (!max && max !== 0 && max !== "0") max = Infinity;
            }
            return inRange(item, min, max, key);
          });
        }
      })
      .filter((item) => {
        if (optionsMode !== "Filter") return true;
        if (options.indexOf(item.symbol) > -1) return true;
        if (this.isSymbolFav(item.symbol)) return true;
        return false;
      });

    const filteredItems = discoveryDataFiltered.map((data, index) => ({
      index,
      ...data,
      vWAPDist: data.vWapDist,
      alert: data.symbol,
    }));

    return this._sortList(
      discoverySort[widget].sortBy,
      discoverySort[widget].sortDirection,
      filteredItems
    );
  };

  getTableDataToExport = () => {
    const data = this.getTableData().slice(0, 1000);
    return data.map((item) => {
      if (!isNaN(item.vWapDist)) {
        item.vWapDist = Number(item.vWapDist).toFixed(4);
      }
      if (item.squeeze == 0) {
        item.squeeze = "now";
      }
      item.trend = item.trend[0] ? TREND_TYPE.BUY : TREND_TYPE.SELL;
      if (!item.halt) {
        if (Array.isArray(item.luld) && isRegularMarketOpen()) {
          item.halt = `up: ${item.luld[0]} down: ${item.luld[1]}`;
        } else {
          item.halt = "-";
        }
      }
      if (!item.marketCap) {
        item.marketCap = "-";
      }
      return item;
    });
  };

  clearDiscoveryFilter = () => {
    const {
      resetDiscoverySector,
      isFavFilter,
      toggleFavFilter,
      updateTableFilter,
      updateDiscoveryFilter,
      updateDiscoverySort,
      widget,
    } = this.props;
    resetDiscoverySector(widget);
    isFavFilter[widget] && toggleFavFilter(widget);
    updateTableFilter({
      widget,
      filterName: SHOW_ALL_DISCOVERY_FILTER,
    });
    updateDiscoveryFilter(
      {
        symbol: "",
        exactMatch: false,
      },
      widget
    );
    updateDiscoverySort(
      {
        sortBy: "price_dist",
        sortDirection: SortDirection.DESC,
      },
      widget
    );
  };

  renderDiscoveryTableResponsive = () => {
    const { discoveryIndex, discoverySort, widget } = this.props;
    const { sortBy, sortDirection } = discoverySort[widget];
    const { discoveryData } = this.state;
    const data = this.getTableData();

    return (
      <>
        <DiscoveryTableGrid
          widget={widget}
          index={discoveryIndex}
          discoveryData={data}
          _sort={this._sort.bind(this)}
          sortBy={sortBy}
          sortDirection={sortDirection}
          checkIsFavorite={(symbol) => this.isSymbolFav(symbol)}
          onSetSymbolFav={(symbol) => {
            if (this.isSymbolFav(symbol)) {
              this.props.removeFromQuotes(symbol);
            } else {
              this.props.registerQuote([symbol]);
            }
          }}
          onAlertTrigger={this.props.alertSelected}
          tableConfig={this.getWidgetConfig().value}
        />
        {discoveryData &&
          discoveryData.length > 0 &&
          (!data || data.length === 0) && (
            <div
              className={
                "d-flex flex-column justify-content-center align-items-center"
              }
              style={{ top: "8rem", left: 0, right: 0, position: "absolute" }}
            >
              <span>NO RESULTS FOUND</span>
              <div
                className={"btn-load-recent"}
                style={{ fontSize: "1.2rem", marginTop: "1rem" }}
                onClick={this.clearDiscoveryFilter}
              >
                CLEAR FILTERS
              </div>
            </div>
          )}
        {/* {this.renderAlertMenu(`discovery-alert-context-menu`)} */}
      </>
    );
  };

  discoveryFilterCheckTimeoutId = null;
  discoveryFilterSymbolLastInced = null;

  onChangeDiscoveryFilter = (value, exactMatch = false) => {
    const { widget } = this.props;
    const discoveryFilter = value.toUpperCase();
    this.props.updateDiscoveryFilter(
      {
        symbol: discoveryFilter,
        exactMatch,
      },
      widget
    );

    if (this.discoveryFilterCheckTimeoutId) {
      clearTimeout(this.discoveryFilterCheckTimeoutId);
      this.discoveryFilterCheckTimeoutId = null;
    }
    if (discoveryFilter !== "" && discoveryFilter.length <= 6) {
      this.discoveryFilterCheckTimeoutId = setTimeout(() => {
        this.discoveryFilterCheckTimeoutId = null;
        const currentDiscoveryFilter = this.props.discoveryFilter[widget];
        if (currentDiscoveryFilter !== discoveryFilter) {
          return;
        }
        if (
          this.discoveryFilterSymbolLastInced &&
          new Date() - this.discoveryFilterSymbolLastInced < 1000 * 60 * 60
        ) {
          return;
        }
        API.incSymbolStats(currentDiscoveryFilter);
        this.discoveryFilterSymbolLastInced = new Date();
      }, 2000);
    }
  };

  // handleMaximize = () => {
  //   const { maximisedView, maximiseView } = this.props;
  //   maximiseView(maximisedView ? null : "discovery");
  //   window.scrollTo(0, 0);
  //   document
  //     .getElementById("discovery-table")
  //     .addEventListener("scroll", this.handleScroll);
  // };

  render() {
    const { maximisedView, widget } = this.props;

    return (
      <div
        className={
          maximisedView
            ? "w-100 card"
            : "card d-flex flex-row data-section mx-0 h-100"
        }
      >
        <div className="d-flex flex-column flex-grow-1">
          <div className="discovery-filter-container d-flex flex-row justify-content-between text-center flex-wrap mb-0 pb-0">
            <DiscoveryFilters
              widget={widget}
              settingLoaded={this.props.settingLoaded}
              maximisedView={maximisedView}
              onChangeDiscoveryFilter={this.onChangeDiscoveryFilter.bind(this)}
              getTableDataToExport={this.getTableDataToExport.bind(this)}
              triggerDataFetch={this.onTriggerDataFetch.bind(this)}
            />
          </div>
          <div
            className={
              (maximisedView ? "discovery-max" : "discovery-normal") +
              " discovery-table flex-grow-1"
            }
            id="discovery-table"
          >
            {this.renderDiscoveryTableResponsive()}
          </div>
        </div>
        <AlertPopup widget={widget} />
        <MoneyFlowPopup widget={widget} />
        <VolumePopup widget={widget} />
        <TradeCountPopup widget={widget} />
        <TrendPopup widget={widget} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  alertSelected: DiscoveryActions.alertSelected,
  updateDiscoveryIndex: DiscoveryActions.updateDiscoveryIndex,
  updateDiscoveryFilter: DiscoveryActions.updateDiscoveryFilter,
  resetDiscoverySector: DiscoveryActions.resetDiscoverySector,
  updateDiscoverySort: DiscoveryActions.updateDiscoverySort,
  updateTableFilter: DiscoveryActions.updateTableFilter,
  toggleFavFilter: DiscoveryActions.toggleFavFilter,
  removeFromQuotes: QuoteActions.removeFromQuotes,
  registerQuote: QuoteActions.registerQuote,
  maximiseView: DashboardActions.maximiseView,
};

const mapStateToProps = (state, props) => ({
  ...state.discovery,
  config: state.config,
  maximisedView: state.dashboard.maximisedView,
  quotes: state.quote.quotes,
  options: state.options.options,
});

export default withDataSource(
  connect(mapStateToProps, mapDispatchToProps)(Discovery)
);

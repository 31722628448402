import { store } from "../../store/createStore";
import API from "../../api";

async function uploadCurrentConfig() {
  const state = store.getState();
  const config = state.config;

  const response = await API.updateSettingConfig(config);
  return response;
}

export default uploadCurrentConfig;

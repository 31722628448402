import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import qs from "qs";
import cogoToast from "cogo-toast";

import { AuthActions } from "../store";
import Api from "../api";
import i18n from "../../i18n";

export class ResetPassword extends Component {
  state = {
    errTxt: "",
  };

  componentDidMount() {
    window.localStorage.removeItem("jwt_access_token");
  }

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({ errTxt: "" });

    const password = this.refPassword.value;
    const rePassword = this.refConfirm.value;
    if (password != rePassword) {
      this.setState({
        errTxt: i18n.getResource("en", ["translations"], "password_mismatch"),
      });
      return;
    }

    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { token } = query;

    Api.resetPassword(password, token)
      .then(() => {
        cogoToast.success(
          "The password is successfully reset! You will be redirected to the login page."
        );
        setTimeout(() => {
          this.props.history.replace("/login");
        }, 3000);
      })
      .catch((error) => {
        const errTxt = error.toString();
        console.info(errTxt);
        let resetErrTxt;
        if (errTxt === "TypeError: Failed to fetch") {
          resetErrTxt = "Service not available";
        } else if (errTxt === "invalid_token" || errTxt === "link_expired") {
          resetErrTxt = "This link is expired or token is invalid";
        } else {
          resetErrTxt = "Failed to reset password. Please try again";
        }

        this.setState({ errTxt: resetErrTxt });
      });
  };

  render() {
    const { errTxt } = this.state;
    return (
      <div className="auth px-0 pt-4 pt-md-5 h-100">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <span className="pr-2">MOMO</span>
              </div>
              <h4>Reset your password</h4>
              <h6 className="font-weight-light">
                Please enter a new password.
              </h6>
              <form className="pt-3" onSubmit={this.onSubmit}>
                <Form.Group>
                  <label>Password</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <i className="input-group-text mdi mdi-lock text-momo-green" />
                    </div>
                    <Form.Control
                      type="password"
                      className="form-control text-light form-momo-green"
                      placeholder="Password"
                      ref={(ref) => {
                        this.refPassword = ref;
                      }}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <label>Confirm Password</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <i className="input-group-text mdi mdi-lock text-momo-green" />
                    </div>
                    <Form.Control
                      type="password"
                      className="form-control text-light form-momo-green"
                      placeholder="Password"
                      ref={(ref) => {
                        this.refConfirm = ref;
                      }}
                    />
                  </div>
                </Form.Group>
                {errTxt !== "" && (
                  <label className="text-danger">{`${errTxt}`}</label>
                )}
                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-block btn-momo-green btn-lg font-weight-medium auth-form-btn"
                  >
                    RESET
                  </button>
                </div>
                <div className="text-center mt-4 font-weight-light">
                  <Link to="/login" className="text-momo-green">
                    Login
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPassword));

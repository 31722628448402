import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import NumAbbr from "number-abbreviate";

import RadioButton, {
  ButtonLabel as RadioButtonLabel,
  ButtonWrapper as RadioButtonWrapper,
} from "../shared/Button/RadioButton";
import StepSlider from "../shared/StepRangeSlider/StepSlider";

import { Types as FlowActionTypes } from "./flowReducer";

import {
  FLOW_MINUTE_VOLUME_RANGE,
  FLOW_TIMEFRAME_FILTER,
  PRICE_RANGE,
  VOLUME_RANGE,
} from "../constants";

const numAbbr = new NumAbbr(["k", "m"], 2);

const PopoverTriggerButton = styled.button`
  font-size: 20px;
  color: white;
  border: 0;
`;

const PopoverContentTimeframeOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${RadioButtonLabel} {
    color: white;
    margin-left: 0.25rem;
  }

  ${RadioButtonWrapper} {
    margin-left: 0.5em;
    :first-child {
      margin-left: 0;
    }
    margin-top: 0;
  }
`;

const PopoverContentVolumeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  min-width: 150px;
`;

const PopoverContentVolumeText = styled.span`
  font-size: 14px;
  color: white;
  display: block;
  text-align: right;
  width: 85px;
  margin-right: 15px;
`;

const PopoverContentTimeframeRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const PopoverContentTimeframeText = styled(PopoverContentVolumeText)``;

const PopoverContentSliderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 120px;
`;

const PopoverContentVolumeLabel = styled.span`
  font-size: 12px;
  color: white;
  text-align: right;
  min-width: 16px;
  margin-left: 8px;
  display: block;
  white-space: nowrap;
`;

const minVolFilters = [
  {
    storeKey: "1m",
    label: "1min",
    range: FLOW_MINUTE_VOLUME_RANGE,
  },
  {
    storeKey: "5m",
    label: "5min",
    range: FLOW_MINUTE_VOLUME_RANGE,
  },
  {
    storeKey: "1d",
    label: "daily",
    range: VOLUME_RANGE,
  },
];

function FlowSettingPopover(props) {
  const dispatch = useDispatch();

  const {
    timeframe,
    filter: { vol, price },
  } = useSelector((state) => state.flow);

  const popover = (
    <Popover style={{ borderRadius: 12 }}>
      <Popover.Content style={{ minWidth: 160, borderRadius: 12 }}>
        <PopoverContentTimeframeRow>
          <PopoverContentTimeframeText>Display</PopoverContentTimeframeText>
          <PopoverContentTimeframeOptionsWrapper>
            {Object.keys(FLOW_TIMEFRAME_FILTER).map((item) => (
              <RadioButton
                key={`timeframe-${item}`}
                label={FLOW_TIMEFRAME_FILTER[item]}
                checked={item == timeframe}
                onClick={() => {
                  dispatch({
                    type: FlowActionTypes.UPDATE_TIMEFRAME,
                    value: item,
                  });
                }}
              />
            ))}
          </PopoverContentTimeframeOptionsWrapper>
        </PopoverContentTimeframeRow>
        {minVolFilters.map(({ storeKey, label, range }) => (
          <PopoverContentVolumeRow key={storeKey}>
            <PopoverContentVolumeText>{label} min vol</PopoverContentVolumeText>
            <PopoverContentSliderWrapper>
              <StepSlider
                range={range}
                value={parseFloat(vol[storeKey])}
                onChange={(value) => {
                  dispatch({
                    type: FlowActionTypes.UPDATE_MIN_VOLUME,
                    value: {
                      tf: storeKey,
                      volume: value,
                    },
                  });
                }}
                showTooltip={false}
              />
              <PopoverContentVolumeLabel>
                {numAbbr.abbreviate(vol[storeKey], 2)}
              </PopoverContentVolumeLabel>
            </PopoverContentSliderWrapper>
          </PopoverContentVolumeRow>
        ))}
        <PopoverContentVolumeRow>
          <PopoverContentVolumeText>min price</PopoverContentVolumeText>
          <PopoverContentSliderWrapper>
            <StepSlider
              range={PRICE_RANGE}
              value={parseFloat(price)}
              onChange={(value) => {
                dispatch({
                  type: FlowActionTypes.UPDATE_MIN_PRICE,
                  value: value,
                });
              }}
              showTooltip={false}
            />
            <PopoverContentVolumeLabel>
              {numAbbr.abbreviate(price, 2)}
            </PopoverContentVolumeLabel>
          </PopoverContentSliderWrapper>
        </PopoverContentVolumeRow>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={popover}
    >
      <PopoverTriggerButton className="bg-transparent" onClick={() => {}}>
        <i className="mdi mdi-settings" />
      </PopoverTriggerButton>
    </OverlayTrigger>
  );
}

export default FlowSettingPopover;
